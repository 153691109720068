import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import RegistrationList from "../../../components/Admin/Registrations/RegistrationList";
import RegistrationFilters from "../../../components/Admin/Registrations/RegistrationFilters";
import {
  apiAdminExportRegistrations,
  apiAdminImportRegistrations,
} from "../../../store/actions/actions";
import XLSX from "xlsx";
import { Button } from "react-bootstrap";
import ImportErrorsModal from "../../../components/Admin/Registrations/ImportErrorsModal";

const Registrations = (props) => {
  const exportHandler = (e) => {
    setExporting(true);
    props
      .apiAdminExportRegistrations(props.filters)
      .then(({ response }) => {
        setExporting(false);
      })
      .catch((error) => {
        setExporting(false);
      });
  };

  const [refreshCounter, setRefreshCounter] = useState(0);

  const importRef = useRef(null);
  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState(null);

  const closeModalHandler = (e) => {
    setStatus(null);
    setShowStatus(false);
  };

  let errorsModal = null;
  if (showStatus) {
    errorsModal = (
      <ImportErrorsModal onClose={closeModalHandler} status={status} />
    );
  }

  const processImport = (e) => {
    setImporting(true);

    var files = importRef.current.files,
      f = files[0];
    if (files.length > 0) {
      var reader = new FileReader();
      reader.onload = function (e) {
        let data = new Uint8Array(e.target.result);

        let workbook = XLSX.read(data, { type: "array", cellDates: true });
        const jsonData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        );

        props
          .apiAdminImportRegistrations(jsonData)
          .then(({ response }) => {
            console.log(response);
            // props.notify('Successfully imported ' + response.data.success_count + ' registrations with ' + response.data.failed_count + ' failures and ' +  response.data.removed_count + ' deletions');

            setStatus(response.data);
            setShowStatus(true);

            importRef.current.value = "";
            setImporting(false);
            setRefreshCounter(refreshCounter + 1);
          })
          .catch((error) => {
            props.notify("Your import failed. Please check file format", "");
            importRef.current.value = "";
            setImporting(false);
          });
      };
      reader.readAsArrayBuffer(f);
    }
  };

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row my-4 d-flex align-items-center">
          <div className="col col-md-4">
            <h1 className="">Registrations</h1>
          </div>
          <div className="col-md-8 text-md-right">
            <label for="upload">
              <span
                className={
                  importing
                    ? "btn btn-sm btn-dark mr-2 disabled"
                    : "btn btn-sm btn-dark mr-2"
                }
                aria-hidden="true"
              >
                Import
              </span>
              <input
                ref={importRef}
                type="file"
                id="upload"
                className="d-none"
                onChange={processImport}
              ></input>
            </label>
            <Button
              variant="dark"
              className={exporting ? "btn-sm mr-2 disabled" : "btn-sm mr-2"}
              onClick={exportHandler}
            >
              Export
            </Button>
            <Link to="/admin/registrations/add" className="btn btn-sm btn-dark">
              + Add Registration
            </Link>
          </div>
        </div>
        <div className="">
          <div className="container-full">
            <div className="row">
              <div className="col-md-3 mb-4">
                <RegistrationFilters />
              </div>
              <div className="col-md-9">
                <RegistrationList counter={refreshCounter} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorsModal}
    </AdminLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: state.registrationFilters.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminExportRegistrations: (f) =>
      dispatch(apiAdminExportRegistrations(f)),
    apiAdminImportRegistrations: (d) =>
      dispatch(apiAdminImportRegistrations(d)),
    notify: (msg, data) => dispatch({ type: "NOTIFY", msg: msg, data: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registrations);
