import React  from "react";

const YesOrNo = (props) => {
  let item = <span>No</span>;
  if(props.value){
    item = <span>Yes</span>
  }
  
  return (
    item
  )
}

export default YesOrNo;
