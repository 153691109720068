import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiLoadPage } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import ResponseBar from "../../components/ResponseBar";
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';
import { Helmet } from "react-helmet";


const Page = (props) => {

  const [page, setPage] = useState(null);

  useEffect(() => {
    props.apiLoadPage(props.match.params.slug)
      .then(({ response }) => {
        setPage(response.data.page);
      })
      .catch((error) => {
        console.log("Error retrieving pages");
      });
  }, [props.match.params.slug]);

  let output = <div></div>;
  if(page != null) {
    output =  
      <div>
        <Helmet>
          <title>T12ADE | {page.title}</title>
        </Helmet>
        <ResponseBar/>
        <div className="container py-4">
          <h1 className="mb-4 pb-4 border-bottom">{page.title}</h1>
          <div  dangerouslySetInnerHTML={{ __html: page.content }}></div>
        </div>
      </div>;
  }
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        {output}
      </main>
      <Footer />
    </div>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiLoadPage: (s) => dispatch(apiLoadPage(s)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Page));
