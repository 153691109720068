import React, { useEffect, useRef, useState } from "react";
import { Col, Form } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { apiAdminLoadStatuses } from '../../../store/actions/actions';

const DealerRegistrationFilters = (props) => {
  const [formValues, setFormValues] = useState(props.filters);
  const [statuses, setStatuses] = useState([]);
  
  let refs = {
    dateless: useRef(null),
    suffix: useRef(null),
    prefix: useRef(null),
    currentStyle: useRef(null),
    statuses: [],
    vatAny: useRef(null),
    vatNo: useRef(null),
    vatYes: useRef(null)
  }

  for (let i =0; i <= 10; i++) {
    refs.statuses[i] = useRef(null)
  }

  useEffect(() => {
    props.apiAdminLoadStatuses()
      .then(({ response }) => {
        setStatuses(response.data.statuses);
      })
      .catch(() => {
        console.log("Error retrieving statuses");
      });
  }, []);


  const updateMarkHandler = (e) => {
    setFormValues({
      ...formValues,
      mark: e.target.value
    })
  }

  const updatePriceFromHandler = (e) => {
    setFormValues({
      ...formValues,
      price_from: e.target.value
    })
  }

  const updatePriceToHandler = (e) => {
    setFormValues({
      ...formValues,
      price_to: e.target.value
    })
  }

  const updateTypeHandler = () => {
    let tmpValues = [];
    if (refs.dateless.current.checked) {
      tmpValues.push(1);
    }
    if (refs.suffix.current.checked) {
      tmpValues.push(2);
    }
    if (refs.prefix.current.checked) {
      tmpValues.push(3);
    }
    if (refs.currentStyle.current.checked) {
      tmpValues.push(4);
    }
    setFormValues({
      ...formValues,
      types: tmpValues
    })
  }

  const updateVatHandler = (e) => {
    setFormValues({
      ...formValues,
      vat: e.target.value
    })
  }


  const updateStatusHandler = () => {
    let tmpValues = [];
    for (let i =0; i <= 10; i++) {
      if(refs.statuses[i].current && refs.statuses[i].current.checked){
        tmpValues.push(Number(refs.statuses[i].current.value));
      }
    }
    setFormValues({
      ...formValues,
      statuses: tmpValues
    })
  }

  const updateFiltersHandler = (e) => {
    e.preventDefault();
    props.updateMainState({
      ...formValues,
      page: 1
    });
  }

  let statusItems = [];
  if(statuses.length > 0){
    statusItems = statuses.map((s, index) => {
      return (
        <Form.Group key={index} controlId={"formStatus" + index} className="mb-1">
          <Form.Check
            ref={refs.statuses[index]}
            name="status"
            type="checkbox"
            label={s.name}
            defaultChecked={formValues.statuses.includes(s.id)}
            value={s.id}
            onChange={updateStatusHandler}
          />
        </Form.Group>
      )
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#expandableFilters"
            >
              <span>
                <small><CaretDownFill /></small>
              </span>
            </button>
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
          <form onSubmit={updateFiltersHandler}>
            <Form.Group controlId="formRegistration" className="my-4">
              <Form.Label className="font-weight-bold">Registration</Form.Label>
              <Form.Control
                name="mark"
                type="text"
                className="text-uppercase"
                value={formValues.mark}
                onChange={updateMarkHandler}
              />
            </Form.Group>
            <Form.Group controlId="formType0" className="mt-4 mb-1">
              <Form.Label className="font-weight-bold">Type</Form.Label>
              <Form.Check
                ref={refs.dateless}
                name="type"
                type="checkbox"
                label="Dateless"
                defaultChecked={formValues.types.includes(1)}
                onChange={updateTypeHandler}
              />
            </Form.Group>
            <Form.Group controlId="formType1" className="mb-1">
              <Form.Check
                ref={refs.suffix}
                name="type"
                type="checkbox"
                label="Suffix"
                defaultChecked={formValues.types.includes(2)}
                onChange={updateTypeHandler}
              />
            </Form.Group>
            <Form.Group controlId="formType2" className="mb-1">
              <Form.Check
                ref={refs.prefix}
                name="type"
                type="checkbox"
                label="Prefix"
                defaultChecked={formValues.types.includes(3)}
                onChange={updateTypeHandler}
              />
            </Form.Group>
            <Form.Group controlId="formType3" className="mb-1">
              <Form.Check
                ref={refs.currentStyle}
                name="type"
                type="checkbox"
                label="Current Style"
                defaultChecked={formValues.types.includes(4)}
                onChange={updateTypeHandler}
              />
            </Form.Group>
            <Form.Label className="font-weight-bold mt-4">Price</Form.Label>
            <Form.Row>
              <Col md={12} lg={6}>
                <Form.Control
                  name="price_from"
                  type="text"
                  className="text-uppercase"
                  value={formValues.price_from}
                  onChange={updatePriceFromHandler}
                />
              </Col>
              <Col md={12} lg={6}>
              <Form.Control
                  name="price_to"
                  type="text"
                  className="text-uppercase"
                  value={formValues.price_to}
                  onChange={updatePriceToHandler}
                />
              </Col>
            </Form.Row>
            <Form.Label className="font-weight-bold mt-4">Status</Form.Label>
            {statusItems}
            <Form.Group controlId="formVat0" className="mt-4 mb-1">
              <Form.Label className="font-weight-bold">VAT</Form.Label>
              <Form.Check
                name="vat"
                type="radio"
                label="Any"
                defaultChecked={formValues.vat === 'Any'}
                value="Any"
                onChange={updateVatHandler}
              />
            </Form.Group>
            <Form.Group controlId="formVat1" className="mb-1">
              <Form.Check
                name="vat"
                type="radio"
                label="No Vat"
                defaultChecked={formValues.vat === 'No VAT'}
                value="No VAT"
                onChange={updateVatHandler}
              />
            </Form.Group>
            <Form.Group controlId="formVat2" className="mb-4">
              <Form.Check
                name="vat"
                type="radio"
                label="Vat"
                defaultChecked={formValues.vat === 'VAT'}
                value="VAT"
                onChange={updateVatHandler}
              />
            </Form.Group>
              <button type="submit" className="btn btn-dark btn-lg btn-block">
                Update
              </button>
              {/* <button type="button" className="btn btn-block" onClick={resetFiltersHandler}>
                Reset Filters
              </button>             */}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.registrationFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) => dispatch({ type: 'SET_REGISTRATION_FILTERS', payload: f }),
    resetMainState: () => dispatch({ type: 'RESET_REGISTRATION_FILTERS', payload: null }),
    apiAdminLoadStatuses: () => dispatch(apiAdminLoadStatuses())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealerRegistrationFilters));

