import React  from "react";
import CurrencyFormat from 'react-currency-format';

const Gbp = (props) => {
  return (
    <CurrencyFormat 
      value={props.amount} 
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'£'} 
    />
  )
}

export default Gbp;
