import React from 'react';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import CompanyForm from '../../../components/Admin/Companies/CompanyForm';

const EditCompany = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Edit Company</h1>
          <CompanyForm companyId={props.match.params.id}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(EditCompany);