import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";
import  { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Gbp from '../../utils/Gbp';
import NumberPlate from '../../NumberPlate';
import { apiAdminRegistrationSearch } from "../../../store/actions/actions";
import PaginationDetail from '../../PaginationDetail';
import { displayPrice, displayCostPrice } from '../../../utils';
import Dropdown from 'react-bootstrap/Dropdown'
import DeleteRegistrationModal from './DeleteRegistrationModal';

const RegistrationList = (props) => {
  const [registrationResults, setRegistrationResults] = useState([]);
  const [meta, setMeta] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [redirectToReporting, setRedirectToReporting] = useState(false);
  const [deleteRegistrationModal, setDeleteRegistrationModal] = useState(null);

  useEffect(() => {
    props.apiAdminRegistrationSearch(props.filters)
      .then(({ response }) => {
        setRegistrationResults(response.data.registrations);
        setMeta(response.data.meta);
      })
      .catch((error) => { })
  },[props.filters, refreshCounter, props.counter]);

  const previousPageHandler = (e) => {
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
    }
  }

  const nextPageHandler = (e) => {
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
    }
  }

  const reportHandler = (e, r) => {
    props.updateReportingState(
      {
        subscriber_email:'',
        registration: r.mark,
        event_type: '',
        page: 1
      }
    );
    setRedirectToReporting(true);
  }

  const deleteRegistrationCloseHandler = (e) => {
    setDeleteRegistrationModal(null);
  }

  const deleteRegistrationClickHandler = (e, index) => {
    setDeleteRegistrationModal(registrationResults[index]);
  }

  const deleteRegistrationConfirmedHandler = (e, id, mark) => {
    setDeleteRegistrationModal(null);
    setRefreshCounter(refreshCounter+1);
  }


  let registrationItems = null;
  if(registrationResults.length > 0){

    registrationItems = registrationResults.map((r, index) => {
      return (
        <tr key={index}>
          <th scope="row"><NumberPlate mark={r.mark}/></th>
          <td className=""><Gbp amount={displayPrice(r)}/></td>
          <td className=""><Gbp amount={displayCostPrice(r)}/></td>
          <td className="d-none d-md-table-cell">{r.status}</td>
          <td className="min">
            <div className="btn-group">
            <button type="button" className="btn btn-link text-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              &hellip;
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={'/admin/registrations/' + r.id} className="dropdown-item">
                  Edit
                </Link>
                <Button className="dropdown-item" onClick={(e) => reportHandler(e, r)}>
                  View Reports
                </Button>
                <Dropdown.Divider></Dropdown.Divider>
                <Button className="dropdown-item text-danger" onClick={(e) => deleteRegistrationClickHandler(e, index)}>
                  Delete
                </Button>
              </div>
            </div>
          </td>
        </tr>
      )
    });
  }

  if(registrationResults.length > 0) {
    let deleteRegistrationModalItem = null;
    if(deleteRegistrationModal !== null) {
      deleteRegistrationModalItem = <DeleteRegistrationModal reg={deleteRegistrationModal} onClose={deleteRegistrationCloseHandler} onConfirm={deleteRegistrationConfirmedHandler}/>;
    }

    let rtr = null;
    if(redirectToReporting) {
      rtr = <Redirect to="/admin/reporting"/>;
    }
    return (
      <React.Fragment>
        {rtr}
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Registration</th>
              <th scope="col" className="">Price</th>
              <th scope="col" className="">Cost Price</th>
              <th scope="col" className="d-none d-md-table-cell">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {registrationItems}
          </tbody>
        </table>
        <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} />
        {deleteRegistrationModalItem}
      </React.Fragment>
    )
  }
  return(
    <div></div>
  )
}

const mapStateToProps = state => {
  return {
    filters: state.registrationFilters.filters
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminRegistrationSearch: (f) => dispatch(apiAdminRegistrationSearch(f)),
    updateMainState: (f) => dispatch({ type: 'SET_REGISTRATION_FILTERS', payload: f }),
    updateReportingState: (f) => dispatch({ type: 'SET_REPORTING_FILTERS', payload: f }),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationList));