import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../../components/Layouts/AdminLayout';
import { apiAdminLoadPages, apiAdminDeletePage  } from "../../../store/actions/actions";
import  { Link } from 'react-router-dom';

const Pages = (props) => {
  const [pageResults, setPageResults] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    props.apiAdminLoadPages()
      .then(({ response }) => {
        setPageResults(response.data.pages);
      })
      .catch((error) => { })
  },[props.filters, refreshCounter]);

  const deleteHandler = (e, id) => {
    props.apiAdminDeletePage(id)
      .then(({ response }) => {
        setRefreshCounter(refreshCounter+1);
      })
      .catch((error) => { })
  }

  let informationItems = [];
  let dealerItems = [];
  let footerItems = [];
  let companyItems = [];
  let orderFormItems = [];
  if(pageResults) {
    informationItems = pageResults.information.map((p, index) => {
      return (
        <li className="list-group-item">
         <strong>{p.title}</strong>
          <span className="float-right text-dark text-hover-underline" onClick={(e) => { deleteHandler(e, p.id) }}>Delete</span>
          <Link to={"/admin/pages/" + p.id} className="float-right text-dark mr-4">Edit</Link>
        </li>
      )
    });
    dealerItems = pageResults.dealer_services.map((p, index) => {
      return (
        <li className="list-group-item">
          <strong>{p.title}</strong>
          <span className="float-right text-dark text-hover-underline" onClick={(e) => { deleteHandler(e, p.id) }}>Delete</span>
          <Link to={"/admin/pages/" + p.id} className="float-right text-dark mr-4">Edit</Link>
        </li>
      )
    });
    footerItems = pageResults.footer.map((p, index) => {
      return (
        <li className="list-group-item">
          <strong>{p.title}</strong>
          <span className="float-right text-dark text-hover-underline" onClick={(e) => { deleteHandler(e, p.id) }}>Delete</span>
          <Link to={"/admin/pages/" + p.id} className="float-right text-dark mr-4">Edit</Link>
        </li>
      )
    });
    companyItems = pageResults.company.map((p, index) => {
      return (
        <li className="list-group-item">
          <strong>{p.title}</strong>
          <span className="float-right text-dark text-hover-underline" onClick={(e) => { deleteHandler(e, p.id) }}>Delete</span>
          <Link to={"/admin/pages/" + p.id} className="float-right text-dark mr-4">Edit</Link>
        </li>
      )
    });
    orderFormItems = pageResults.order_form.map((p, index) => {
      return (
        <li className="list-group-item">
          <strong>{p.title}</strong>
          <span className="float-right text-dark text-hover-underline" onClick={(e) => { deleteHandler(e, p.id) }}>Delete</span>
          <Link to={"/admin/pages/" + p.id} className="float-right text-dark mr-4">Edit</Link>
        </li>
      )
    });
  }
  return (
    <AdminLayout>
      <div className="container">
        
        
        <div className="row my-4 d-flex align-items-center">
          <div className="col">
            <h1 className="">Pages</h1>
          </div>
          <div className="col text-right">
            <Link to="/admin/pages/add" className="btn btn-sm btn-dark">+ Add Page</Link>
          </div>
        </div>
        <div className="">
          <div className="row pt-2 mb-2  d-flex align-items-center">
            <div className="col">
              <h5 className="">Company</h5>
            </div>
            <div className="col text-right"></div>
          </div>
          <ul className="list-group">
            {companyItems}
          </ul>
        </div>
        <div className="mt-4">
          <div className="row mb-2 d-flex align-items-center">
            <div className="col">
              <h5 className="">Important Information</h5>
            </div>
            <div className="col text-right"></div>
          </div>
          <ul className="list-group">
            {informationItems}
          </ul>
        </div>
        <div className="mt-4">
          <div className="row pt-2 mb-2  d-flex align-items-center">
            <div className="col">
              <h5 className="">Dealer Services</h5>
            </div>
            <div className="col text-right"></div>
          </div>
          <ul className="list-group">
            {dealerItems}
          </ul>
        </div>
        <div className="mt-4">
          <div className="row pt-2 mb-2  d-flex align-items-center">
            <div className="col">
              <h5 className="">Footer</h5>
            </div>
            <div className="col text-right"></div>
          </div>
          <ul className="list-group">
            {footerItems}
          </ul>
        </div>
        <div className="mt-4">
          <div className="row pt-2 mb-2  d-flex align-items-center">
            <div className="col">
              <h5 className="">Order Form</h5>
            </div>
            <div className="col text-right"></div>
          </div>
          <ul className="list-group">
            {orderFormItems}
          </ul>
        </div>
      </div>
    </AdminLayout>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadPages: (f) => dispatch(apiAdminLoadPages(f)),
    apiAdminDeletePage: (id) => dispatch(apiAdminDeletePage(id)),
  };
};

export default connect(null, mapDispatchToProps)(Pages);