import React from "react";
import RegistrationFilters from "../../components/Registrations/RegistrationFilters";
import RegistrationList from "../../components/Registrations/RegistrationList";
import ResponseBar from "../../components/ResponseBar";
import Footer from '../../components/User/Footer/Footer';
import MainNav from '../../components/User/MainNav/MainNav';

const Registrations = () => {
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <div className="container-full mx-3">
            <h1 className="my-4">Registrations</h1>
            <div className="row">
              <div className="col-md-3 mb-4">
                <RegistrationFilters />
              </div>
              <div className="col-md-9">
                <RegistrationList />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
export default Registrations;
