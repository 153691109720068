import React from 'react';
import  { Link } from 'react-router-dom';
import AdminLayout from '../../../components/Layouts/AdminLayout';
import CompanyList from "../../../components/Admin/Companies/CompanyList";
import CompanyFilters from "../../../components/Admin/Companies/CompanyFilters";

const Companies = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="row d-flex align-items-center">
          <div className="col">
            <h1 className="my-4">Companies</h1>
          </div>
          <div className="col text-right">
            <Link to="/admin/companies/new" className="btn btn-sm btn-dark">+ Add Company</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
            <CompanyFilters />
          </div>
          <div className="col-md-9">
            <CompanyList />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Companies;