const initAuth = () => {
  return {
    filters: {
      companyName: '',
      statuses: [], 
      page: 1
    }
  };
};

function companyFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_COMPANY_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    default:
      return state;
  }
}

export default companyFilters;
