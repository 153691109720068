import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { apiAdminLoadCompanies } from '../../../store/actions/actions'
import Inflation from '../../Inflation'
import PaginationDetail from '../../PaginationDetail';
import UrlOrBlank from '../../UrlOrBlank';
import CompanyStatusBadge from './CompanyStatusBadge';
import EnableCompanyModal from './EnableCompanyModal';
import DisableCompanyModal from './DisableCompanyModal';
import  { Link } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'

const CompaniesList = (props) => {
  const [companies, setCompanies] = useState([]);
  const [meta, setMeta] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [enableCompanyModal, setEnableCompanyModal] = useState(null);
  const [disableCompanyModal, setDisableCompanyModal] = useState(null);
  const [redirectToUsers, setRedirectToUsers] = useState(false);

  useEffect(() => {
    props.apiAdminLoadCompanies({
      name: props.filters.companyName,
      statuses: props.filters.statuses,
      page: props.filters.page
    })
      .then(({ response }) => {
        setCompanies(response.data.companies);
        setMeta(response.data.meta);
      })
      .catch((error) => {
        console.log("Error retrieving companies");
      });
  }, [props.filters, props.page, refreshCounter]);

  const viewCompanyUsers = (e, name) => {
    e.preventDefault();
    props.updateUserState(
      {
        name: '',
        companyName: name,
        statuses: [], 
        optedIn: [],
        isAdmin: [],
        page: 1
      }
    );
    setRedirectToUsers(true);
  }

  const enableClickHandler = (e, index) => {
    setEnableCompanyModal(companies[index]);
  }

  const enableCloseHandler = (e) => {
    setEnableCompanyModal(null);
  }

  const processedEnableHandler = (e) => {
    setEnableCompanyModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const disableClickHandler = (e, index) => {
    setDisableCompanyModal(companies[index]);
  }

  const disableCloseHandler = (e) => {
    setDisableCompanyModal(null);
  }

  const processedDisableHandler = (e) => {
    setDisableCompanyModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const previousPageHandler = (e) => {
    console.log('previous');
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
    }
  }

  const nextPageHandler = (e) => {
    console.log('next');
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
    }
  }

  let companyItems = [];
  if( companies && companies.length > 0 ) {
    companyItems = companies.map((c, index) => {
      let buttons = null;
      
      if(c.status === 'Enabled'){
         buttons = <div>
          <Link to={'/admin/companies/' + c.id} className="dropdown-item">Edit</Link>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => disableClickHandler(e, index)}>Disable</button>
        </div>
      } else {
        buttons = <div>
          <Link to={'/admin/companies/' + c.id} className="dropdown-item">Edit</Link>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => enableClickHandler(e, index)}>Enable</button>
        </div>
      }
      return <tr>
        <td><strong>{c.name}</strong></td>
        <td className="d-none d-md-table-cell">{c.location}</td>
        <td className="d-none d-md-table-cell"><UrlOrBlank url={c.website}/></td>
        <td><Inflation value={c.inflate_prices}/></td>
        <td>
          <Link to="/admin/users" onClick={(e) => viewCompanyUsers(e, c.name)}>
            {c.subscriber_count} users
          </Link>
        </td>
        <td><CompanyStatusBadge status={c.status}/></td>
        <td className="min">
          <div class="btn-group">
          <button type="button" class="btn btn-link text-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            &hellip;
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="drop2">
              {buttons}
            </div>
          </div>
        </td>
      </tr>
    });
  }

  let enableCompanyModalItem = null;
  if(enableCompanyModal) {
    enableCompanyModalItem = <EnableCompanyModal company={enableCompanyModal}  onClose={enableCloseHandler} onConfirm={processedEnableHandler}/>
  }

  let disableCompanyModalItem = null;
  if(disableCompanyModal) {
    disableCompanyModalItem = <DisableCompanyModal company={disableCompanyModal}  onClose={disableCloseHandler} onConfirm={processedDisableHandler}/>
  }

  let redirect = null;
  if(redirectToUsers) {
    redirect = <Redirect to='/admin/users' />;
  }
  return (
    <div>
      {redirect}
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Company</th>
            <th scope="col" className="d-none d-md-table-cell">Location</th>
            <th scope="col" className="d-none d-md-table-cell">Website</th>
            <th scope="col">Inflate prices</th>
            <th scope="col">Users</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {companyItems}
        </tbody>
      </table>
      <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} />
      {enableCompanyModalItem}
      {disableCompanyModalItem}
    </div>
    )
}

const mapStateToProps = state => {
  return {
    filters: state.companyFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadCompanies: (f) => dispatch(apiAdminLoadCompanies(f)),
    updateMainState: (f) => dispatch({ type: 'SET_COMPANY_FILTERS', payload: f }),
    updateUserState: (f) => dispatch({ type: 'SET_USER_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompaniesList));
