import React, { useState, useEffect } from "react";
import { CaretDownFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Form } from 'react-bootstrap';

const ReportingFilters = (props) => {
  const [formValues, setFormValues] = useState(props.filters);

  useEffect(() => {
    
  }, []);

  const updateSubscriberHandler = (e) => {
    setFormValues({
      ...formValues,
      subscriber_email: e.target.value
    })
  }

  const updateRegistrationHandler = (e) => {
    setFormValues({
      ...formValues,
      registration: e.target.value
    })
  }

  const updateEventTypeHandler = (e) => {
    setFormValues({
      ...formValues,
      event_type: e.target.value
    })
  }

  const updateFiltersHandler = (e) => {
    e.preventDefault();
    props.updateMainState({
      ...formValues,
      page: 1
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#expandableFilters"
            >
              <span>
                <small><CaretDownFill /></small>
              </span>
            </button>
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
          <form onSubmit={updateFiltersHandler}>
            <Form.Group controlId="formSubscriber" className="my-4">
              <Form.Label className="font-weight-bold">Subscriber Email</Form.Label>
              <Form.Control
                name="subscriber_email"
                type="text"
                className=""
                value={formValues.subscriber_email}
                onChange={updateSubscriberHandler}
              />
            </Form.Group>
            <Form.Group controlId="formRegistration" className="my-4">
              <Form.Label className="font-weight-bold">Registration</Form.Label>
              <Form.Control
                name="registration"
                type="text"
                className="text-uppercase"
                value={formValues.registration}
                onChange={updateRegistrationHandler}
              />
            </Form.Group>
            <Form.Group controlId="formEventType" className="my-4">
              <Form.Label className="font-weight-bold">Event Type</Form.Label>
              <Form.Control
                name="event_type"
                as="select"
                className=""
                defaultValue={formValues.event_type}
                onChange={updateEventTypeHandler}
                >
                  <option value={''}></option>
                  <option value={'LOGIN'}>Login</option>
                  <option value={'SEARCH'}>Search</option>
                  <option value={'OFFER'}>Offer</option>
                  <option value={'EXPORT'}>Export</option>
                  <option value={'PURCHASE'}>Purchase</option>
                </Form.Control>
            </Form.Group>
            
              <button type="submit" className="btn btn-dark btn-lg btn-block">
                Update
              </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.reportingFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) => dispatch({ type: 'SET_REPORTING_FILTERS', payload: f }),
    resetMainState: () => dispatch({ type: 'RESET_REPORTING_FILTERS', payload: null }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportingFilters));

