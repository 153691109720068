import React, { useState, useEffect }  from "react";
import { Form, Button, Card } from 'react-bootstrap';
import  { Link } from 'react-router-dom';
import { apiAdminLoadStatus } from '../../../store/actions/actions';
import { apiAdminSaveStatus } from '../../../store/actions/actions';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import StatusTypeConfigurator from './StatusTypeConfigurator';
import { anyErrors } from '../../../utils';


const StatusForm = (props) => {
  const defaultErrors = {
    name: false,
    enable_certificate_fast_track: false,
    enable_certificate_printed: false,
    enable_vehicle: false,
    profit_adjustment: false
  }

  const [status, setStatus] = useState(null);
  const [errors, setErrors] = useState({...defaultErrors})
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  
  useEffect(() => {
    if(props.statusId === null) {
      setStatus({
        id: null,
        name: '',
        enable_certificate_fast_track: false,
        certificate_fast_track_transfer_fee_id: null,
        certificate_fast_track_requires_nominee: false,
        enable_certificate_printed: false,
        certificate_printed_transfer_fee_id: null,
        certificate_printed_requires_nominee: false,
        enable_vehicle: false,
        vehicle_transfer_fee_id: null,
        vehicle_requires_nominee: false,
        profit_adjustment: 0.00,
        status_certificate_fast_track_status_notes_ids: [],
        status_certificate_printed_status_notes_ids: [],
        status_vehicle_status_notes_ids: []
      });
    } else {
      props.apiAdminLoadStatus(props.statusId)
        .then(({ response }) => {
          setStatus(response.data.status);
        })
        .catch((error) => { });
    }
  }, [props.statusId]);

  useEffect(() => {
    if(status !== null) {
      let tmpErrors = { ...defaultErrors };
      
      if(status.name === ''){
        tmpErrors.name = true;
      }

      setErrors(tmpErrors);
    }
  }, [status, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    console.log(e, fieldName);
    let tmpStatus = { 
      ...status 
    }
    tmpStatus[fieldName] = e.target.value;

    setStatus(tmpStatus);
  }

  const fastTrackUpdateHandler = (v) => {
    setStatus(
      {
        ...status,
        enable_certificate_fast_track: v.enabled,
        certificate_fast_track_transfer_fee_id: v.transfer_fee_id,
        certificate_fast_track_requires_nominee: v.requires_nominee,
        status_certificate_fast_track_status_notes_ids: v.status_notes_ids
      }
    )
  }

  const printedUpdateHandler = (v) => {
    setStatus(
      {
        ...status,
        enable_certificate_printed: v.enabled,
        certificate_printed_transfer_fee_id: v.transfer_fee_id,
        certificate_printed_requires_nominee: v.requires_nominee,
        status_certificate_printed_status_notes_ids: v.status_notes_ids
      }
    )
  }

  const vehicleUpdateHandler = (v) => {
    setStatus(
      {
        ...status,
        enable_vehicle: v.enabled,
        vehicle_transfer_fee_id: v.transfer_fee_id,
        vehicle_requires_nominee: v.requires_nominee,
        status_vehicle_status_notes_ids: v.status_notes_ids
      }
    )
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors(errors)){
      props.apiAdminSaveStatus(status)
        .then(({ response }) => {
          props.notify('The status ' + status.name +  ' was successfully saved');
          setSaved(true);
        })
        .catch((error) => { });
    }
  }

  let redirect = null;
  if(saved) {
    redirect = <Redirect to="/admin/settings/statuses"/>
  }
  
  let form = null;

  if(status) {
    form = <Form onSubmit={submitFormHandler}>
      {redirect}
      <Card className="mb-4">
        <Card.Header as="h5">Status details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder=""
              className={errors.name && submissionAttempted  ? 'is-invalid' : ''}
              value={status.name}
              onChange={(e) => fieldUpdateHandler(e, 'name')}
            />
          </Form.Group>
          <Form.Group controlId="formProfit">
            <Form.Label className="font-weight-bold">Profit Adjustment</Form.Label>
            <Form.Control
              name="profit_adjustment"
              type="text"
              placeholder=""
              className={errors.profit_adjustment && submissionAttempted  ? 'is-invalid' : ''}
              value={status.profit_adjustment}
              onChange={(e) => fieldUpdateHandler(e, 'profit_adjustment')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <StatusTypeConfigurator title="Fast-track" defaults={
          {
            enabled: status.enable_certificate_fast_track, 
            transfer_fee_id: status.certificate_fast_track_transfer_fee_id,
            requires_nominee: status.certificate_fast_track_requires_nominee,
            status_notes_ids: status.status_certificate_fast_track_status_notes_ids
          }
        }
        onUpdate={fastTrackUpdateHandler}>  
      </StatusTypeConfigurator>
      <StatusTypeConfigurator title="Printed" defaults={
          {
            enabled: status.enable_certificate_printed, 
            transfer_fee_id: status.certificate_printed_transfer_fee_id,
            requires_nominee: status.certificate_printed_requires_nominee,
            status_notes_ids: status.status_certificate_printed_status_notes_ids
          }
        }
        onUpdate={printedUpdateHandler}>  
      </StatusTypeConfigurator>
      <StatusTypeConfigurator title="Vehicle" defaults={
          {
            enabled: status.enable_vehicle, 
            transfer_fee_id: status.vehicle_transfer_fee_id,
            requires_nominee: status.vehicle_requires_nominee,
            status_notes_ids: status.status_vehicle_status_notes_ids
          }
        }
        onUpdate={vehicleUpdateHandler}>  
      </StatusTypeConfigurator>
      {/* <StatusTypeConfigurator title="Printed" defaults={{enabled: false}}></StatusTypeConfigurator>
      <StatusTypeConfigurator title="Vehicle" defaults={{enabled: false}}></StatusTypeConfigurator> */}
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
      <Link to="/admin/settings/statuses" className="btn btn-secondary">Cancel</Link>
    </Form>
  }
  return (
    form
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadStatus: (sid) => dispatch(apiAdminLoadStatus(sid)),
    apiAdminSaveStatus: (s) => dispatch(apiAdminSaveStatus(s)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(null, mapDispatchToProps)(StatusForm));
