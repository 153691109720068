import React  from "react";
import validation from 'uk-numberplate-format';

const PlainNumberPlate = (props) => {
  let mark = null;
  validation.validate(props.mark, (err,data) => {
    mark = data.plate;
  });
  
  return (
    <span>{mark}</span>
  )
}

export default PlainNumberPlate;
