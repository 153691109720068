import React, { useState, useEffect } from "react";
import  { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiLoadRegistration } from "../../store/actions/actions";
import { apiSubmitEnquiry } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";

const EnquireForm = (props) => {
  
  // ***** Default state *****
  const [registration, setRegistration] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [orderFormData, setOrderFormData] = useState({
    enquiry: null,
  });
  const [orderFormErrors, setOrderFormErrors] = useState({
    enquiry: false
  });

  // ***** Mount handler *****
  
  // Trigger API call when registration mark changes
  useEffect(() => {
    props.apiLoadRegistration(props.mark)
      .then(({ response }) => {
        setApiError(false)
        setRegistration(response.data.registration)
      })
      .catch((error) => {
        setApiError(true)
        setRegistration(null);
      });
  }, [props.mark]);

  // ***** Validation handler *****
  const validate = () => {
    // Store errors
    let tmpErrors = {
      enquiry: false
    }
    if(!orderFormData.enquiry) {
      tmpErrors.enquiry = true;
    }
    
    setOrderFormErrors(tmpErrors);
  }

  const anyErrors = () => {
    return (
      orderFormErrors.enquiry
    )
  }

  useEffect(() => {
    if(!registration) return
    validate();
  }, [registration, orderFormData]);

  // ***** Field change handlers *****
  const enquiryChangeHandler = (e) => {
    setOrderFormData({
      ...orderFormData,
      enquiry: e.target.value
    })
  }

  // ***** Form submit handler *****
  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    validate(); 
    if(!anyErrors()){
      props.apiSubmitEnquiry(registration.mark, orderFormData.enquiry)
        .then(({ response }) => {
          console.log(response);
          props.onSubmit(response);
        })
        .catch((error) => {
          
        });
    }
  }

  let form =    <div></div>;
  if(apiError) {
    form = <div>Registration Not Found</div>;
  } else {
    if(registration) {
      let errorMessage = <div></div>;

      if(submissionAttempted && anyErrors() === true) {
        errorMessage = <div class="text-danger mb-4"><small>Please review the highlighted errors and try again.</small></div>;
      }
      
      form = <form onSubmit={formSubmitHandler}>
      <div class="card mb-4">
        <div class="card-header">
          <strong>Your Enquiry</strong>
        </div>
        <div class="card-body">
          <div class="form-group">
            <textarea
              class={orderFormErrors.enquiry && submissionAttempted ? 'form-control is-invalid' : 'form-control'} 
              id="enquiry" 
              name="enquiry" 
              placeholder="Ask away..."
              value={orderFormData.enquiry}
              onChange={enquiryChangeHandler}
              rows="5"
            ></textarea>
          
          </div>
        </div>
      </div>
      <div className="mb-5">
        {errorMessage}
        <button type="submit" class="btn btn-dark mr-2">Submit enquiry</button>
        <Link to="/registrations" className="btn btn-secondary">
          Cancel
        </Link>
      </div>
    </form>
    }
  }
  return (
    <div class="container">
      {form}
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadRegistration: (m) => dispatch(apiLoadRegistration(m)),
    apiSubmitEnquiry: (m, e) => dispatch(apiSubmitEnquiry(m, e)),
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(null, mapDispatchToProps)(EnquireForm));

