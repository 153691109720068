import React, { useState, useEffect } from "react";
import  { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiLoadRegistration } from "../../store/actions/actions";
import { apiSubmitOffer } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";

const OfferForm = (props) => {
  
  // ***** Default state *****
  const [registration, setRegistration] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [orderFormData, setOrderFormData] = useState({
    offer_price: null,
    comments: null,
  });
  const [orderFormErrors, setOrderFormErrors] = useState({
    offer_price: false,
    comments: false
  });

  // ***** Mount handler *****
  
  // Trigger API call when registration mark changes
  useEffect(() => {
    props.apiLoadRegistration(props.mark)
      .then(({ response }) => {
        setApiError(false)
        setRegistration(response.data.registration)
      })
      .catch((error) => {
        setApiError(true)
        setRegistration(null);
      });
  }, [props.mark]);

  const displayPrice = (r) => {
    if(r.reduced_price > 0) {
      return r.reduced_price;
    }
    return r.price;
  }

  // ***** Validation handler *****
  const validate = () => {
    // Store errors
    let tmpErrors = {
      offer_price: false,
      comments: false
    }
    if(!orderFormData.offer_price) {
      tmpErrors.offer_price = true;
    }
    
    setOrderFormErrors(tmpErrors);
  }

  const anyErrors = () => {
    return (
      orderFormErrors.offer_price
    )
  }

  useEffect(() => {
    if(!registration) return
    validate();
  }, [registration, orderFormData]);

  // ***** Field change handlers *****
  const offerPriceChangeHandler = (e) => {
    setOrderFormData({
      ...orderFormData,
      offer_price: e.target.value
    })
  }

  // ***** Field change handlers *****
  const commentsChangeHandler = (e) => {
    setOrderFormData({
      ...orderFormData,
      comments: e.target.value
    })
  }

  // ***** Form submit handler *****
  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    validate(); 
    if(!anyErrors()){
      props.apiSubmitOffer(registration.mark, orderFormData.offer_price, orderFormData.comments)
        .then(({ response }) => {
          console.log(response);
          props.onSubmit(response);
        })
        .catch((error) => {
          
        });
    }
  }

  let form =    <div></div>;
  if(apiError) {
    form = <div>Registration Not Found</div>;
  } else {
    if(registration) {
      let errorMessage = <div></div>;

      if(submissionAttempted && anyErrors() === true) {
        errorMessage = <div class="text-danger mb-4"><small>Please review the highlighted errors and try again.</small></div>;
      }
      
      form = <form onSubmit={formSubmitHandler}>
      <div class="card mb-4">
        <div class="card-header">
          <strong>Your Offer (GBP)</strong>
        </div>
        <div class="card-body">
          <div class="form-group">
            <input
              type="number" 
              class={orderFormErrors.offer_price && submissionAttempted ? 'form-control is-invalid' : 'form-control'} 
              id="offer_price" 
              name="offer_price" 
              placeholder={displayPrice(registration)}
              value={orderFormData.offer_price}
              onChange={offerPriceChangeHandler}
            />
          
          </div>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header">
          <strong>Your Comments</strong>
        </div>
        <div class="card-body">
          <div class="form-group">
            <textarea
              class={orderFormErrors.comments && submissionAttempted ? 'form-control is-invalid' : 'form-control'} 
              id="comments" 
              name="comments" 
              placeholder="Add any comments..."
              value={orderFormData.comments}
              onChange={commentsChangeHandler}
              rows="5"
            ></textarea>
          
          </div>
        </div>
      </div>
      <div className="mb-5">
        {errorMessage}
        <button type="submit" class="btn btn-dark mr-2">Submit offer</button>
        <Link to="/registrations" className="btn btn-secondary">
          Cancel
        </Link>
      </div>
    </form>
    }
  }
  return (
    <div class="container">
      {form}
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadRegistration: (m) => dispatch(apiLoadRegistration(m)),
    apiSubmitOffer: (m, p, c) => dispatch(apiSubmitOffer(m, p, c)),
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(null, mapDispatchToProps)(OfferForm));

