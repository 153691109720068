import React from 'react';
import AdminLayout from '../../../components/Layouts/AdminLayout';
import PageForm from '../../../components/Admin/Pages/PageForm';

const AddPage = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Add Page</h1>
          <PageForm pageId={null}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AddPage;
