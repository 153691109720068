import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { X } from 'react-bootstrap-icons'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
const Notifications = (props) => {
  console.log(props);
  const dismisshandler = () => {
    props.markAsRead();
  }

  let notificationItem = [];
  let popup = null;
  if(props.data) {
    popup = <OverlayTrigger
          id={'notificationId'}
          trigger="hover"
          key={'pop1'}
          placement={'top'}
          overlay={<Popover 
            className="custom-popup">
            <Popover.Title as="h3">Additional Info</Popover.Title>
            <Popover.Content>
              {props.data}
            </Popover.Content>
          </Popover>
          }
          >
            <span className="ml-2 mr-4"><InfoCircle/></span>
          </OverlayTrigger>
  }
  if(props.notification) {
    notificationItem = <div className="custom-notification border border-light text-dark bg-warning">
        {props.notification}{popup}
        <span className="ml-2">
          <X className="cursor-pointer" onClick={dismisshandler}></X>
        </span>
      </div>;
  }
  return (
    <div>
      {notificationItem}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    notification: state.notifications.message,
    data: state.notifications.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    markAsRead: () => dispatch({ type: 'MARK_AS_READ' })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));

