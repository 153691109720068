import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiSaveApplication } from "../../store/actions/actions";
import Footer from "../../components/User/Footer/Footer";
import MainNav from "../../components/User/MainNav/MainNav";
import { Link } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import countryList from "react-select-country-list";
import validator from "validator";

const Apply = (props) => {
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);

  const [applicationForm, setApplicationForm] = useState({
    name: "",
    website: "",
    address_1: "",
    address_2: "",
    city: "",
    county: "",
    country: "United Kingdom",
    post_code: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    opted_in: true,
    position: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    website: false,
    address_1: false,
    address_2: false,
    city: false,
    county: false,
    country: false,
    post_code: false,
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm_password: false,
    opted_in: false,
    position: false,
  });

  const refs = {
    optedIn: useRef(null),
  };

  const fieldUpdateHandler = (e, fieldName) => {
    let tmpApplicationForm = {
      ...applicationForm,
    };

    tmpApplicationForm[fieldName] = e.target.value;
    setApplicationForm(tmpApplicationForm);
  };

  const anyErrors = () => {
    for (const [, value] of Object.entries(errors)) {
      if (value) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (applicationForm !== null) {
      let tmpErrors = {
        name: false,
        inflate_prices: false,
        website: false,
        address_1: false,
        address_2: false,
        city: false,
        county: false,
        country: false,
        post_code: false,
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        confirm_password: false,
        opted_in: false,
        position: false,
      };
      if (validator.isEmpty(applicationForm.first_name)) {
        tmpErrors.first_name = true;
      }
      if (validator.isEmpty(applicationForm.last_name)) {
        tmpErrors.last_name = true;
      }
      if (
        validator.isEmpty(applicationForm.email) ||
        !validator.isEmail(applicationForm.email)
      ) {
        tmpErrors.email = true;
      }
      if (validator.isEmpty(applicationForm.position)) {
        tmpErrors.position = true;
      }
      if (validator.isEmpty(applicationForm.password)) {
        tmpErrors.password = true;
      }
      if (
        validator.isEmpty(applicationForm.confirm_password) ||
        applicationForm.password != applicationForm.confirm_password
      ) {
        tmpErrors.confirm_password = true;
      }
      if (validator.isEmpty(applicationForm.name)) {
        tmpErrors.name = true;
      }
      if (
        !validator.isEmpty(applicationForm.website) &&
        !validator.isURL(applicationForm.website, { require_protocol: true })
      ) {
        tmpErrors.website = true;
      }
      if (validator.isEmpty(applicationForm.address_1)) {
        tmpErrors.address_1 = true;
      }
      if (validator.isEmpty(applicationForm.city)) {
        tmpErrors.city = true;
      }
      if (validator.isEmpty(applicationForm.county)) {
        tmpErrors.county = true;
      }
      if (validator.isEmpty(applicationForm.country)) {
        tmpErrors.country = true;
      }
      if (validator.isEmpty(applicationForm.post_code)) {
        tmpErrors.post_code = true;
      }
      setErrors(tmpErrors);
    }
  }, [applicationForm, submissionAttempted]);

  const isOptedInHandler = (e) => {
    let tmpApplicationForm = {
      ...applicationForm,
    };

    tmpApplicationForm.opted_in = refs.optedIn.current.checked;
    setApplicationForm(tmpApplicationForm);
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if (!anyErrors()) {
      props
        .apiSaveApplication(applicationForm)
        .then(({ response }) => {
          setSaved(true);
        })
        .catch((error) => {});
    }
  };

  let countryOptions = countryList()
    .getData()
    .map((c, index) => {
      return (
        <option key={index} value={c.label}>
          {c.label}
        </option>
      );
    });

  let form = null;
  if (saved) {
    form = (
      <React.Fragment>
        <p>
          Thank you. We've received your application and will respond shortly.
        </p>
        <Link to="/" className="btn btn-dark">
          Back to the homepage
        </Link>
      </React.Fragment>
    );
  } else {
    form = (
      <React.Fragment>
        <p>
          If you are a member of the trade and would like to apply for access to
          the T12ADE platform, please complete an application below.
        </p>
        <Form onSubmit={submitFormHandler}>
          <Card className="my-4">
            <Card.Header as="h5">Company details</Card.Header>
            <Card.Body>
              <Form.Group controlId="formName">
                <Form.Label className="font-weight-bold">
                  Company Name
                </Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Name"
                  className={
                    submissionAttempted && errors.name ? "is-invalid" : ""
                  }
                  value={applicationForm.name}
                  onChange={(e) => fieldUpdateHandler(e, "name")}
                />
              </Form.Group>
              <Form.Group controlId="formWebsite">
                <Form.Label className="font-weight-bold">
                  Website <small>(Optional)</small>
                </Form.Label>
                <Form.Control
                  name="website"
                  type="text"
                  placeholder="https://"
                  className={
                    submissionAttempted && errors.website ? "is-invalid" : ""
                  }
                  value={applicationForm.website}
                  onChange={(e) => fieldUpdateHandler(e, "website")}
                />
              </Form.Group>

              <Form.Group controlId="formAddress">
                <Form.Label className="font-weight-bold">Address</Form.Label>
                <Form.Control
                  name="address_1"
                  type="text"
                  placeholder="Street"
                  className={
                    submissionAttempted && errors.address_1 ? "is-invalid" : ""
                  }
                  value={applicationForm.address_1}
                  onChange={(e) => fieldUpdateHandler(e, "address_1")}
                />
              </Form.Group>
              <Form.Group controlId="formAddress2">
                <Form.Control
                  name="address_2"
                  type="text"
                  placeholder=""
                  className={
                    submissionAttempted && errors.address_2 ? "is-invalid" : ""
                  }
                  value={applicationForm.address_2}
                  onChange={(e) => fieldUpdateHandler(e, "address_2")}
                />
              </Form.Group>
              <Form.Group controlId="formAddress3">
                <Form.Control
                  name="city"
                  type="text"
                  placeholder="City"
                  className={
                    submissionAttempted && errors.city ? "is-invalid" : ""
                  }
                  value={applicationForm.city}
                  onChange={(e) => fieldUpdateHandler(e, "city")}
                />
              </Form.Group>
              <Form.Group controlId="formAddress4">
                <Form.Control
                  name="county"
                  type="text"
                  placeholder="County"
                  className={
                    submissionAttempted && errors.county ? "is-invalid" : ""
                  }
                  value={applicationForm.county}
                  onChange={(e) => fieldUpdateHandler(e, "county")}
                />
              </Form.Group>
              <Form.Group controlId="formAddress5">
                <Form.Control
                  name="country"
                  as="select"
                  className={
                    submissionAttempted && errors.country ? "is-invalid" : ""
                  }
                  defaultValue={applicationForm.country}
                  onChange={(e) => fieldUpdateHandler(e, "country")}
                >
                  {countryOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formAddress6">
                <Form.Control
                  name="post_code"
                  type="text"
                  placeholder="Postcode"
                  className={
                    submissionAttempted && errors.post_code ? "is-invalid" : ""
                  }
                  value={applicationForm.post_code}
                  onChange={(e) => fieldUpdateHandler(e, "post_code")}
                />
              </Form.Group>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header as="h5">Your details</Card.Header>
            <Card.Body>
              <Form.Group controlId="formName">
                <Form.Label className="font-weight-bold">First Name</Form.Label>
                <Form.Control
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  className={
                    submissionAttempted && errors.first_name ? "is-invalid" : ""
                  }
                  value={applicationForm.first_name}
                  onChange={(e) => fieldUpdateHandler(e, "first_name")}
                />
              </Form.Group>
              <Form.Group controlId="formName">
                <Form.Label className="font-weight-bold">Last Name</Form.Label>
                <Form.Control
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  className={
                    submissionAttempted && errors.last_name ? "is-invalid" : ""
                  }
                  value={applicationForm.last_name}
                  onChange={(e) => fieldUpdateHandler(e, "last_name")}
                />
              </Form.Group>

              <Form.Group controlId="formName">
                <Form.Label className="font-weight-bold">Email</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="Email"
                  className={
                    submissionAttempted && errors.email ? "is-invalid" : ""
                  }
                  value={applicationForm.email}
                  onChange={(e) => fieldUpdateHandler(e, "email")}
                />
              </Form.Group>
              <Form.Group controlId="formPosition">
                <Form.Label className="font-weight-bold">Position</Form.Label>
                <Form.Control
                  name="position"
                  type="text"
                  placeholder="Position"
                  className={
                    submissionAttempted && errors.position ? "is-invalid" : ""
                  }
                  value={applicationForm.position}
                  onChange={(e) => fieldUpdateHandler(e, "position")}
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label className="font-weight-bold">Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder=""
                  className={
                    submissionAttempted && errors.password ? "is-invalid" : ""
                  }
                  value={applicationForm.password}
                  onChange={(e) => fieldUpdateHandler(e, "password")}
                />
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label className="font-weight-bold">
                  Confirm Password
                </Form.Label>
                <Form.Control
                  name="confirm_password"
                  type="password"
                  placeholder=""
                  className={
                    submissionAttempted && errors.confirm_password
                      ? "is-invalid"
                      : ""
                  }
                  value={applicationForm.confirm_password}
                  onChange={(e) => fieldUpdateHandler(e, "confirm_password")}
                />
              </Form.Group>

              <Form.Group controlId="formOptedIn" className="mt-4 mb-1">
                <Form.Check
                  name="opted_in"
                  ref={refs.optedIn}
                  type="checkbox"
                  label="Opt in to receive communications"
                  defaultChecked={applicationForm.opted_in}
                  onChange={isOptedInHandler}
                />
              </Form.Group>
            </Card.Body>
          </Card>
          <Button type="submit" variant="dark" className="my-4 mr-2">
            Submit application
          </Button>
          <Link to="/login" className="btn btn-outline-secondary ml-2">
            Already a member?
          </Link>
        </Form>
      </React.Fragment>
    );
  }

  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <div className="container py-4">
            <h1 className="mb-4 pb-4 border-bottom">Application Form</h1>
            {form}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiSaveApplication: (d) => dispatch(apiSaveApplication(d)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Apply);
