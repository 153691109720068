import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { apiLogin } from "../../store/actions/actions";
import Footer from "../../components/User/Footer/Footer";
import MainNav from "../../components/User/MainNav/MainNav";
import { Link } from "react-router-dom";
import validator from "validator";

const Login = (props) => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const anyErrors = () => {
    for (const [, value] of Object.entries(errors)) {
      if (value) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (loginForm !== null) {
      let tmpErrors = {
        email: false,
        password: false,
      };
      if (
        validator.isEmpty(loginForm.email) ||
        !validator.isEmail(loginForm.email)
      ) {
        tmpErrors.email = true;
      }
      if (validator.isEmpty(loginForm.password)) {
        tmpErrors.password = true;
      }
      setErrors(tmpErrors);
    }
  }, [loginForm, submissionAttempted]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if (!anyErrors()) {
      props
        .apiLogin({ email: loginForm.email, password: loginForm.password })
        .then(({ response }) => {
          console.log(response);
        })
        .catch((error) => {
          setLoginFailed(true);
        });
    }
  };

  const emailChangeHandler = (e) => {
    setLoginFailed(false);
    setLoginForm({
      ...loginForm,
      email: e.target.value,
    });
  };

  const passwordChangeHandler = (e) => {
    setLoginFailed(false);
    setLoginForm({
      ...loginForm,
      password: e.target.value,
    });
  };

  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <div className="container py-4">
            <h1 className="mb-4 pb-4 border-bottom">Member Login</h1>
            <form id="loginForm" onSubmit={formSubmitHandler}>
              <div class="card mb-4">
                <div class="card-header">
                  <strong>Login Details</strong>
                </div>
                <div class="card-body">
                  <div className="form-group">
                    <label for="email">Email address</label>
                    <input
                      type="email"
                      className={
                        submissionAttempted && (loginFailed || errors.email)
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="email"
                      name="email"
                      onChange={emailChangeHandler}
                    />
                  </div>
                  <div className="form-group">
                    <label for="password">Password</label>
                    <input
                      type="password"
                      className={
                        submissionAttempted && (loginFailed || errors.password)
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="password"
                      name="password"
                      onChange={passwordChangeHandler}
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-dark">
                Login
              </button>
              <Link to="/apply" className="btn btn-outline-secondary ml-2">
                Not registered?
              </Link>
              <Link
                to="/forgotten-password"
                className="btn ml-2 text-underline"
              >
                Forgotten your password?
              </Link>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiLogin: (u) => dispatch(apiLogin(u)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
