import React from 'react';
import AdminLayout from '../../../components/Layouts/AdminLayout';
import TransferFeesList from '../../../components/Admin/TransferFeeList'

const TransferFees = (props) => {
  return (
    <AdminLayout>
      <div className="container">
        <h1 className="my-4 pb-4 border-bottom">Transfer Fees</h1>
        <p className=""><strong>Manage your list of available transfer fees.</strong> (Used when configuring statuses)</p>
        <TransferFeesList/>
      </div>
    </AdminLayout>
  )
}

export default TransferFees;
