import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from "react-router-dom";
import { apiAdminLoadSubscribers } from '../../../store/actions/actions'
import PaginationDetail from '../../PaginationDetail';
import YesOrNo from '../../YesOrNo';
import UserStatusBadge from './UserStatusBadge';
import EnableUserModal from './EnableUserModal';
import DisableUserModal from './DisableUserModal';
import UserCompanyName from './UserCompanyName';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'

const UserList = (props) => {
  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [enableUserModal, setEnableUserModal] = useState(null);
  const [disableUserModal, setDisableUserModal] = useState(null);
  const [redirectToReporting, setRedirectToReporting] = useState(false);

  const reportHandler = (e, u) => {
    props.updateReportingState(
      {
        subscriber_email: u.email,
        registration: '',
        event_type: '',
        page: 1
      }
    );
    setRedirectToReporting(true);
  }

  useEffect(() => {
    props.apiAdminLoadSubscribers({
      name: props.filters.name,
      company_name: props.filters.companyName,
      statuses: props.filters.statuses,
      opted_in: props.filters.optedIn,
      is_admin: props.filters.isAdmin,
      page: props.filters.page
    })
      .then(({ response }) => {
        setUsers(response.data.subscribers);
        setMeta(response.data.meta);
      })
      .catch((error) => {
        console.log("Error retrieving users");
      });
  }, [props.filters, props.page, refreshCounter]);

  const enableClickHandler = (e, index) => {
    setEnableUserModal(users[index]);
  }

  const enableCloseHandler = (e) => {
    setEnableUserModal(null);
  }

  const processedEnableHandler = (e) => {
    setEnableUserModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const disableClickHandler = (e, index) => {
    setDisableUserModal(users[index]);
  }

  const disableCloseHandler = (e) => {
    setDisableUserModal(null);
  }

  const processedDisableHandler = (e) => {
    setDisableUserModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const previousPageHandler = (e) => {
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
    }
  }

  const nextPageHandler = (e) => {
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
    } 
  }
  let userItems = [];
  if( users && users.length > 0 ) {
    userItems = users.map((s, index) => {
      let buttons = null;
      
      if(s.status === 'Enabled'){
         buttons = <div>
          <Link to={'/admin/users/' + s.id} className="dropdown-item">Edit</Link>
          <Button className="dropdown-item" onClick={(e) => reportHandler(e, s)}>
            View Reports
          </Button>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => disableClickHandler(e, index)}>Disable</button>
        </div>
      } else {
        buttons = <div>
          <Link to={'/admin/users/' + s.id} className="dropdown-item">Edit</Link>
          <Button className="dropdown-item" onClick={(e) => reportHandler(e, s)}>
            View Reports
          </Button>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => enableClickHandler(e, index)}>Enable</button>
        </div>
      }
      return <tr key={index}>
        <td><strong>{s.full_name}</strong></td>
        <td><UserCompanyName name={s.company_name} status={s.company_status}/></td>
        <td><YesOrNo value={s.opted_in}/></td>
        <td><YesOrNo value={s.is_admin}/></td>
        <td><UserStatusBadge status={s.status}/></td>
        <td className="min">
          <div className="btn-group">
          <button type="button" className="btn btn-link text-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            &hellip;
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="drop2">
              {buttons}
            </div>
          </div>
        </td>
      </tr>
    });
  }

  let enableUserModalItem = null;
  if(enableUserModal) {
    enableUserModalItem = <EnableUserModal user={enableUserModal}  onClose={enableCloseHandler} onConfirm={processedEnableHandler}/>
  }

  let disableUserModalItem = null;
  if(disableUserModal) {
    disableUserModalItem = <DisableUserModal user={disableUserModal}  onClose={disableCloseHandler} onConfirm={processedDisableHandler}/>
  }
  let rtr = null;
  if(redirectToReporting) {
    rtr = <Redirect to="/admin/reporting"/>;
  }
  return (
    <div>
      {rtr}
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Company</th>
            <th scope="col">Opted in?</th>
            <th scope="col">Is admin?</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {userItems}
        </tbody>
      </table>
      <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} />
      {enableUserModalItem}
      {disableUserModalItem}
    </div>
    )
}

const mapStateToProps = state => {
  return {
    filters: state.userFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadSubscribers: (f) => dispatch(apiAdminLoadSubscribers(f)),
    updateMainState: (f) => dispatch({ type: 'SET_USER_FILTERS', payload: f }),
    updateReportingState: (f) => dispatch({ type: 'SET_REPORTING_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserList));
