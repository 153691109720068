import React from "react";
import { CaretDownFill } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

const FiltersCloseButton = (props) => {
  return (
  <Button
      variant=""
      type="button"
      className="close"
      data-toggle="collapse"
      data-target="#expandableFilters"
    >
      <span aria-hidden="true">
        <small><CaretDownFill/></small>
      </span>
    </Button>
  )
}

export default FiltersCloseButton;