import React, { useState, useRef } from "react";
import { CaretDownFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';

const CompanyFilters = (props) => {
  const refs = {
    active: useRef(null),
    disabled: useRef(null)
  }
  
  const [formValues, setFormValues] = useState({
    companyName: props.filters.companyName,
    statuses: props.filters.statuses
  });

  const updateStatusHandler = (e) => {
    let tmpValues = [];
    if (refs.active.current.checked) {
      tmpValues.push(0);
    }
    if (refs.disabled.current.checked) {
      tmpValues.push(1);
    }
    setFormValues({
      ...formValues,
      statuses: tmpValues
    })
  }

  const updateCompanyNameHandler = (e) => {
    setFormValues({
      ...formValues,
      companyName: e.target.value
    })
  }

  
  const updateFiltersHandler = () => {
    props.updateMainState({
      ...formValues,
      page: 1
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <Button
              variant=""
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#expandableFilters"
            >
              <span aria-hidden="true">
                <small><CaretDownFill/></small>
              </span>
            </Button>
           
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
          <Form.Group controlId="formName" className="my-4">
            <Form.Label className="font-weight-bold">Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              className=""
              value={formValues.companyName}
              onChange={updateCompanyNameHandler}
            />
          </Form.Group>
          <Form.Group controlId="formStatus0" className="mt-4 mb-1">
            <Form.Label className="font-weight-bold">Status</Form.Label>
            <Form.Check
              ref={refs.active}
              name="status"
              type="checkbox"
              label="Enabled"
              defaultChecked={formValues.statuses.includes(0)}
              onChange={updateStatusHandler}
            />
          </Form.Group>
          <Form.Group controlId="formStatus1" className="mb-4">
            <Form.Check
              ref={refs.disabled}
              name="status"
              type="checkbox"
              label="Disabled"
              defaultChecked={formValues.statuses.includes(1)}
              onChange={updateStatusHandler}
            />
          </Form.Group>
          <Button
            type="button"
            className="btn btn-dark btn-lg btn-block"
            onClick={updateFiltersHandler}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.companyFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) => dispatch({ type: 'SET_COMPANY_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyFilters));
