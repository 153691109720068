import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiAdminSaveTransferFee, apiAdminDeleteTransferFee } from "../../store/actions/actions";
import { PencilSquare, CheckSquareFill, XSquare } from 'react-bootstrap-icons';
import { Row, Col } from 'react-bootstrap';
import validator from "validator";
import Gbp from '../../components/utils/Gbp'

const TransferFeeItem = (props) => {
  const [editor, setEditor] = useState(props.transferFee.id == null);
  const [fee, setFee] = useState(props.transferFee.fee);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    fee: false,
    save: false
  })

  const enableEditorHandler = (e) => {
    setEditor(!editor);
  }

  const changeHandler = (e) => {
    setFee(e.target.value);
    setErrors({
      ...errors,
      save: false
    })
  }

  const validate = () => {
    let tmpErrors = {
      fee: false
    }

    if(!fee || fee === '' || !validator.isDecimal(fee)) {
      tmpErrors.fee = true;
    }
    setErrors(tmpErrors);
  }

  const anyErrors = () => {
    return errors.fee || errors.save;
  }

  useEffect(() => {
    validate();
  }, [fee]);

  const deleteHandler = (e) => {
    if(!props.transferFee.in_use) {
      if ( props.transferFee.id === null ) {
        props.deleteHandler(props.index);
        return;
      }
      props.apiAdminDeleteTransferFee(props.transferFee.id)
        .then(({ response }) => {
          props.deleteHandler(props.index);
        })
        .catch((error) => {
          console.log("Error deleteing transfer fee");
        });
    }
  }

  const saveHandler = (e) => {
    setSaved(true);
    if (!anyErrors()){
      props.apiAdminSaveTransferFee({ id: props.transferFee.id, fee: fee })
        .then(({ response }) => {
          props.updateHandler(props.index, response.data.transfer_fee);
          setEditor(!editor);
        })
        .catch((error) => {
          setErrors({fee: true, save: true})
        });
    }
  }

  let feeClass = 'form-control';
  if( saved && anyErrors() ) {
    feeClass = 'form-control is-invalid';
  }

  let content = <Gbp amount={fee}/>;
  if (editor) {
    content = <input 
      type="text"
      className={feeClass}
      placeholder="Enter amount (exluding £ sign)..."
      value={fee}
      onChange={changeHandler}/>;
  }

  let icon = <PencilSquare className="cursor-pointer" onClick={enableEditorHandler} />;
  if( editor ) {
    icon = <CheckSquareFill className="cursor-pointer" onClick={saveHandler} />;
  }

  let deleteItem = <XSquare className="ml-2 text-muted"/>;;
  if(!props.transferFee.in_use){
    deleteItem= <XSquare className="ml-2 text-danger cursor-pointer" onClick={deleteHandler}/>;
  }

  return (
    <li className="list-group-item" key={props.index}>
      <Row>
        <Col xs={10}>{content}</Col>
        <Col xs={2} className="text-right">
          {icon}
          {deleteItem}
        </Col>
      </Row>
    </li>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    apiAdminSaveTransferFee: (tf) => dispatch(apiAdminSaveTransferFee(tf)),
    apiAdminDeleteTransferFee: (id) => dispatch(apiAdminDeleteTransferFee(id)),
  };
};

export default connect(null, mapDispatchToProps)(TransferFeeItem);
