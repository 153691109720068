import React, {  } from "react";

const MoreInformationFormIntro = (props) => {
  let copy = '';

  if(!props.error){
    // Copy for when page registration is loaded.
    copy =  <div>
              <h1 class="my-4">{props.heading}</h1>
            </div>;
  } else {
    // Copy for when an error occurred.
    copy =  <div>
              <h1 class="my-4">Oops</h1>
            </div>;
  }
  return (
    copy
  );
};

export default MoreInformationFormIntro;

