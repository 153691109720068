import sub from 'date-fns/sub'
const sd = new Date(2012, 1, 1);

const initAuth = () => {
  return {
    filters: {
      startDate: sd,
      endDate: new Date(),
      invoice_no: '',
      registration: '',
      company: '',
      statuses: [], 
      paymentStatuses: [],
      page: 1
    }
  };
};

function orderFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_ORDER_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    default:
      return state;
  }
}

export default orderFilters;
