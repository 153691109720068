import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ResponseBar from "../../components/ResponseBar";
import { withRouter } from "react-router-dom";
import OrderForm from "../../components/OrderForm/OrderForm";
import ConditionalMessage from "../../components/ConditionalMessage/ConditionalMessage";
import MainNav from "../../components/User/MainNav/MainNav";
import Footer from "../../components/User/Footer/Footer";
import { INVOICE_TERMS } from "../../constants";
import PlainNumberPlate from "../../components/PlainNumberPlate";

const Registration = (props) => {
  const [submitState, setSubmitState] = useState({
    submitted: false,
    altContent: (
      <div className="container">
        <h1 class="my-4">Order Form</h1>
        <p>
          Thank you. Your order for{" "}
          <strong>
            <PlainNumberPlate mark={props.match.params.mark} />
          </strong>{" "}
          has been accepted. An invoice has been generated and you have{" "}
          {INVOICE_TERMS} days to make the full payment.
        </p>
        <Link to="/invoices" className="btn btn-dark mr-2">
          View invoices
        </Link>
      </div>
    ),
  });

  const submitHandler = (r) => {
    let tmpState = {
      ...submitState,
      submitted: true,
    };
    setSubmitState(tmpState);
  };

  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <ConditionalMessage
            display={submitState.submitted}
            altContent={submitState.altContent}
          >
            <OrderForm
              mark={props.match.params.mark}
              onSubmit={submitHandler}
            />
          </ConditionalMessage>
        </div>
      </main>
      <Footer />
    </div>
  );
};

//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(null, null)(Registration));
