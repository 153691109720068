import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiAdminLoadStatusNotes } from "../../store/actions/actions";
import StatusNoteItem from './StatusNoteItem'
import { Button } from 'react-bootstrap';

const StatusNoteList = (props) => {
  const [statusNotes, setStatusNotes] = useState([]);

  const updateStatusNoteItemHandler = (index, n) => {
    let tmpStatusNotes = [...statusNotes];
    tmpStatusNotes[index] = n;
    setStatusNotes(tmpStatusNotes);
  }

  const deleteStatusNoteItemHandler = (index) => {
    let tmpStatusNotes = [...statusNotes];
    tmpStatusNotes.splice(index, 1);
    setStatusNotes(tmpStatusNotes);
  }

  const addHandler = (e) => {
    let tmpStatusNotes = [...statusNotes];
    tmpStatusNotes.push({
      id: null,
      note: ''
    })
    setStatusNotes(tmpStatusNotes);
  }

  useEffect(() => {
    props.apiAdminLoadStatusNotes()
      .then(({ response }) => {
        setStatusNotes(response.data.status_notes);
      })
      .catch((error) => {
        console.log("Error retrieving status notes");
      });
  }, []);

  let statusNoteItems = [];
  if( statusNotes && statusNotes.length > 0 ) {
    statusNoteItems = statusNotes.map((sn, index) => {
      return <StatusNoteItem key={index} statusNote={sn} index={index} updateHandler={updateStatusNoteItemHandler} deleteHandler={deleteStatusNoteItemHandler} />
    });
  }

  return (
    <div>
      <ul className="pt-4 list-group">
        {statusNoteItems}
      </ul>
      <Button variant="dark" onClick={addHandler} className="my-4">+ Add new note</Button>
    </div>
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiAdminLoadStatusNotes: () => dispatch(apiAdminLoadStatusNotes()),
  };
};

export default connect(null, mapDispatchToProps)(StatusNoteList);
