import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { apiAdminLoadEvents } from "../../../store/actions/actions";
import PaginationDetail from "../../PaginationDetail";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

const ReportingList = (props) => {
  const [eventResults, setEventResults] = useState([]);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    props
      .apiAdminLoadEvents(props.filters)
      .then(({ response }) => {
        setEventResults(response.data.events);
        setMeta(response.data.meta);
      })
      .catch((error) => {});
  }, [props.filters, props.counter]);

  const previousPageHandler = (e) => {
    if (props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: props.filters.page - 1,
      });
    }
  };

  const nextPageHandler = (e) => {
    if (meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: props.filters.page + 1,
      });
    }
  };

  let eventItems = null;
  if (eventResults.length > 0) {
    eventItems = eventResults.map((r, index) => {
      let ad = null;
      if (r.additional_data !== null && r.additional_data !== "") {
        let adKv = [];
        for (const [key, value] of Object.entries(r.additional_data)) {
          if (key == "mark_cont") {
            adKv.push(<span className="">{value}</span>);
          }
        }
        ad = <>{adKv}</>;
      }

      return (
        <tr key={index}>
          <td className="">{r.date}</td>
          <th scope="row">
            {r.company} <small>({r.subscriber_email})</small>
          </th>
          <td className="">{r.event_type}</td>
          <td className="">{r.registration}</td>
        </tr>
      );
    });
  }

  if (eventResults.length > 0) {
    return (
      <React.Fragment>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col" className="">
                Date
              </th>
              <th scope="col">Company</th>
              <th scope="col" className="">
                Event Type
              </th>
              <th scope="col">Registration</th>
            </tr>
          </thead>
          <tbody>{eventItems}</tbody>
        </table>
        <PaginationDetail
          meta={meta}
          onPreviousClick={previousPageHandler}
          onNextClick={nextPageHandler}
        />
      </React.Fragment>
    );
  }
  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    filters: state.reportingFilters.filters,
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadEvents: (f) => dispatch(apiAdminLoadEvents(f)),
    updateMainState: (f) =>
      dispatch({ type: "SET_REPORTING_FILTERS", payload: f }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportingList)
);
