const initAuth = () => {
  return {
    message:  null,
    data: null
  };
};

function notifications(state = initAuth(), action) {
  switch (action.type) {
    case "NOTIFY":
      return { ...state, ...{ message: action.msg, data: action.data } };
    case "MARK_AS_READ":
      return { ...state, ...{ message: null, data: null } };
  }
  return state;
}


export default notifications;
