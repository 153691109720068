import React, { useState } from "react";
import { connect } from "react-redux";
import AdminLayout from "../../../components/Layouts/AdminLayout";
import ExpiryList from "../../../components/Admin/Registrations/ExpiryList";
import { apiAdminExportExpiries } from "../../../store/actions/actions";
import { Button } from "react-bootstrap";

const Expiries = (props) => {
  const exportHandler = (e) => {
    setExporting(true);
    props
      .apiAdminExportExpiries(props.filters)
      .then(({ response }) => {
        setExporting(false);
      })
      .catch((error) => {
        setExporting(false);
      });
  };

  const [exporting, setExporting] = useState(false);

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row my-4 d-flex align-items-center">
          <div className="col col-md-4">
            <h1 className="">Certificate Expiry Dates</h1>
          </div>
          <div className="col-md-8 text-md-right">
            <Button
              variant="dark"
              className={exporting ? "btn-sm mr-2 disabled" : "btn-sm mr-2"}
              onClick={exportHandler}
            >
              Export
            </Button>
          </div>
        </div>
        <div className="">
          <div className="container-full">
            <div className="row">
              <div className="col-md-12">
                <ExpiryList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminExportExpiries: (f) => dispatch(apiAdminExportExpiries(f)),
    notify: (msg, data) => dispatch({ type: "NOTIFY", msg: msg, data: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expiries);
