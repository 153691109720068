import React, { useRef, useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ResponseBar from "../../components/ResponseBar";
import Footer from '../../components/User/Footer/Footer';
import MainNav from '../../components/User/MainNav/MainNav';
import { apiExportRegistrations } from '../../store/actions/actions';

const RegistrationsDownload = (props) => {
  const [formValues, setFormValues] = useState(props.filters);
  const [exporting, setExporting] = useState(false);

  let refs = {
    columnRegistration: useRef(null),
    columnPrice: useRef(null),
    columnVatAmount: useRef(null),
    columnStatus: useRef(null),
    columnExpiryDate: useRef(null),
    columnNotes: useRef(null),
    vatAny: useRef(null),
    vatYes: useRef(null),
    vatNo: useRef(null),
  }

  const updateColumnsHandler = () => {
    let tmpColumns = []
    if(refs.columnRegistration.current.checked) {
      tmpColumns.push('Registration');
    }
    if(refs.columnPrice.current.checked) {
      tmpColumns.push('Price');
    }
    if(refs.columnVatAmount.current.checked) {
      tmpColumns.push('VAT Amount');
    }
    if(refs.columnStatus.current.checked) {
      tmpColumns.push('Status');
    }
    if(refs.columnExpiryDate.current.checked) {
      tmpColumns.push('Expiry Date');
    }
    if(refs.columnNotes.current.checked) {
      tmpColumns.push('Notes');
    }

    let tmpConfig = {
      ...formValues,
      columns: tmpColumns
    }
    
    setFormValues(tmpConfig);
    props.updateMainState(tmpConfig);
  }

  const updateVatHandler = (e) => {
    let tmpConfig = {
      ...formValues,
      vat: e.target.value
    }
    setFormValues(tmpConfig)
    props.updateMainState(tmpConfig);
  }

  const exportXlsxHandler = () => {
    setExporting(true);
    props.apiExportRegistrations(formValues, 'xlsx')
    .then(() => {
      setExporting(false);
    })
    .catch(() => { 
      setExporting(false);
    })
  }

  const exportCsvHandler = () => {
    setExporting(true);
    props.apiExportRegistrations(formValues, 'csv')
    .then(() => {
      setExporting(false);
    })
    .catch(() => { 
      setExporting(false);
    })
  }

  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <div className="container py-4">
            <h1 className="mb-4 pb-4 border-bottom">Download Stock List</h1>
            <p className="my-4">Use the configuration tool below to create your download.</p>
            <div class="row">
              <div class="col-md mb-md-0 mb-4">
                <div class="card mb-6 h-100">
                  <div class="card-header">
                    <strong>Required Columns</strong>
                  </div>
                  <div class="card-body">
                    <Form.Group controlId="formColumnRegistration" className="mb-1">
                      <Form.Check
                        ref={refs.columnRegistration}
                        name="type"
                        type="checkbox"
                        label="Registration"
                        defaultChecked={formValues.columns.includes('Registration')}
                        onChange={updateColumnsHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formColumnPrice" className="mb-1">
                      <Form.Check
                        ref={refs.columnPrice}
                        name="type"
                        type="checkbox"
                        label="Price"
                        defaultChecked={formValues.columns.includes('Price')}
                        onChange={updateColumnsHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formColumnVatAmount" className="mb-1">
                      <Form.Check
                        ref={refs.columnVatAmount}
                        name="type"
                        type="checkbox"
                        label="VAT Amount"
                        defaultChecked={formValues.columns.includes('VAT Amount')}
                        onChange={updateColumnsHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formColumnStatus" className="mb-1">
                      <Form.Check
                        ref={refs.columnStatus}
                        name="type"
                        type="checkbox"
                        label="Status"
                        defaultChecked={formValues.columns.includes('Status')}
                        onChange={updateColumnsHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formColumnExpiryDate" className="mb-1">
                      <Form.Check
                        ref={refs.columnExpiryDate}
                        name="type"
                        type="checkbox"
                        label="Expiry Date"
                        defaultChecked={formValues.columns.includes('Expiry Date')}
                        onChange={updateColumnsHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formColumnNotes" className="mb-1">
                      <Form.Check
                        ref={refs.columnNotes}
                        name="type"
                        type="checkbox"
                        label="Notes"
                        defaultChecked={formValues.columns.includes('Notes')}
                        onChange={updateColumnsHandler}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="card mb-6 h-100">
                  <div class="card-header">
                    <strong>Filters</strong>
                  </div>
                  <div class="card-body">
                    <Form.Group controlId="formVatAny" className="mb-1">
                      <Form.Check
                        ref={refs.vatAny}
                        name="vat"
                        type="radio"
                        label="Show all registrations"
                        defaultChecked={formValues.vat === ''}
                        value=""
                        onChange={updateVatHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formVatNo" className="mb-1">
                      <Form.Check
                        ref={refs.vatNo}
                        name="vat"
                        type="radio"
                        label="Show registrations not subject to VAT"
                        defaultChecked={formValues.vat === 'No VAT'}
                        value="No VAT"
                        onChange={updateVatHandler}
                      />
                    </Form.Group>
                    <Form.Group controlId="formVatYes" className="mb-1">
                      <Form.Check
                        ref={refs.vatYes}
                        name="vat"
                        type="radio"
                        label="Show registrations subject to VAT"
                        defaultChecked={formValues.vat === 'VAT'}
                        value="VAT"
                        onChange={updateVatHandler}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div class="w-100"></div>
            </div>
            <div class="row no-gutters my-4">
              <div class="btn-group">
                <button type="button" class={exporting ? "btn btn-dark dropdown-toggle disabled" : "btn btn-dark dropdown-toggle"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Download as
                </button>
                <div class="dropdown-menu">
                  <Button className="btn dropdown-item" onClick={exportXlsxHandler}>Excel (.xlsx)</Button>
                  <Button className="btn dropdown-item" onClick={exportCsvHandler}>CSV (.csv)</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    filters: state.publicDownloadConfig.config
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiExportRegistrations: (f, format) => dispatch(apiExportRegistrations(f, format)),
    updateMainState: (f) => dispatch({ type: 'SET_PUBLIC_DOWNLOAD_CONFIG', payload: f }),
    resetMainState: () => dispatch({ type: 'RESET_PUBLIC_DOWNLOAD_CONFIG', payload: null })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationsDownload));