import React  from "react";
import { CircleFill } from 'react-bootstrap-icons';

const InvoiceStatusBadge = (props) => {
  let variant = 'warning';
  switch(props.status){
    case 'New':
      variant = 'warning';
      break;
    case 'Processed':
      variant = 'success';
      break;
    case 'Cancelled':
      variant = 'danger';
      break;
    default:
      break;
  }
  
  return (
    <small className="text-nowrap">
      <CircleFill className={'text-' + variant}/><span className="text-muted pl-2">{props.status}</span>
    </small>
  )
}

export default InvoiceStatusBadge;
