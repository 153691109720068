import React from "react";
import { connect } from 'react-redux';
 
const ResponseBar = (props) => {
  let status = null;
  for(let i=0; i<props.pages.company.length; i++)  {
    if(props.pages.company[i].slug === 't12ade-status'){
      status = props.pages.company[i];
    }
  }

  return (
    <div className="alert alert-warning text-center" role="alert">
      {status.content.replace(/(<([^>]+)>)/gi, "")}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    pages: state.pages.pageList
  };
};

export default connect(mapStateToProps, null)(ResponseBar);