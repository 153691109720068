import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import UserList from "../../../components/Admin/Users/UserList";
import UserFilters from "../../../components/Admin/Users/UserFilters";
import { apiAdminExportSubscribers, apiAdminSendStockList } from '../../../store/actions/actions'
import { Button } from 'react-bootstrap';

const Users = (props) => {
  const exportHandler = (e) => {
    props.apiAdminExportSubscribers({
      name: props.filters.name,
      company_name: props.filters.companyName,
      statuses: props.filters.statuses,
      opted_in: props.filters.optedIn,
      is_admin: props.filters.isAdmin
    })
  }

  const sendStockListHandler = (e) => {
    props.apiAdminSendStockList('')
      .then(({ response }) => {
        props.notify('Stock list mailer has been queued');
      })
  }

  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="row d-flex align-items-center">
          <div className="col">
            <h1 className="my-4">Users</h1>
          </div>
          <div className="col text-right">
            <Button variant="dark" className="btn-sm mr-2" onClick={sendStockListHandler}>Email Stocklist</Button>
            <Button variant="dark" className="btn-sm mr-2" onClick={exportHandler}>Export</Button>
            <Link to="/admin/users/new" className="btn btn-sm btn-dark">+ Add User</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
            <UserFilters />
          </div>
          <div className="col-md-9">
            <UserList />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

const mapStateToProps = state => {
  return {
    filters: state.userFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminExportSubscribers: (f) => dispatch(apiAdminExportSubscribers(f)),
    apiAdminSendStockList: (message) => dispatch(apiAdminSendStockList(message)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);