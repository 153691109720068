import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiLoadPage } from "../../../store/actions/actions";
import { withRouter } from "react-router-dom";
import ResponseBar from "../../../components/ResponseBar";

class Page extends Component {


  state = {
    page: null
  }

  loadPage = () => {
    this.props.apiLoadPage(this.props.match.params.slug)
        .then(({ response }) => {
          this.setState({
            ...this.state, page: response.data.page
          });
        })
        .catch((error) => {
          console.log("Error retrieving pages");
        });
  }

  componentDidMount = () => {
      this.loadPage();
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== this.state.page.slug) {
      this.loadPage();
    }
  }

  render() {
    let output = <div></div>;
    if(this.state.page != null) {
      output =  <div>
                  <ResponseBar/>
                  <div className="container">
                    <h1 class="my-4 pb-4 border-bottom">{this.state.page.title}</h1>
                    <div  dangerouslySetInnerHTML={{ __html: this.state.page.content }}></div>
                  </div>
                </div>;
    }
    return (
      output
    );
  }
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiLoadPage: (s) => dispatch(apiLoadPage(s)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Page));
