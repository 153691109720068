import React, { useState, useRef } from "react";
import { CaretDownFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Form } from 'react-bootstrap';
import CompanyNameDropdown from '../../CompanyNameDropdown';

const OfferFilters = (props) => {

  const [formValues, setFormValues] = useState(props.filters);

  const refs = {
    pending: useRef(null),
    accepted: useRef(null),
    countered: useRef(null),
    declined: useRef(null),
    expired: useRef(null)
  }

  const updateRegistrationHandler = (e) => {
    setFormValues({
      ...formValues,
      registration: e.target.value
    })
  }

  const updateCompanyHandler = (n) => {
    setFormValues({
      ...formValues,
      company: n
    })
  }


  const updateStatusHandler = (e) => {
    let tmpValues = [];
    if (refs.pending.current.checked) {
      tmpValues.push(0);
    }
    if (refs.accepted.current.checked) {
      tmpValues.push(1);
    }
    if (refs.countered.current.checked) {
      tmpValues.push(2);
    }
    if (refs.declined.current.checked) {
      tmpValues.push(3);
    }
    if (refs.expired.current.checked) {
      tmpValues.push(4);
    }
    setFormValues({
      ...formValues,
      statuses: tmpValues
    })
  }

  const updateFiltersHandler = (e) => {
    e.preventDefault();
    props.updateMainState({
      ...formValues,
      page: 1
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#expandableFilters"
            >
              <span>
                <small><CaretDownFill /></small>
              </span>
            </button>
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
          <form onSubmit={updateFiltersHandler}>
            <Form.Group controlId="formRegistration" className="my-4">
              <Form.Label className="font-weight-bold">Registration</Form.Label>
              <Form.Control
                name="registration"
                type="text"
                className="text-uppercase"
                value={formValues.registration}
                onChange={updateRegistrationHandler}
              />
            </Form.Group>
            <CompanyNameDropdown label="Company name" companyName={formValues.company} onUpdate={updateCompanyHandler}/>
            <Form.Group controlId="formStatus0" className="mt-4 mb-1">
              <Form.Label className="font-weight-bold">Status</Form.Label>
              <Form.Check
                ref={refs.pending}
                name="status"
                type="checkbox"
                label="Pending"
                defaultChecked={formValues.statuses.includes(0)}
                onChange={updateStatusHandler}
              />
            </Form.Group>
            <Form.Group controlId="formStatus1" className="mb-1">
              <Form.Check
                ref={refs.accepted}
                name="status"
                type="checkbox"
                label="Accepted"
                defaultChecked={formValues.statuses.includes(1)}
                onChange={updateStatusHandler}
              />
            </Form.Group>
            <Form.Group controlId="formStatus2" className="mb-1">
              <Form.Check
                ref={refs.countered}
                name="status"
                type="checkbox"
                label="Countered"
                defaultChecked={formValues.statuses.includes(2)}
                onChange={updateStatusHandler}
              />
            </Form.Group>
            <Form.Group controlId="formStatus3" className="mb-1">
              <Form.Check
                ref={refs.declined}
                name="status"
                type="checkbox"
                label="Declined"
                defaultChecked={formValues.statuses.includes(3)}
                onChange={updateStatusHandler}
              />
            </Form.Group>
            <Form.Group controlId="formStatus4" className="mb-4">
              <Form.Check
                ref={refs.expired}
                name="status"
                type="checkbox"
                label="Expired"
                defaultChecked={formValues.statuses.includes(4)}
                onChange={updateStatusHandler}
              />
            </Form.Group>
            <button type="submit" className="btn btn-dark btn-lg btn-block">
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.offerFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) => dispatch({ type: 'SET_OFFER_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferFilters));

