import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { apiAdminLoadStatusNotes } from '../../../store/actions/actions';

const uniqid = require('uniqid');

const StatusTermsConfigurator = (props) => {
  const [statusNotes, setStatusNotes] = useState(null);
  const checkboxGroupName = uniqid();

  const refs = [];
  for(let i=0; i< 20; i++) {
    refs.push(useRef(null));
  }

  useEffect(() => {
    props.apiAdminLoadStatusNotes()
      .then(({ response }) => {
        setStatusNotes(response.data.status_notes);
      })
      .catch((error) => {
        console.log("Error retrieving status notes");
      });
  }, []);

  const changeHandler = (e) => {
    let tmpIds = [];
    for(let i=0; i< 20; i++) {
      if(refs[i].current) {
        if(refs[i].current.checked) {
          tmpIds.push(parseInt(refs[i].current.value));
        }
      }
    }
    props.changeHandler(tmpIds);
  }


  let formItem = null;

  if(statusNotes) {
    console.log("Rendering");
    let formItemItems = statusNotes.map((sn, index) => {
      console.log(props.defaults.status_notes_ids.includes(sn.id));
      return(
        <li key={index}>
          <Form.Group controlId={checkboxGroupName + index} className="my-0 py-0">
            <Form.Check
              ref={refs[index]}
              type="checkbox"
              label={sn.note}
              defaultChecked={props.defaults.status_notes_ids.includes(sn.id)}
              value={sn.id}
              onChange={changeHandler}
            />
          </Form.Group>
        </li>
      )
    });
    formItem = <ul className="list-unstyled">
      {formItemItems}
    </ul>
  }


  return (
    <React.Fragment>
      <h6 className="mb-3">Status Notes</h6>
      {formItem}
    </React.Fragment>
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiAdminLoadStatusNotes: () => dispatch(apiAdminLoadStatusNotes()),
  };
};

export default connect(null, mapDispatchToProps)(StatusTermsConfigurator);

