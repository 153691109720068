const initAuth = () => {
  return {
    active: false,
  };
};

function killSwitch(state = initAuth(), action) {
  switch (action.type) {
    case "TOGGLE_KILL_SWITCH":
      return { ...state, ...{ active: !state.active } };
    default:
      return state;
  }
}

export default killSwitch;
