import React  from "react";
import { BoxArrowUpRight }  from 'react-bootstrap-icons';
const UrlOrBlank = (props) => {
  let urlItem = <span>N/A</span>;
  if(props.url !== ''){
    urlItem = <span>
      <small className="mr-2 offset-top-2"><BoxArrowUpRight/></small> 
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        {props.url}
      </a>
    </span>
  }
  
  return (
    urlItem
  )
}

export default UrlOrBlank;
