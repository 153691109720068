import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiAdminLoadTransferFees } from "../../store/actions/actions";
import TransferFeeItem from './TransferFeeItem'
import { Button } from 'react-bootstrap';

const TransferFeeList = (props) => {
  const [transferFees, setTransferFees] = useState([]);

  const updateTransferFeeItemHandler = (index, n) => {
    let tmpTransferFees = [...transferFees];
    tmpTransferFees[index] = n;
    setTransferFees(tmpTransferFees);
  }

  const deleteTransferFeeItemHandler = (index) => {
    let tmpTransferFees = [...transferFees];
    tmpTransferFees.splice(index, 1);
    setTransferFees(tmpTransferFees);
  }

  const addHandler = (e) => {
    let tmpTransferFees = [...transferFees];
    tmpTransferFees.push({
      id: null,
      fee: ''
    })
    setTransferFees(tmpTransferFees);
  }

  useEffect(() => {
    props.apiAdminLoadTransferFees()
      .then(({ response }) => {
        setTransferFees(response.data.transfer_fees);
      })
      .catch((error) => {
        console.log("Error retrieving transfer_fees");
      });
  }, []);

  let transferFeeItems = [];
  if( transferFees && transferFees.length > 0 ) {
    transferFeeItems = transferFees.map((tf, index) => {
      return <TransferFeeItem key={index} transferFee={tf} index={index} updateHandler={updateTransferFeeItemHandler} deleteHandler={deleteTransferFeeItemHandler} />
    });
  }

  return (
    <div>
      <ul className="pt-4 list-group">
        {transferFeeItems}
      </ul>
      <Button variant="dark" onClick={addHandler} className="my-4">+ Add new fee</Button>
    </div>
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiAdminLoadTransferFees: () => dispatch(apiAdminLoadTransferFees()),
  };
};

export default connect(null, mapDispatchToProps)(TransferFeeList);
