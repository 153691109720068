import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiAdminLoadStatuses, apiAdminDeleteStatus } from "../../../store/actions/actions";
import { Row, Col } from 'react-bootstrap';

const StatusList = (props) => {
  const [statuses, setStatuses] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const deleteHandler = (e, id) => {
    props.apiAdminDeleteStatus(id)
      .then(({ response }) => {
        props.notify('Status was successfully deleted');
        setRefreshCounter(refreshCounter+1)
      })
      .catch((error) => {
        props.notify('Error deleting the status');
      });
  }

  useEffect(() => {
    props.apiAdminLoadStatuses()
      .then(({ response }) => {
        setStatuses(response.data.statuses);
      })
      .catch((error) => {
        console.log("Error retrieving statuses");
      });
  }, [refreshCounter]);

  let statusItems = [];
  if( statuses && statuses.length > 0 ) {
    statusItems = statuses.map((s, index) => {
      return <li className="list-group-item" key={index}>
        <Row>
          <Col xs={10}>{s.name}</Col>
          <Col xs={2} className="text-right">
            <Link to={"/admin/settings/statuses/" + s.id} className="text-dark mr-4">Edit</Link>
            <span className="float-right text-dark text-hover-underline" onClick={(e) => { deleteHandler(e, s.id) }}>Delete</span>
          </Col>
        </Row>
      </li>
    });
  }

  return (
    <div>
      <ul className="pt-4 list-group">
        {statusItems}
      </ul>
    </div>
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiAdminLoadStatuses: () => dispatch(apiAdminLoadStatuses()),
    apiAdminDeleteStatus: (id) => dispatch(apiAdminDeleteStatus(id)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(StatusList);
