import React, { useState, useEffect }  from "react";
import { Form, Button, Card } from 'react-bootstrap';
import  { Link } from 'react-router-dom';
import { apiAdminLoadEmailTemplate } from '../../../store/actions/actions';
import { apiAdminSaveEmailTemplate } from '../../../store/actions/actions';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { anyErrors } from '../../../utils';


const EmailForm = (props) => {
  const defaultErrors = {
    name: false,
    subject: false,
    content: false,
  }

  const [emailTemplate, setEmailTemplate] = useState(null);
  const [errors, setErrors] = useState({...defaultErrors})
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  
  useEffect(() => {
    props.apiAdminLoadEmailTemplate(props.emailTemplateId)
      .then(({ response }) => {
        setEmailTemplate(response.data.template);
      })
      .catch((error) => { });
  }, [props.emailTemplateId]);

  useEffect(() => {
    if(emailTemplate !== null) {
      let tmpErrors = { ...defaultErrors };
      
      if(emailTemplate.name === ''){
        tmpErrors.name = true;
      }

      if(emailTemplate.subject === ''){
        tmpErrors.subject = true;
      }

      setErrors(tmpErrors);
    }
  }, [emailTemplate, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    console.log(e, fieldName);
    let tmpTemplate = { 
      ...emailTemplate 
    }
    tmpTemplate[fieldName] = e.target.value;

    setEmailTemplate(tmpTemplate);
  }

  const contentUpdateHandler = (event, editor) => {
    const data = editor.getData();
    console.log(data);
    let tmpTemplate = { 
      ...emailTemplate 
    }
    tmpTemplate.content = data;
    setEmailTemplate(tmpTemplate);
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors(errors)){
      props.apiAdminSaveEmailTemplate(emailTemplate)
        .then(({ response }) => {
          props.notify('The email template ' + emailTemplate.name +  ' was successfully updated');
          setSaved(true);
        })
        .catch((error) => { });
    }
  }

  let redirect = null;
  if(saved) {
    redirect = <Redirect to="/admin/emails"/>
  }
  
  let form = null;

  if(emailTemplate) {
    form = <Form onSubmit={submitFormHandler}>
      {redirect}
      <Card className="mb-4">
        <Card.Header as="h5">Email details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formTitle">
            <Form.Label className="font-weight-bold">Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder=""
              readOnly={true}
              className={errors.name && submissionAttempted  ? 'is-invalid' : ''}
              value={emailTemplate.name}
              onChange={(e) => fieldUpdateHandler(e, 'name')}
            />
          </Form.Group>
          <Form.Group controlId="formSubject">
            <Form.Label className="font-weight-bold">Subject</Form.Label>
            <Form.Control
              name="subject"
              type="text"
              placeholder=""
              className={errors.subject && submissionAttempted  ? 'is-invalid' : ''}
              value={emailTemplate.subject}
              onChange={(e) => fieldUpdateHandler(e, 'subject')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="h5">Template Variables</Card.Header>
        <Card.Body>
          <ul>
            <li><strong>{"{{"}first_name{"}}"}</strong> - All templates</li>
            <li><strong>{"{{"}last_name{"}}"}</strong> - All templates</li>
            <li><strong>{"{{"}company_name{"}}"}</strong> - All templates</li>
            <li><strong>{"{{"}mark{"}}"}</strong> - Order / offer / enquiry templates</li>
            <li><strong>{"{{"}enquiry{"}}"}</strong> - Enquiry templates</li>
            <li><strong>{"{{"}profit{"}}"}</strong> - Order / offer templates</li>
            <li><strong>{"{{"}offer_amount{"}}"}</strong> - Offer templates</li>
            <li><strong>{"{{"}counter_offer_amount{"}}"}</strong> - Counter-offer template</li>
            <li><strong>{"{{"}invoice_no{"}}"}</strong> - Order templates</li>
            <li><strong>{"{{"}invoice_total{"}}"}</strong> - Order templates</li>
            <li><strong>{"{{"}status{"}}"}</strong> - Order templates</li>
            <li><strong>{"{{"}payment_status{"}}"}</strong> - Order templates</li>
            <li><strong>{"{{"}custom_message{"}}"}</strong> - All templates (optional)</li>
          </ul>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="h5">Content</Card.Header>
        <Card.Body>
          <CKEditor
            editor={ ClassicEditor }
            data={emailTemplate.content}
            onChange={ ( event, editor ) => contentUpdateHandler(event, editor) }
        />
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
      <Link to="/admin/emails" className="btn btn-secondary">Cancel</Link>
    </Form>
  }
  return (
    form
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadEmailTemplate: (rid) => dispatch(apiAdminLoadEmailTemplate(rid)),
    apiAdminSaveEmailTemplate: (r) => dispatch(apiAdminSaveEmailTemplate(r)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(null, mapDispatchToProps)(EmailForm));
