import React, { useState, useEffect, useRef } from 'react';
import { Form, Card } from 'react-bootstrap';
import StatusTermsConfigurator from './StatusTermsConfigurator';
import StatusTransferFeeConfigurator from './StatusTransferFeeConfigurator';

const StatusTypeConfigurator = (props) => {
  const [state, setState] = useState(props.defaults);
  const refs = {
    enabled: useRef(null)
  }
  useEffect(() => {
    props.onUpdate(state);
  }, [state]);


  const enabledUpdateHandler = (e) => {
    setState({...state, enabled: refs.enabled.current.checked})
  }

  const statusTermsChangeHandler = (v) => {
    setState({...state, status_notes_ids: v})
  }

  const statusTransferFeeChangeHandler = (v) => {
    setState({...state, transfer_fee_id: v})
  }

  const requiresNomineeChangeHandler = (e) => {
    setState({...state, requires_nominee: e.target.checked})
  }

  let formItem = null;

  if(state.enabled) {
    formItem = <Card.Body>
      <StatusTermsConfigurator key={'stc'} defaults={
        {
          status_notes_ids: state.status_notes_ids
        }
      }
      changeHandler={statusTermsChangeHandler}/>
      <StatusTransferFeeConfigurator key={'stfc'} defaults={
        {
          transfer_fee_id: state.transfer_fee_id
        }
      }
      changeHandler={statusTransferFeeChangeHandler}/>
      <h6 className="mb-3">Nominee</h6>
      <Form.Group controlId={'requiresNominee'} className="my-0 py-0">
        <Form.Check
          type="checkbox"
          label={'Required'}
          defaultChecked={state.requires_nominee}
          onChange={requiresNomineeChangeHandler}
        />
      </Form.Group>
    </Card.Body>
  }


  return (
    <Card className="mb-4">
      <Card.Header as="h5">
        <Form.Group className="my-0 py-0">
          <Form.Check
            name="enabled"
            ref={refs.enabled}
            type="checkbox"
            label={props.title}
            defaultChecked={state.enabled}
            onChange={enabledUpdateHandler}
          />
        </Form.Group>
      </Card.Header>
      {formItem}
    </Card>
  )
}

export default StatusTypeConfigurator;
