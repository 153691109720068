import React  from "react";
import { CircleFill } from 'react-bootstrap-icons';

const CompanyStatusBadge = (props) => {
  let variant = 'warning';
  switch(props.status){
    case 'Enabled':
      variant = 'success';
      break;
    case 'Disabled':
      variant = 'danger';
      break;
    default:
      break;
  }
  
  return (
    <small>
      <CircleFill className={'text-' + variant}/><span className="text-muted pl-2">{props.status}</span>
    </small>
  )
}

export default CompanyStatusBadge;
