import React, { useState, useEffect }  from "react";
import { Form, Button, Card } from 'react-bootstrap';
import  { Link } from 'react-router-dom';
import { apiAdminLoadPage } from '../../../store/actions/actions';
import { apiAdminSavePage } from '../../../store/actions/actions';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import validator from "validator";
import { numberAsString, anyErrors } from '../../../utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const slugify = require('slugify');

const PageForm = (props) => {
  const defaultErrors = {
    title: false,
    slug: false,
    group: false,
    position: false,
    content: false,
  }

  const defaultPage = {
    id: null,
    title: '',
    slug: '',
    group: 1,
    position: 0,
    content: ''
  }

  const [page, setPage] = useState(null);
  const [errors, setErrors] = useState({...defaultErrors})
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  
  useEffect(() => {
    if(props.pageId === null) {
      setPage({ ...defaultPage });
    } else {
      props.apiAdminLoadPage(props.pageId)
        .then(({ response }) => {
          setPage(response.data.page);
        })
        .catch((error) => { });
    }
  }, [props.pageId]);

  useEffect(() => {
    if(page !== null) {
      let tmpErrors = { ...defaultErrors };
      
      if(page.title === ''){
        tmpErrors.title = true;
      }
      if(page.slug === ''){
        tmpErrors.slug = true;
      }
      if(page.group === ''){
        tmpErrors.group = true;
      }
      if(page.position === '' || !validator.isNumeric(numberAsString(page.position))){
        tmpErrors.position = true;
      }
      setErrors(tmpErrors);
    }
  }, [page, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    console.log(e, fieldName);
    let tmpPage = { 
      ...page 
    }
    tmpPage[fieldName] = e.target.value;
    if(fieldName === 'title') {
      tmpPage.slug = slugify(e.target.value).toLowerCase();
    }
    setPage(tmpPage);
  }

  const groupUpdateHandler = (e) => {
    let tmpPage = { 
      ...page 
    }
    tmpPage.group = e.target.value;
    setPage(tmpPage);
  }

  const contentUpdateHandler = (event, editor) => {
    const data = editor.getData();
    console.log(data);
    let tmpPage = { 
      ...page 
    }
    tmpPage.content = data;
    setPage(tmpPage);
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors(errors)){
      props.apiAdminSavePage(page)
        .then(({ response }) => {
          props.notify('The page ' + page.title +  ' was successfully saved');
          setSaved(true);
        })
        .catch((error) => { });
    }
  }

  let redirect = null;
  if(saved) {
    redirect = <Redirect to="/admin/pages"/>
  }
  
  let form = null;

  if(page) {


    form = <Form onSubmit={submitFormHandler}>
      {redirect}
      <Card className="mb-4">
        <Card.Header as="h5">Page details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formTitle">
            <Form.Label className="font-weight-bold">Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              placeholder=""
              className={errors.title && submissionAttempted  ? 'is-invalid' : ''}
              value={page.title}
              onChange={(e) => fieldUpdateHandler(e, 'title')}
            />
          </Form.Group>
          <Form.Group controlId="formSlug">
            <Form.Label className="font-weight-bold">Slug</Form.Label>
            <Form.Control
              name="slug"
              type="text"
              placeholder=""
              readOnly={true}
              className={errors.slug && submissionAttempted  ? 'is-invalid' : ''}
              value={page.slug}
            />
          </Form.Group>
          <Form.Group controlId="formSlug">
            <Form.Label className="font-weight-bold">Group</Form.Label>
            <Form.Control
              name="group"
              as="select"
              className={errors.group && submissionAttempted  ? 'is-invalid' : ''}
              value={page.group}
              onChange={groupUpdateHandler}
            >
              <option value="1">Information</option>
              <option value="2">Dealer Services</option>
              <option value="3">Footer</option>
              <option value="4">Company</option>
              <option value="5">Order Form</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formPosition">
            <Form.Label className="font-weight-bold">Position</Form.Label>
            <Form.Control
              name="position"
              type="text"
              placeholder=""
              className={errors.position && submissionAttempted  ? 'is-invalid' : ''}
              value={page.position}
              onChange={(e) => fieldUpdateHandler(e, 'position')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="h5">Content</Card.Header>
        <Card.Body>
          <CKEditor
            editor={ ClassicEditor }
            data={page.content}
            onChange={ ( event, editor ) => contentUpdateHandler(event, editor) }
        />
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
      <Link to="/admin/pages" className="btn btn-secondary">Cancel</Link>
    </Form>
  }
  return (
    form
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadPage: (rid) => dispatch(apiAdminLoadPage(rid)),
    apiAdminSavePage: (r) => dispatch(apiAdminSavePage(r)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PageForm));
