import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { apiAdminLoadCompaniesList } from './../store/actions/actions';

const CompanyIdDropdown = (props) => {
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    props.apiAdminLoadCompaniesList()
      .then(({ response }) => {
        setCompanyList(
          [{id: '', name: ''}].concat(response.data.companies)
        );
      })
      .catch((error) => {
        console.log("Error retrieving companies");
      });
  }, []);

  const updateCompanyIdHandler = (e) => {
    props.onUpdate(e.target.value);
  }

  let companyListItems = [];
  if(companyList.length > 0) {
    companyListItems = companyList.map((c, index) => {
      return <option key={index} value={c.id.toString()}>{c.name}</option>;
    });
  }

  return (
    <Form.Group controlId="formCompanyId" className="">
      <Form.Label className="font-weight-bold">{props.label}</Form.Label>
      <Form.Control
        as="select"
        name="name"
        value={props.companyId}
        onChange={updateCompanyIdHandler}
        className={props.error ? 'is-invalid' : ''}
      >
        {companyListItems}
        </Form.Control>
    </Form.Group>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadCompaniesList: () => dispatch(apiAdminLoadCompaniesList()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CompanyIdDropdown));
