import React from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../../../components/Layouts/AdminLayout';
import StatusList from '../../../../components/Admin/Statuses/StatusList'

const Statuses = (props) => {
  return (
    <AdminLayout>
      <div className="container">
        
        <div className="row my-4 d-flex align-items-center">
          <div className="col">
            <h1 className="">Statuses</h1>
          </div>
          <div className="col text-right">
            <Link to="/admin/settings/statuses/add" className="btn btn-sm btn-dark">+ Add Status</Link>
          </div>
        </div>
        <p className=""><strong>Manage your list of available statuses.</strong></p>
        <StatusList/>
      </div>
    </AdminLayout>
  )
}

export default Statuses;
