import React from "react";
import { INVOICE_TERMS } from '../../constants';
import PlainNumberPlate from '../PlainNumberPlate';

const OrderFormIntro = (props) => {
  let copy = '';

  if(!props.error){
    // Copy for when page registration is loaded.
    copy =  <div>
              <h1 class="my-4">Order Form</h1>
              <p>You are about to place an order for the registration <strong><PlainNumberPlate mark={props.mark}/></strong>. <u>Please read the following information carefully.</u></p>
              <ul className="mb-4">
                <li>Check the registration details below.</li>
                <li>Choose the type of transfer you require.</li>
                <li>Complete the nominee name (if applicable).</li>
                <li>Click <strong>'Confirm Purchase'</strong> to place your order.</li>
                <li>You'll have {INVOICE_TERMS} days to pay the full balance after which the order will be cancelled and the registration returned to sale.</li>
                <li>Cancelled orders will be subject to a £100 + VAT restocking fee.</li>
              </ul>
            </div>;
  } else {
    // Copy for when an error occurred.
    copy =  <div>
              <h1 class="my-4">Not Found</h1>
            </div>;
  }
  return (
    copy
  );
};

export default OrderFormIntro;

