import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { apiAdminLoadOffers } from '../../../store/actions/actions'
import Gbp from '../../utils/Gbp'
import OfferStatusBadge from './OfferStatusBadge';
import AcceptOfferModal from './AcceptOfferModal';
import CounterOfferModal from './CounterOfferModal';
import DeclineOfferModal from './DeclineOfferModal';
import DeleteOfferModal from './DeleteOfferModal';
import PaginationDetail from '../../PaginationDetail';
import NumberPlate from '../../NumberPlate';
import Dropdown from 'react-bootstrap/Dropdown'

const OfferList = (props) => {
  const [offers, setOffers] = useState([]);
  const [acceptModal, setAcceptModal] = useState(null);
  const [counterModal, setCounterModal] = useState(null);
  const [declineModal, setDeclineModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    props.apiAdminLoadOffers(props.filters)
      .then(({ response }) => {
        setOffers(response.data.offers);
        setMeta(response.data.meta);
      })
      .catch((error) => {
        console.log("Error retrieving offers");
      });
  }, [props.filters, props.page, refreshCounter]);

 

  const previousPageHandler = (e) => {
    console.log('previous');
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
    }
  }

  const nextPageHandler = (e) => {
    console.log('next');
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
    }
  }

  const acceptClickHandler = (e, index) => {
    setAcceptModal(offers[index]);
  }

  const acceptOfferCloseHandler = (e) => {
    setAcceptModal(null);
  }

  const acceptOfferAcceptedHandler = (e) => {
    setAcceptModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const declineClickHandler = (e, index) => {
    setDeclineModal(offers[index]);
  }

  const declineOfferCloseHandler = (e) => {
    setDeclineModal(null);
  }

  const declineOfferAcceptedHandler = (e) => {
    setDeclineModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const deleteClickHandler = (e, index) => {
    setDeleteModal(offers[index]);
  }

  const deleteOfferCloseHandler = (e) => {
    setDeleteModal(null);
  }

  const deleteOfferAcceptedHandler = (e) => {
    setDeleteModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const counterClickHandler = (e, index) => {
    setCounterModal(offers[index]);
  }

  const counterOfferCloseHandler = (e) => {
    setCounterModal(null);
  }

  const counterOfferAcceptedHandler = (e) => {
    setCounterModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  

  let offerItems = [];
  if( offers && offers.length > 0 ) {
    offerItems = offers.map((o, index) => {
      let buttons = null;
      if(o.status === 'Pending'){
        buttons = <React.Fragment>
          <button className="dropdown-item" onClick={(e) => acceptClickHandler(e, index)}>Accept</button>
          <button className="dropdown-item" onClick={(e) => counterClickHandler(e, index)}>Counter</button>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => declineClickHandler(e, index)}>Decline</button>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => deleteClickHandler(e, index)}>Delete</button>
          </React.Fragment>
      }
      if(o.status === 'Accepted'){
        buttons = <React.Fragment>
          <button className="dropdown-item text-danger" onClick={(e) => declineClickHandler(e, index)}>Decline</button>
          <Dropdown.Divider></Dropdown.Divider>
          <button className="dropdown-item text-danger" onClick={(e) => deleteClickHandler(e, index)}>Delete</button>
          </React.Fragment>
      }
      return <tr>
        <td><NumberPlate mark={o.registration}/></td>
        <td>{o.portfolio}</td>
        <td className="d-none d-md-table-cell">{o.offer_date}</td>
        <td>{o.company}</td>
        <td className="d-none d-md-table-cell"><Gbp amount={o.price}/></td>
        <td className="d-none d-md-table-cell"><Gbp amount={o.cost_price}/></td>
        <td><Gbp amount={o.offer_price}/></td>
        <td><Gbp amount={o.countered_price}/></td>
        <td>{o.comments}</td>
        <td className={o.profit > 0 ? 'd-none d-md-table-cell text-success' : ' d-none d-md-table-cell text-danger'}><Gbp amount={o.profit} /></td>

        <td className="text-nowrap"><OfferStatusBadge status={o.status}/></td>
        <td className="min">
          <div className="btn-group">
            <button disabled={o.status === 'Countered' || o.status === 'Declined' || o.status === 'Expired'} type="button" className="btn btn-link text-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            &hellip;
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="drop2">
              {buttons}
            </div>
          </div>
        </td>
      </tr>
    });
  }

  let acceptModalItem = null;
  if(acceptModal !== null) {
    acceptModalItem = <AcceptOfferModal offer={acceptModal} onClose={acceptOfferCloseHandler} onAccept={acceptOfferAcceptedHandler}/>;
  }

  let declineModalItem = null;
  if(declineModal !== null) {
    declineModalItem = <DeclineOfferModal offer={declineModal} onClose={declineOfferCloseHandler} onDecline={declineOfferAcceptedHandler}/>;
  }

  let deleteModalItem = null;
  if(deleteModal !== null) {
    deleteModalItem = <DeleteOfferModal offer={deleteModal} onClose={deleteOfferCloseHandler} onDelete={deleteOfferAcceptedHandler}/>;
  }

  let counterModalItem = null;
  if(counterModal !== null) {
    counterModalItem = <CounterOfferModal offer={counterModal} onClose={counterOfferCloseHandler} onCounterOffer={counterOfferAcceptedHandler}/>;
  }

  return (
    <div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Registration</th>
            <th scope="col">Portfolio</th>
            <th scope="col" className="d-none d-md-table-cell">Date</th>
            <th scope="col">Company</th>
            <th scope="col" className="d-none d-md-table-cell">List Price</th>
            <th scope="col" className="d-none d-md-table-cell">Cost Price</th>
            <th scope="col">Offer Price</th>
            <th scope="col">Countered Price</th>
            <th scope="col">Comments</th>
            <th scope="col" className="d-none d-md-table-cell">Profit</th>
            <th scope="col">Status</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {offerItems}
        </tbody>
      </table>
      <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} />
      {acceptModalItem}
      {counterModalItem}
      {declineModalItem}
      {deleteModalItem}
    </div>
    )
}

const mapStateToProps = state => {
  return {
    filters: state.offerFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadOffers: (f) => dispatch(apiAdminLoadOffers(f)),
    updateMainState: (f) => dispatch({ type: 'SET_OFFER_FILTERS', payload: f })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferList);
