import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/User/Footer/Footer";
import MainNav from "../../components/User/MainNav/MainNav";

const Home = (props) => {
  let homepage = null;
  for (let i = 0; i < props.pages.company.length; i++) {
    if (props.pages.company[i].slug === "welcome-to-t12ade") {
      homepage = props.pages.company[i];
    }
  }
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <div className="container py-4">
            <h1 className="mb-4 pb-4 border-bottom">{homepage.title}</h1>
            <div className="row">
              <div className="col-md-6 page-content">
                <div
                  dangerouslySetInnerHTML={{ __html: homepage.content }}
                ></div>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="border rounded p-4 bg-light">
                  <h5>Already a member?</h5>
                  <p>Access your account using the button below.</p>
                  <Link to="/login" className="btn btn-dark">
                    Member login
                  </Link>
                </div>
                <div className="border rounded p-4 mt-4 bg-light">
                  <h5>Want to open an account?</h5>
                  <p>Begin your T12ADE application here.</p>
                  <Link to="/apply" className="btn btn-dark">
                    Apply now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pages: state.pages.pageList,
  };
};

export default connect(mapStateToProps, null)(Home);
