import React  from "react";
import validation from 'uk-numberplate-format';

const NumberPlate = (props) => {
  let mark = null;
  validation.validate(props.mark, (err,data) => {
    mark = data.plate;
  });
  
  return (
    <h3><span className="lead border badge badge-warning numberplate">{mark}</span></h3>
  )
}

export default NumberPlate;
