import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiAdminSaveNote, apiAdminDeleteNote } from "../../store/actions/actions";
import { PencilSquare, CheckSquareFill, XSquare } from 'react-bootstrap-icons';
import { Row, Col } from 'react-bootstrap';

const StatusNoteItem = (props) => {
  const [editor, setEditor] = useState(props.statusNote.id == null);
  const [note, setNote] = useState(props.statusNote.note);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    note: false,
    save: false
  })

  const enableEditorHandler = (e) => {
    setEditor(!editor);
  }

  const changeHandler = (e) => {
    setNote(e.target.value);
    setErrors({
      ...errors,
      save: false
    })
  }

  const validate = () => {
    let tmpErrors = {
      note: false
    }

    if(!note || note === '') {
      tmpErrors.note = true;
    }
    setErrors(tmpErrors);
  }

  const anyErrors = () => {
    return errors.note || errors.save;
  }

  useEffect(() => {
    validate();
  }, [note]);

  const deleteHandler = (e) => {
    if(!props.statusNote.in_use){
      if ( props.statusNote.id === null ) {
        props.deleteHandler(props.index);
        return;
      }
      props.apiAdminDeleteNote(props.statusNote.id)
        .then(({ response }) => {
          props.deleteHandler(props.index);
        })
        .catch((error) => {
          console.log("Error deleteing status note");
        });
    }
  }

  const saveHandler = (e) => {
    setSaved(true);
    if (!anyErrors()){
      props.apiAdminSaveNote({ id: props.statusNote.id, note: note })
        .then(({ response }) => {
          props.updateHandler(props.index, response.data.status_note);
          setEditor(!editor);
        })
        .catch((error) => {
          setErrors({note: true, save: true})
        });
    }
  }

  let noteClass = 'form-control';
  if( saved && anyErrors() ) {
    noteClass = 'form-control is-invalid';
  }

  let content = <span>{note}</span>;
  if (editor) {
    content = <input 
      type="text"
      className={noteClass}
      placeholder="Enter note..."
      value={note}
      onChange={changeHandler}/>;
  }

  let icon = <PencilSquare className="cursor-pointer" onClick={enableEditorHandler} />;
  if( editor ) {
    icon = <CheckSquareFill className="cursor-pointer" onClick={saveHandler} />;
  }

  let deleteItem = <XSquare className="ml-2 text-muted"/>;;
  if(!props.statusNote.in_use){
    deleteItem= <XSquare className="ml-2 text-danger cursor-pointer" onClick={deleteHandler}/>;
  }

  return (
    <li className="list-group-item" key={props.index}>
      <Row>
        <Col xs={10}>{content}</Col>
        <Col xs={2} className="text-right">
          {icon}
          {deleteItem}
        </Col>
      </Row>
    </li>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    apiAdminSaveNote: (sn) => dispatch(apiAdminSaveNote(sn)),
    apiAdminDeleteNote: (id) => dispatch(apiAdminDeleteNote(id)),
  };
};

export default connect(null, mapDispatchToProps)(StatusNoteItem);
