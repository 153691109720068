const initAuth = () => {
  return {
    filters: {
      registration: '',
      company: '',
      statuses: [0], 
      page: 1
    }
  };
};

function offerFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_OFFER_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    default:
      return state;
  }
}

export default offerFilters;
