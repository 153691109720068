import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import DealerRegistrationList from "../../../components/Admin/DealerRegistrations/DealerRegistrationList";
import DealerRegistrationFilters from "../../../components/Admin/DealerRegistrations/DealerRegistrationFilters";
import { Button } from 'react-bootstrap';

const DealerRegistrations = (props) => {
 

  const [refreshCounter, setRefreshCounter] = useState(0);

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row my-4 d-flex align-items-center">
          <div className="col col-md-4">
            <h1 className="">Dealer Registrations</h1>
          </div>
          <div className="col-md-8 text-md-right">
           
          </div>
        </div>
        <div className="">
          <div className="container-full">
            <div className="row">
              <div className="col-md-3 mb-4">
                <DealerRegistrationFilters/>
              </div>
              <div className="col-md-9">
                <DealerRegistrationList counter={refreshCounter}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

const mapStateToProps = state => {
  return {
    filters: state.registrationFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (msg, data) => dispatch({ type: 'NOTIFY', msg: msg, data: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerRegistrations);
