import React from 'react';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import PageForm from '../../../components/Admin/Pages/PageForm';

const EditPage = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Edit Page</h1>
          <PageForm pageId={props.match.params.id}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(EditPage);
