import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ResponseBar from "../../components/ResponseBar";
import { apiSaveSubscriberPassword } from "../../store/actions/actions";
import { withRouter, Redirect } from "react-router-dom";
import { Form, Button, Card } from 'react-bootstrap';
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';
import validator from "validator";

const UpdatePassword = (props) => {
  const [user, setUser] = useState(null);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    password: false,
    password_confirmation: false,
  })

  useEffect(() => {
    setUser({
      password: '',
      password_confirmation: ''
    });
  }, []);

  useEffect(() => {
    if(user !== null) {
      let tmpErrors = {
        password: false,
        password_confirmation: false
      }
      if(validator.isEmpty(user.password)){
        tmpErrors.password = true;
      }
      if(validator.isEmpty(user.password_confirmation)){
        tmpErrors.password_confirmation = true;
      }
      if(user.password != user.password_confirmation){
        tmpErrors.password = true;
        tmpErrors.password_confirmation = true;
      }
      setErrors(tmpErrors);
    }
  }, [user, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    let tmpUser = {
      ...user
    }

    tmpUser[fieldName] = e.target.value;
    setUser(tmpUser);
  }

  const anyErrors = () => {
    for (const [key, value] of Object.entries(errors)) {
      if (value) {
        return true;
      }
    }
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors()){
      props.apiSaveSubscriberPassword(user)
        .then(({ response }) => {
          setSaved(true);
        })
        .catch((error) => {
          
        });
    }
  }


  const refreshForm = () => {
    setUser({
      password: '',
      password_confirmation: ''
    });
    setSaved(false);
    setSubmissionAttempted(false);
  }

  let form = null;
  if(user) {
    form = <Form onSubmit={submitFormHandler}>
      <Card>
        <Card.Header as="h5">User details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formPassword">
            <Form.Label className="font-weight-bold">Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder=""
              className={submissionAttempted && errors.password ? 'is-invalid' : ''}
              value={user.password}
              onChange={(e) => fieldUpdateHandler(e, 'password')}
            />
          </Form.Group>
          <Form.Group controlId="formPasswordConfirmation">
            <Form.Label className="font-weight-bold">Password Confirmation</Form.Label>
            <Form.Control
              name="password_confirmation"
              type="password"
              placeholder=""
              className={submissionAttempted && errors.password_confirmation ? 'is-invalid' : ''}
              value={user.password_confirmation}
              onChange={(e) => fieldUpdateHandler(e, 'password_confirmation')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
    </Form>
  }

  let content = form;
  if(saved) {
    content = <div>
      <p>Your changes were successfully saved.</p>
      <button className="btn btn-dark" onClick={refreshForm}>
        Make more changes
      </button>
    </div>;
  }
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <div className="container py-4">
            <h1 className="mb-4 pb-4 border-bottom">Update Password</h1>
            {content}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiSaveSubscriberPassword: (data) => dispatch(apiSaveSubscriberPassword(data))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UpdatePassword));