import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { apiAdminLoadTransferFees } from '../../../store/actions/actions';
import Gbp from '../../utils/Gbp';

const uniqid = require('uniqid');

const StatusTransferFeeConfigurator = (props) => {
  const [transferFees, setTransferFees] = useState(null);
  const radioGroupName = uniqid();
  useEffect(() => {
    props.apiAdminLoadTransferFees()
      .then(({ response }) => {
        setTransferFees(response.data.transfer_fees);
      })
      .catch((error) => {
        console.log("Error retrieving transfer fees");
      });
  }, []);

  const changeHandler = (e) => {
    props.changeHandler(parseInt(e.target.value));
  }


  let formItem = null;

  if(transferFees) {
    let formItemItems = transferFees.map((t, index) => {
      return(
        <li className="">
          <Form.Group controlId={radioGroupName + index} className="my-0 py-0">
            <Form.Check
              name={radioGroupName}
              type="radio"
              value={t.id}
              label={<Gbp amount={t.fee}/>}
              defaultChecked={props.defaults.transfer_fee_id === t.id}
              onChange={changeHandler}
            />
          </Form.Group>
        </li>
      )
    });
    formItem = <ul className="list-unstyled">
      {formItemItems}
    </ul>
  }


  return (
    <React.Fragment>
      <h6 className="mb-3">Transfer Fee</h6>
      {formItem}
    </React.Fragment>
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = dispatch => {
  return {
    apiAdminLoadTransferFees: () => dispatch(apiAdminLoadTransferFees()),
  };
};

export default connect(null, mapDispatchToProps)(StatusTransferFeeConfigurator);

