import React, { useState, useEffect }  from "react";
import { Form, Button, Card } from 'react-bootstrap';
import  { Link } from 'react-router-dom';
import { apiAdminLoadCompany } from '../../../store/actions/actions';
import { apiAdminSaveCompany } from '../../../store/actions/actions';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import validator from "validator";
import countryList from 'react-select-country-list'
import anyErrors from '../../../utils/anyErrors';

const CompanyForm = (props) => {
  const [company, setCompany] = useState(null);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    cc: false,
    inflate_prices: false,
    website: false,
    address_1: false,
    address_2: false,
    city: false,
    county: false,
    country: false,
    post_code: false
  })
  
  useEffect(() => {
    if(props.companyId === null) {
      setCompany({
        id: null,
        name: '',
        cc: '',
        inflate_prices: 0.00,
        website: '',
        address_1: '',
        address_2: '',
        city: '',
        county: '',
        country: 'United Kingdom',
        post_code: ''
      });
    } else {
      props.apiAdminLoadCompany(props.companyId)
        .then(({ response }) => {
          setCompany(response.data.company);
        })
        .catch((error) => {
          console.log("Error retrieving company");
        });
    }
  }, [props.companyId]);

  useEffect(() => {
    if(company !== null) {
      let tmpErrors = {
        name: false,
        cc: false,
        inflate_prices: false,
        website: false,
        address_1: false,
        address_2: false,
        city: false,
        county: false,
        country: false,
        post_code: false
      }
      if(!validator.isEmpty(company.cc)){
        let emails = company.cc.split(',');
        for (let i = 0; i < emails.length; i++) {
          if(!validator.isEmail(emails[i])){
            tmpErrors.cc = true;
          }
        };
      }
      if(validator.isEmpty(company.name)){
        tmpErrors.name = true;
      }
      if(!validator.isEmpty(company.website) && !validator.isURL(company.website)){
        tmpErrors.website = true;
      }
      if(validator.isEmpty(company.address_1)){
        tmpErrors.address_1 = true;
      }
      if(validator.isEmpty(company.city)){
        tmpErrors.city = true;
      }
      if(validator.isEmpty(company.county)){
        tmpErrors.county = true;
      }
      if(validator.isEmpty(company.country)){
        tmpErrors.country = true;
      }
      if(validator.isEmpty(company.post_code)){
        tmpErrors.post_code = true;
      }
      console.log(tmpErrors);
      setErrors(tmpErrors);
    }
  }, [company]);

  const fieldUpdateHandler = (e, fieldName) => {
    console.log(e, fieldName);
    let tmpCompany = {
      ...company
    }

    tmpCompany[fieldName] = e.target.value;
    setCompany(tmpCompany);
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors(errors)){
      props.apiAdminSaveCompany(company)
        .then(({ response }) => {
          props.notify('The company ' + company.name +  ' was successfully saved');
          setSaved(true);
        })
        .catch((error) => {
          console.log("Error saving company");
        });
    }
  }
  let redirect = null;
  if(saved) {
    redirect = <Redirect to="/admin/companies"/>
  }
  let form = null;
  if(company) {
    let countryOptions = countryList().getData().map(c => {
      return(
        <option value={c.label}>{c.label}</option>
      )
    });
    form = <Form onSubmit={submitFormHandler}>
      {redirect}
      <Card>
        <Card.Header as="h5">Company details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder="Name"
              className={submissionAttempted && errors.name ? 'is-invalid' : ''}
              value={company.name}
              onChange={(e) => fieldUpdateHandler(e, 'name')}
            />
          </Form.Group>
          <Form.Group controlId="formInflatePrices">
            <Form.Label className="font-weight-bold">Inflate Prices</Form.Label>
            <Form.Control
              name="inflate_prices"
              type="text"
              placeholder="0.00"
              className={submissionAttempted && errors.inflate_prices ? 'is-invalid' : ''}
              value={company.inflate_prices}
              onChange={(e) => fieldUpdateHandler(e, 'inflate_prices')}
            />
          </Form.Group>
          <Form.Group controlId="formCC">
            <Form.Label className="font-weight-bold">CC's (Comma-Separated Emails)</Form.Label>
            <Form.Control
              name="cc"
              type="text"
              placeholder=""
              className={submissionAttempted && errors.cc ? 'is-invalid' : ''}
              value={company.cc}
              onChange={(e) => fieldUpdateHandler(e, 'cc')}
            />
          </Form.Group>
          <Form.Group controlId="formWebsite">
            <Form.Label className="font-weight-bold">Website</Form.Label>
            <Form.Control
              name="website"
              type="text"
              placeholder="https://"
              className={submissionAttempted && errors.website ? 'is-invalid' : ''}
              value={company.website}
              onChange={(e) => fieldUpdateHandler(e, 'website')}
            />
          </Form.Group>

          <Form.Group controlId="formAddress">
            <Form.Label className="font-weight-bold">Address</Form.Label>
            <Form.Control
              name="address_1"
              type="text"
              placeholder="Street"
              className={submissionAttempted && errors.address_1 ? 'is-invalid' : ''}
              value={company.address_1}
              onChange={(e) => fieldUpdateHandler(e, 'address_1')}
            />
          </Form.Group>
          <Form.Group controlId="formAddress2">
            <Form.Control
              name="address_2"
              type="text"
              placeholder="Street 2"
              className={submissionAttempted && errors.address_2 ? 'is-invalid' : ''}
              value={company.address_2}
              onChange={(e) => fieldUpdateHandler(e, 'address_2')}
            />
          </Form.Group>
          <Form.Group controlId="formAddress3">
            <Form.Control
              name="city"
              type="text"
              placeholder="City"
              className={submissionAttempted && errors.city ? 'is-invalid' : ''}
              value={company.city}
              onChange={(e) => fieldUpdateHandler(e, 'city')}
            />
          </Form.Group>
          <Form.Group controlId="formAddress4">
            <Form.Control
              name="county"
              type="text"
              placeholder="County"
              className={submissionAttempted && errors.county ? 'is-invalid' : ''}
              value={company.county}
              onChange={(e) => fieldUpdateHandler(e, 'county')}
            />
          </Form.Group>
          <Form.Group controlId="formAddress5">
            <Form.Control
              name="country"
              as="select"
              className={submissionAttempted && errors.country ? 'is-invalid' : ''}
              defaultValue={company.country}
              onChange={(e) => fieldUpdateHandler(e, 'country')}
            >
              {countryOptions}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formAddress6">
            <Form.Control
              name="post_code"
              type="text"
              placeholder="Postcode"
              className={submissionAttempted && errors.post_code ? 'is-invalid' : ''}
              value={company.post_code}
              onChange={(e) => fieldUpdateHandler(e, 'post_code')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
      <Link to="/admin/companies" className="btn btn-secondary">Cancel</Link>
    </Form>
  }
  return (
    form
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadCompany: (cid) => dispatch(apiAdminLoadCompany(cid)),
    apiAdminSaveCompany: (c) => dispatch(apiAdminSaveCompany(c)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CompanyForm));
