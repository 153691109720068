import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import { apiAdminLoadEmailTemplates } from "../../../store/actions/actions";

const Pages = (props) => {
  const [emailTemplateResults, setEmailTemplateResults] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    props.apiAdminLoadEmailTemplates()
      .then(({ response }) => {
        setEmailTemplateResults(response.data.templates);
      })
      .catch((error) => { })
  },[props.filters, refreshCounter]);

  let templateItems = null;
  if(emailTemplateResults) {
    templateItems = emailTemplateResults.map((t, index) => {
      return (
        <li className="list-group-item">
          <strong>{t.name}</strong>
          <Link to={"/admin/emails/" + t.id} className="float-right text-dark mr-4">Edit</Link>
        </li>
      )
    })
  }
  return (
    <AdminLayout>
      <div className="container">
        <div className="row my-4 d-flex align-items-center">
          <div className="col">
            <h1 className="">Emails</h1>
          </div>
          <div className="col text-right">
          </div>
        </div>
        <div className="">       
          <ul className="list-group">
            {templateItems}
          </ul>
        </div>
      </div>
    </AdminLayout>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadEmailTemplates: (f) => dispatch(apiAdminLoadEmailTemplates(f)),
  };
};

export default connect(null, mapDispatchToProps)(Pages);