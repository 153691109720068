import React from 'react';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../../components/Layouts/AdminLayout';
import StatusForm from '../../../../components/Admin/Statuses/StatusForm';

const EditPage = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Edit Status</h1>
          <StatusForm statusId={props.match.params.id}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(EditPage);
