import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { apiAdminLoadRegistrationsMeta } from './../store/actions/actions';

const StatusIdDropdown = (props) => {
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    props.apiAdminLoadRegistrationsMeta()
      .then(({ response }) => {
        setStatusList(
          [{id: '', name: ''}].concat(response.data.statuses)
        );
      })
      .catch((error) => {
        console.log("Error retrieving statuses");
      });
  }, []);

  const updateStatusIdHandler = (e) => {
    if(e.target.value === ''){
      props.onUpdate(null);
    } else {
      props.onUpdate(Number(e.target.value));
    }
  }

  let statusListItems = [];
  if(statusList.length > 0) {
    statusListItems = statusList.map((s, index) => {
      return <option key={index} value={s.id}>{s.name}</option>;
    });
  }

  return (
    <Form.Group controlId="formStatusId" className="">
      <Form.Label className="font-weight-bold">{props.label}</Form.Label>
      <Form.Control
        as="select"
        name="status_id"
        value={props.statusId}
        onChange={updateStatusIdHandler}
        className={props.error ? 'is-invalid' : ''}
      >
        {statusListItems}
        </Form.Control>
    </Form.Group>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadRegistrationsMeta: () => dispatch(apiAdminLoadRegistrationsMeta()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(StatusIdDropdown));
