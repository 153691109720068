import axios from 'axios';
import store from './store.js'

axios.defaults.headers.common = {
  "Content-Type": "application/json"
}
if(process.env.REACT_APP_API_SERVER) {
  axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;
} else {
 axios.defaults.baseURL = 'https://api.t12ade.co.uk';
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.data.response.status === 401) {
    store.dispatch( { type: 'LOG_OUT' } );
  }
  return Promise.reject(error);
});

export default axios;