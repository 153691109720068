import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import ResponseBar from "../../components/ResponseBar";
import { withRouter } from "react-router-dom";
import MoreInformationForm from "../../components/MoreInformationForm/MoreInformationForm"
import OfferForm from "../../components/OfferForm/OfferForm"
import ConditionalMessage from "../../components/ConditionalMessage/ConditionalMessage"
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';
import PlainNumberPlate from '../../components/PlainNumberPlate';

const MakeOffer = (props) => {
  const [submitState, setSubmitState] = useState({
    submitted: false,
    altContent: <div></div>
  });

  const submitHandler = (r) => {
    let tmpState = {
      ...submitState,
      submitted: true
    }
    if (r.data.offer.status === 'Pending') {
      tmpState.altContent = <div className="container">
        <h1 class="my-4">Offer Submitted</h1>
        <p>Thanks. We've received your offer for <strong><PlainNumberPlate mark={props.match.params.mark}/></strong>. We'll make a decision and notify you by email shortly.</p>
        <Link to="/registrations" className="btn btn-secondary">
          Back
        </Link>
      </div>

    } else if (r.data.offer.status === 'Accepted') {
      tmpState.altContent = <div className="container">
        <h1 class="my-4">Offer Accepted</h1>
        <p>Thanks. We're happy to accept your offer for <strong><PlainNumberPlate mark={props.match.params.mark}/></strong>. It is available to purchase at the reduced price immediately.</p>
        <Link to="/registrations" className="btn btn-secondary">
          Back
        </Link>
      </div>
    }
    setSubmitState(tmpState)
  }

  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <ConditionalMessage display={submitState.submitted} altContent={submitState.altContent}>
            <MoreInformationForm heading="Make Offer" mark={props.match.params.mark} collapsed={false} />
            <OfferForm mark={props.match.params.mark} onSubmit={submitHandler} />
          </ConditionalMessage>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default withRouter(connect(null, null)(MakeOffer));
