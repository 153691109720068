import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";

const Redirecter = (props) => {
  return (
    <Redirect to="/admin/orders"/>
  );
}

export default withRouter(connect(null, null)(Redirecter));
