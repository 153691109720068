import { combineReducers } from "redux";
import authentication from "./authentication";
import notifications from "./notifications";
import pages from "./pages";
import offerFilters from "./offerFilters";
import orderFilters from "./orderFilters";
import companyFilters from "./companyFilters";
import userFilters from "./userFilters";
import registrationFilters from "./registrationFilters";
import publicRegistrationFilters from "./publicRegistrationFilters";
import publicDownloadConfig from "./publicDownloadConfig";
import reportingFilters from "./reportingFilters";
import killSwitch from "./killSwitch";

export default combineReducers({
  authentication,
  notifications,
  pages,
  offerFilters,
  orderFilters,
  companyFilters,
  userFilters,
  registrationFilters,
  publicRegistrationFilters,
  reportingFilters,
  publicDownloadConfig,
  killSwitch,
});
