import React, { useState, useRef } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiAdminDeleteRegistration } from '../../../store/actions/actions';

const DeleteRegistrationModal = (props) => {
  const refs ={
    notify: useRef(null)
  }

  const closeHandler = (e) => {
    props.onClose();
  }

  const [message, setMessage] = useState('');
  const [notify, setNotify] = useState(false);

  const notifyChangeHandler = (e) => {
    setNotify(refs.notify.current.checked);
  }

  const updateMessageHandler = (e) => {
    setMessage(e.target.value);
  }

  const confirmHandler = (e) => {
    props.apiAdminDeleteRegistration(props.reg.id, notify, message)
      .then(({ response }) => {
        props.notify('Registration ' + props.reg.mark +  ' was successfully deleted');
        props.onConfirm();
      })
      .catch((error) => {
        props.notify('Error registration ' + props.reg.mark);
      });
  }

  let msg = null;
  if(notify) {
    msg = <Form.Group controlId="formMessage" className="my-4">
    <Form.Label className="font-weight-bold">Add an additional custom message</Form.Label>
    <Form.Control
      name="message"
      as="textarea"
      rows={3}
      placeholder=""
      value={message}
      onChange={updateMessageHandler}
    />
  </Form.Group>;
  }

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to delete the registration <strong>{props.reg.mark}</strong>. Please confirm by clicking below.</p>
        <Form.Group controlId="formOptedIn" className="mt-4 mb-1">
          <Form.Check
            name="notify"
            ref={refs.notify}
            type="checkbox"
            label="Notify subscribers?"
            defaultChecked={notify}
            onChange={notifyChangeHandler}
          />
        </Form.Group>
        {msg}
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={confirmHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminDeleteRegistration: (id, notify, msg) => dispatch(apiAdminDeleteRegistration(id, notify, msg)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(DeleteRegistrationModal);
