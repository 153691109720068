import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Notifications from "./../../Notifications";

const Footer = (props) => {
  const year = new Date().getFullYear();
  return (
    <footer className="px-2 py-5 border-top bg-dark">
      <div className="container-full">
        <div className="text-center text-white">
          <h5>T12ADE</h5>
          PO Box 13180, Gordon, TD11 9AF
        </div>
        <div className="mt-4 text-center">
          <ul className="list-inline text-small">
            {props.pages.footer.map((page, key) => (
              <li className="mx-2 list-inline-item">
                <Link
                  to={"/pages/" + page.slug}
                  className="text-white text-underline"
                >
                  {page.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 text-center text-muted">
          <small>
            Copyright &copy;{year} Cape Tech Ltd. All rights reserved.
          </small>
        </div>
      </div>
      <Notifications />
    </footer>
  );
};

// Add ability to get state and manipulate it.
const mapStateToProps = (state) => {
  return {
    pages: state.pages.pageList,
    pagesLoaded: state.pages.loaded,
  };
};

export default connect(mapStateToProps, null)(Footer);
