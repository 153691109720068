import React from 'react';
import AdminLayout from '../../../../components/Layouts/AdminLayout';
import StatusForm from '../../../../components/Admin/Statuses/StatusForm';

const AddPage = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Add Status</h1>
          <StatusForm statusId={null}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AddPage;
