import React  from "react";
import { CircleFill } from 'react-bootstrap-icons';

const UserCompanyName = (props) => {
  let variant = 'warning';
  switch(props.status){
    case 'Enabled':
      variant = 'success';
      break;
    case 'Disabled':
      variant = 'danger';
      break;
    default:
      break;
  }

  return (
    <small>
      <CircleFill className={'text-' + variant}/><span className="text-muted pl-2">{props.name}</span>
    </small>
  )
}

export default UserCompanyName;
