import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { routes } from "./routes/index.js";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { apiLoadPages } from "./store/actions/actions";
import AuthGuard from "./components/AuthGuard/AuthGuard";
import ScrollToTop from "./components/ScrollToTop";

class App extends Component {
  loadPages = () => {
    this.props
      .apiLoadPages()
      .then(({ response }) => {})
      .catch((error) => {
        console.log("Error: Failed to load pages");
      });
  };

  componentDidMount = () => {
    this.loadPages();
  };

  render() {
    let output = null;

    if (this.props.pagesLoaded) {
      output = (
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            {routes.map((route, key) => (
              <Route key={route.path} exact={!!route.exact} path={route.path}>
                <AuthGuard
                  requiresAuth={route.auth}
                  requiresAdmin={route.admin}
                  loggedIn={this.props.loggedIn}
                  redirect={route.redirect}
                  path={route.path}
                >
                  <route.component />
                  <Helmet>
                    <title>{route.headerTitle}</title>
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                      href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
                      rel="stylesheet"
                    ></link>
                  </Helmet>
                </AuthGuard>
              </Route>
            ))}
          </Switch>
        </BrowserRouter>
      );
    }
    return output;
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authentication.loggedIn,
    pages: state.pages.pageList,
    pagesLoaded: state.pages.loaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadPages: () => dispatch(apiLoadPages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
