import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Gbp from "../../utils/Gbp";
import NumberPlate from "../../NumberPlate";
import { apiAdminExpiries } from "../../../store/actions/actions";
import PaginationDetail from "../../PaginationDetail";
import { displayPrice, displayCostPrice } from "../../../utils";

const ExpiryList = (props) => {
  const [registrationResults, setRegistrationResults] = useState([]);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    props
      .apiAdminExpiries(page)
      .then(({ response }) => {
        setRegistrationResults(response.data.registrations);
        setMeta(response.data.meta);
      })
      .catch((error) => {});
  }, [page]);

  const previousPageHandler = (e) => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPageHandler = (e) => {
    if (meta && page < meta.total_pages) {
      setPage(page + 1);
    }
  };

  let registrationItems = null;
  if (registrationResults.length > 0) {
    registrationItems = registrationResults.map((r, index) => {
      return (
        <tr key={index}>
          <th scope="row">
            <NumberPlate mark={r.mark} />
          </th>
          <td className="">
            <Gbp amount={displayPrice(r)} />
          </td>
          <td className="">
            <Gbp amount={displayCostPrice(r)} />
          </td>
          <td className="">{r.status}</td>
          <td className="">{r.expiry_date}</td>
        </tr>
      );
    });
  }

  if (registrationResults.length > 0) {
    return (
      <React.Fragment>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Registration</th>
              <th scope="col" className="">
                Price
              </th>
              <th scope="col" className="">
                Cost Price
              </th>
              <th scope="col" className="">
                Status
              </th>
              <th scope="col" className="">
                Expiry Date
              </th>
            </tr>
          </thead>
          <tbody>{registrationItems}</tbody>
        </table>
        <PaginationDetail
          meta={meta}
          onPreviousClick={previousPageHandler}
          onNextClick={nextPageHandler}
        />
      </React.Fragment>
    );
  }
  return <div></div>;
};

const mapStateToProps = (state) => {
  return {
    filters: state.registrationFilters.filters,
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminExpiries: (f) => dispatch(apiAdminExpiries(f)),
    notify: (msg) => dispatch({ type: "NOTIFY", msg: msg }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExpiryList)
);
