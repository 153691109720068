import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';
import ResponseBar from "../../components/ResponseBar";
import { apiLoadInvoices, apiExportInvoice } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';
import Gbp from '../../components/utils/Gbp'
import InvoiceStatusBadge from '../../components/Admin/Orders/InvoiceStatusBadge';
import InvoicePaymentStatusBadge from '../../components/Admin/Orders/InvoicePaymentStatusBadge';
import InvoiceType from '../../components/Admin/Orders/InvoiceType';
import NumberPlate from '../../components/NumberPlate';

const Invoices = (props) => {
  // ***** Default state *****
  const [invoices, setInvoices] = useState(null);
  const [registration, setRegistration] = useState('');

  useEffect(() => {
    props.apiLoadInvoices(registration)
      .then(({ response }) => {
        setInvoices(response.data.invoices)
      })
      .catch((error) => {
        setInvoices(null);
      });
  }, [registration, props]);
  let invoiceItems = null;

  const updateRegistrationHandler = (e) => {
    setRegistration(e.target.value)
  }

  const downloadHandler = (e, index) => {
    props.apiExportInvoice(invoices[index].id)
    .then(({ response }) => {
      
    })
    .catch((error) => {

    });
  }

  if (invoices) {
    invoiceItems = invoices.map(function (i, index) {
      return (
        <tr key={index}>
          <th>{i.id.toString().padStart(6, '0')}</th>
          <td className="d-none d-lg-table-cell"><InvoiceType type={i.invoice_type}/></td>
          <td><NumberPlate mark={i.mark}/></td>
          <td className="d-none d-lg-table-cell">{i.transfer_type}<br/><small className="text-muted">{i.nominee_name ? i.nominee_name : ''}</small></td>
          <td>{i.order_date}</td>
          <td className="d-none d-lg-table-cell"><Gbp amount={i.total}/></td>
          <td className="d-none d-xl-table-cell"><InvoiceStatusBadge status={i.status}/></td>
          <td className="d-none d-xl-table-cell"><InvoicePaymentStatusBadge paymentStatus={i.payment_status}/></td>
          <td className="min">
            <button className="btn btn-sm btn-dark" onClick={(e) => downloadHandler(e, index)}>
              Download
            </button>
          </td>

        </tr>
      )
    });
  }
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <div className="container py-4">
            <div className="row">
              <div class="col-md-6">
                <h1 className="pb-md-4">Invoices</h1>
              </div>
              <div class="col-md-6">
                <form className="form-inline float-md-right mt-2">
                <Form.Group controlId="searchRegistration" className="">
                  <Form.Control
                    name="mark"
                    type="text"
                    className="text-uppercase"
                    value={registration}
                    onChange={updateRegistrationHandler}
                    placeholder="Search reg no."
                  />
                </Form.Group>
                </form>
              </div>
              
            </div>
            <div className="border-bottom mb-4"></div>
            <div class="table-responsive">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Invoice #</th>
                    <th scope="col" className="d-none d-lg-table-cell">Type</th>
                    <th scope="col">Registration</th>
                    <th scope="col" className="d-none d-lg-table-cell">Transfer Type</th>
                    <th scope="col">Invoice Date</th>
                    <th scope="col" className="d-none d-lg-table-cell">Invoice Total</th>
                    <th scope="col" className="d-none d-xl-table-cell">Status</th>
                    <th scope="col" className="d-none d-xl-table-cell">Payment</th>
                    <th scope="col" className="min"></th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceItems}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadInvoices: (reg) => dispatch(apiLoadInvoices(reg)),
    apiExportInvoice: (id) => dispatch(apiExportInvoice(id)),
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(null, mapDispatchToProps)(Invoices));
