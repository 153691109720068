import React, { useState, useRef } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import CompanyNameDropdown from '../../CompanyNameDropdown';
import FiltersCloseButton from '../../utils/FiltersCloseButton';

const UserFilters = (props) => {
  const refs = {
    statusEnabled: useRef(null),
    statusDisabled: useRef(null),
    optedInYes: useRef(null),
    optedInNo: useRef(null),
    isAdminYes: useRef(null),
    isAdminNo: useRef(null),
  }

  const [formValues, setFormValues] = useState({
    name: props.filters.name,
    companyName: props.filters.companyName,
    statuses: props.filters.statuses,
    optedIn: props.filters.optedIn,
    isAdmin: props.filters.isAdmin,
  });

  const updateStatusHandler = (e) => {
    let tmpValues = [];
    if (refs.statusEnabled.current.checked) {
      tmpValues.push(0);
    }
    if (refs.statusDisabled.current.checked) {
      tmpValues.push(1);
    }
    setFormValues({
      ...formValues,
      statuses: tmpValues
    })
  }

  const updateOptedInHandler = (e) => {
    let tmpValues = [];
    if (refs.optedInNo.current.checked) {
      tmpValues.push(0);
    }
    if (refs.optedInYes.current.checked) {
      tmpValues.push(1);
    }
    setFormValues({
      ...formValues,
      optedIn: tmpValues
    })
  }

  const updateIsAdminHandler = (e) => {
    let tmpValues = [];
    if (refs.isAdminNo.current.checked) {
      tmpValues.push(0);
    }
    if (refs.isAdminYes.current.checked) {
      tmpValues.push(1);
    }
    setFormValues({
      ...formValues,
      isAdmin: tmpValues
    })
  }

  const updateNameHandler = (e) => {
    setFormValues({
      ...formValues,
      name: e.target.value
    })
  }

  const updateCompanyNameHandler = (n) => {
    setFormValues({
      ...formValues,
      companyName: n
    })
  }

  const updateFiltersHandler = () => {
    props.updateMainState({
      ...formValues,
      page: 1
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <FiltersCloseButton/>
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
         <Form.Group controlId="formName" className="my-4">
            <Form.Label className="font-weight-bold">Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              placeholder="Name"
              className=""
              value={formValues.name}
              onChange={updateNameHandler}
            />
          </Form.Group>
          <CompanyNameDropdown label="Company name" companyName={formValues.companyName} onUpdate={updateCompanyNameHandler}/>
          <Form.Group controlId="formStatus0" className="mt-4 mb-1">
            <Form.Label className="font-weight-bold">Status</Form.Label>
            <Form.Check
              ref={refs.statusEnabled}
              name="status"
              type="checkbox"
              label="Enabled"
              defaultChecked={formValues.statuses.includes(0)}
              onChange={updateStatusHandler}
            />
          </Form.Group>
          <Form.Group controlId="formStatus1" className="mb-4">
            <Form.Check
              ref={refs.statusDisabled}
              name="status"
              type="checkbox"
              label="Disabled"
              defaultChecked={formValues.statuses.includes(1)}
              onChange={updateStatusHandler}
            />
          </Form.Group>
          <Form.Group controlId="formOptedIn1" className="mt-4 mb-1">
            <Form.Label className="font-weight-bold">Opted in?</Form.Label>
            <Form.Check
              ref={refs.optedInYes}
              name="optedIn"
              type="checkbox"
              label="Yes"
              defaultChecked={formValues.optedIn.includes(1)}
              onChange={updateOptedInHandler}
            />
          </Form.Group>
          <Form.Group controlId="formOptedIn0" className="mb-4">
            <Form.Check
              ref={refs.optedInNo}
              name="optedIn"
              type="checkbox"
              label="No"
              defaultChecked={formValues.optedIn.includes(0)}
              onChange={updateOptedInHandler}
            />
          </Form.Group>
          <Form.Group controlId="formIsAdmin1" className="mt-4 mb-1">
            <Form.Label className="font-weight-bold">Is admin?</Form.Label>
            <Form.Check
              ref={refs.isAdminYes}
              name="optedIn"
              type="checkbox"
              label="Yes"
              defaultChecked={formValues.isAdmin.includes(1)}
              onChange={updateIsAdminHandler}
            />
          </Form.Group>
          <Form.Group controlId="formIsAdmin0" className="mb-4">
            <Form.Check
              ref={refs.isAdminNo}
              name="optedIn"
              type="checkbox"
              label="No"
              defaultChecked={formValues.isAdmin.includes(0)}
              onChange={updateIsAdminHandler}
            />
          </Form.Group>
          <Button
            type="button"
            className="btn btn-dark btn-lg btn-block"
            onClick={updateFiltersHandler}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.userFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) => dispatch({ type: 'SET_USER_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserFilters));
