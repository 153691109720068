

const initAuth = () => {
  return {
    pageList: null,
    loaded: false
  };
};

function pages(state = initAuth(), action) {
  switch (action.type) {
    case "SET_PAGES":
      return { ...state, ...{ pageList: action.payload, loaded: true } };
    default:
      return state;
  }
}

export default pages;
