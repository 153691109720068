import React, { useState, useRef } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiAdminCancelInvoice } from '../../../store/actions/actions';

const CancelInvoiceModal = (props) => {

  const closeHandler = (e) => {
    props.onClose();
  }

  const refs ={
    chargeRestockingFee: useRef(null)
  }

  const [message, setMessage] = useState('');
  const [chargeRestockingFee, setChargeRestockingFee] = useState(false);

  const updateMessageHandler = (e) => {
    setMessage(e.target.value);
  }

  const confirmHandler = (e) => {
    props.apiAdminCancelInvoice(props.invoice.id, chargeRestockingFee, message)
      .then(({ response }) => {
        props.notify('Order #' + props.invoice.id.toString().padStart(6, '0') +  ' was successfully cancelled');
        props.onConfirm();
      })
      .catch((error) => {
        props.notify('Error cancelling order #' + props.invoice.id.toString().padStart(6, '0'));
      });
  }

  const chargeRestockingFeeChangeHandler = (e) => {
    setChargeRestockingFee(refs.chargeRestockingFee.current.checked);
  }
  let chargeFee = null;
  if(props.invoice.invoice_type === 'PURCHASE') {
    chargeFee = <Form.Group controlId="formChargeRestockingFee" className="mt-4 mb-1">
    <Form.Check
      name="chargeRestockingFee"
      ref={refs.chargeRestockingFee}
      type="checkbox"
      label="Charge Restocking Fee?"
      defaultChecked={chargeRestockingFee}
      onChange={chargeRestockingFeeChangeHandler}
    />
  </Form.Group>;
  }
  let msg = <Form.Group controlId="formMessage" className="my-4">
    <Form.Label className="font-weight-bold">Add an additional custom message</Form.Label>
    <Form.Control
      name="message"
      as="textarea"
      rows={3}
      placeholder=""
      value={message}
      onChange={updateMessageHandler}
    />
  </Form.Group>;
  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to cancel invoice <strong>#{props.invoice.id.toString().padStart(6, '0')}</strong>. Please confirm by clicking below.</p>
        {chargeFee}
        {msg}
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={confirmHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminCancelInvoice: (id, chargeRestockingFee, message) => dispatch(apiAdminCancelInvoice(id, chargeRestockingFee, message)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(CancelInvoiceModal);
