import React, { useState, useEffect, useRef }  from "react";
import { Form, Button, Card } from 'react-bootstrap';
import  { Link } from 'react-router-dom';
import { apiAdminLoadRegistration } from '../../../store/actions/actions';
import { apiAdminSaveRegistration } from '../../../store/actions/actions';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import StatusIdDropdown from '../../StatusIdDropdown';
import PortfolioIdDropdown from '../../PortfolioIdDropdown';
import { validate } from '../../../numberPlateValidator';
import validator from "validator";
import { numberAsString, anyErrors } from '../../../utils';

const RegistrationForm = (props) => {
  const defaultErrors = {
    mark: false,
    price: false,
    expiry_date: false,
    cost_price: false,
    status_id: false,
    portfolio_id: false,
    add_vat: false,
    enable_offers: false,
    auto_accept_offers: false,
    auto_accept_offers_min_value: false,
    notes: false
  }

  const defaultReg = {
    id: null,
    mark: '',
    price: 0.00,
    cost_price: 0.00,
    expiry_date: '',
    status_id: null,
    portfolio_id: null,
    add_vat: false,
    enable_offers: true,
    auto_accept_offers: false,
    auto_accept_offers_min_value: 0.00,
    notes: ''
  }

  const refs = {
    addVat: useRef(null),
    enableOffers: useRef(null),
    autoAcceptOffers: useRef(null)
  }

  const [registration, setRegistration] = useState(null);
  const [errors, setErrors] = useState({...defaultErrors})
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  
  useEffect(() => {
    if(props.registrationId === null) {
      setRegistration({ ...defaultReg });
    } else {
      props.apiAdminLoadRegistration(props.registrationId)
        .then(({ response }) => {
          if(response.data.registration.expiry_date != null){
            response.data.registration.expiry_date = new Date(response.data.registration.expiry_date).toLocaleDateString('en-GB');
          }
          setRegistration(response.data.registration);
        })
        .catch((error) => { });
    }
  }, [props.registrationId]);

  useEffect(() => {
    if(registration !== null) {
      let tmpErrors = { ...defaultErrors };
      if(!validate(registration.mark).isValid){
        tmpErrors.mark = true;
      }
      if(registration.status_id === null){
        tmpErrors.status_id = true;
      }
      if(registration.portfolio_id === null){
        tmpErrors.portfolio_id = true;
      }
      if(registration.expiry_date && !validator.isDate(registration.expiry_date, 'DD/MM/YYYY')) {
        tmpErrors.expiry_date = true;
      }
      if(!validator.isNumeric(numberAsString(registration.price))) {
        tmpErrors.price = true;
      }
      if(!validator.isNumeric(numberAsString(registration.cost_price))) {
        tmpErrors.cost_price = true;
      }
      if(!validator.isNumeric(numberAsString(registration.auto_accept_offers_min_value))) {
        tmpErrors.auto_accept_offers_min_value = true;
      }
      setErrors(tmpErrors);
    }
  }, [registration, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    console.log(e, fieldName);
    let tmpRegistration = { 
      ...registration 
    }
    tmpRegistration[fieldName] = e.target.value;
    setRegistration(tmpRegistration);
  }

  const statusIdUpdateHandler = (v) => {
    let tmpRegistration = { 
      ...registration
    }
    tmpRegistration.status_id = v;
    setRegistration(tmpRegistration);
  }

  const portfolioIdUpdateHandler = (v) => {
    let tmpRegistration = {
      ...registration
    }
    tmpRegistration.portfolio_id = v;
    setRegistration(tmpRegistration);
  }

  const addVatUpdateHandler = (e) => {
    let tmpRegistration = {
      ...registration
    }
    tmpRegistration.add_vat = refs.addVat.current.checked;
    setRegistration(tmpRegistration);
  }

  const enableOffersUpdateHandler = (e) => {
    let tmpRegistration = {
      ...registration
    }
    tmpRegistration.enable_offers = refs.enableOffers.current.checked;
    if(!tmpRegistration.enable_offers){
      tmpRegistration.auto_accept_offers_min_value = 0.00;
      tmpRegistration.auto_accept_offers = false;
    }
    setRegistration(tmpRegistration);
  }

  const autoAcceptOffersUpdateHandler = (e) => {
    let tmpRegistration = {
      ...registration
    }
    tmpRegistration.auto_accept_offers = refs.autoAcceptOffers.current.checked;
    if(!tmpRegistration.auto_accept_offers){
      tmpRegistration.auto_accept_offers_min_value = 0.00;
    }
    setRegistration(tmpRegistration);
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors(errors)){
      props.apiAdminSaveRegistration(registration)
        .then(({ response }) => {
          props.notify('The registration ' + registration.mark +  ' was successfully saved');
          setSaved(true);
        })
        .catch((error) => {
          props.notify('Error saving the registration ' + registration.mark);
        });
    }
  }

  let redirect = null;
  if(saved) {
    redirect = <Redirect to="/admin/registrations"/>
  }
  
  let form = null;

  if(registration) {

    let autoAcceptOfferItem = null;
    if(registration.enable_offers)  {
      autoAcceptOfferItem = <Form.Group controlId="formAutoAcceptOffers" className="mb-4">
      <Form.Check
        name="auto_accept_offers"
        ref={refs.autoAcceptOffers}
        type="checkbox"
        label="Auto accept offers on this registration"
        defaultChecked={registration.auto_accept_offers}
        onChange={autoAcceptOffersUpdateHandler}
      />
    </Form.Group>
    }

    let minOfferItem = null;
    if(registration.enable_offers && registration.auto_accept_offers) {
      minOfferItem = <Form.Group controlId="formMark">
      <Form.Label className="font-weight-bold">Min Offer Value</Form.Label>
      <Form.Control
        name="auto_accept_offers_min_value"
        type="text"
        placeholder=""
        className={errors.auto_accept_offers_min_value && submissionAttempted ? 'is-invalid' : ''}
        value={registration.auto_accept_offers_min_value}
        onChange={(e) => fieldUpdateHandler(e, 'auto_accept_offers_min_value')}
      />
    </Form.Group>
    }

    form = <Form onSubmit={submitFormHandler}>
      {redirect}
      <Card className="mb-4">
        <Card.Header as="h5">Registration details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formMark">
            <Form.Label className="font-weight-bold">Mark</Form.Label>
            <Form.Control
              name="mark"
              type="text"
              placeholder=""
              className={errors.mark && submissionAttempted  ? 'is-invalid text-uppercase' : 'text-uppercase'}
              value={registration.mark}
              onChange={(e) => fieldUpdateHandler(e, 'mark')}
            />
          </Form.Group>
          <StatusIdDropdown label="Status" statusId={registration.status_id} error={errors.status_id && submissionAttempted} onUpdate={statusIdUpdateHandler}></StatusIdDropdown>
          <Form.Group controlId="formExpiryDate">
            <Form.Label className="font-weight-bold">Expiry Date</Form.Label>
            <Form.Control
              name="expiry_date"
              type="text"
              placeholder=""
              className={errors.expiry_date && submissionAttempted  ? 'is-invalid text-uppercase' : 'text-uppercase'}
              value={registration.expiry_date}
              onChange={(e) => fieldUpdateHandler(e, 'expiry_date')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="h5">Pricing details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formPrice">
            <Form.Label className="font-weight-bold">Price</Form.Label>
            <Form.Control
              name="price"
              type="text"
              placeholder=""
              className={errors.price && submissionAttempted  ? 'is-invalid' : ''}
              value={registration.price}
              onChange={(e) => fieldUpdateHandler(e, 'price')}
            />
          </Form.Group>

          <Form.Group controlId="formCostPrice">
            <Form.Label className="font-weight-bold">Cost Price</Form.Label>
            <Form.Control
              name="cost_price"
              type="text"
              placeholder=""
              className={errors.cost_price && submissionAttempted  ? 'is-invalid' : ''}
              value={registration.cost_price}
              onChange={(e) => fieldUpdateHandler(e, 'cost_price')}
            />
          </Form.Group>
          <Form.Group controlId="formAddVat" className="mt-4 mb-1">
            <Form.Check
              name="add_vat"
              ref={refs.addVat}
              type="checkbox"
              label="Add VAT to the asking price"
              defaultChecked={registration.add_vat}
              onChange={addVatUpdateHandler}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="h5">Offer details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formEnableOffers" className="mb-1">
            <Form.Check
              name="enable_offers"
              ref={refs.enableOffers}
              type="checkbox"
              label="Enable offers on this registration"
              defaultChecked={registration.enable_offers}
              onChange={enableOffersUpdateHandler}
            />
          </Form.Group>
          {autoAcceptOfferItem}
          {minOfferItem}
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="h5">Portfolio details</Card.Header>
        <Card.Body>
          <PortfolioIdDropdown label="Portfolio" portfolioId={registration.portfolio_id} error={errors.portfolio_id && submissionAttempted} onUpdate={portfolioIdUpdateHandler}></PortfolioIdDropdown>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header as="h5">Notes</Card.Header>
        <Card.Body>
          <Form.Group controlId="formCostPrice">
            <Form.Label className="font-weight-bold">Notes</Form.Label>
            <Form.Control
              name="notes"
              type="text"
              as="textarea"
              rows={3}
              placeholder=""
              className={errors.notes && submissionAttempted  ? 'is-invalid' : ''}
              value={registration.notes}
              onChange={(e) => fieldUpdateHandler(e, 'notes')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
      <Link to="/admin/registrations" className="btn btn-secondary">Cancel</Link>
    </Form>
  }
  return (
    form
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadRegistration: (rid) => dispatch(apiAdminLoadRegistration(rid)),
    apiAdminSaveRegistration: (r) => dispatch(apiAdminSaveRegistration(r)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(null, mapDispatchToProps)(RegistrationForm));
