import React from 'react';
import AdminLayout from '../../../components/Layouts/AdminLayout';
import StatusNoteList from '../../../components/Admin/StatusNoteList'

const StatusNotes = (props) => {
  return (
    <AdminLayout>
      <div className="container">
        <h1 className="my-4 pb-4 border-bottom">Status Notes</h1>
        <p className=""><strong>Manage your list of available status notes.</strong> (Used when configuring statuses)</p>
        <StatusNoteList/>
      </div>
    </AdminLayout>
  )
}

export default StatusNotes;
