import axios from "../../axios";
import jwt from "jsonwebtoken";

// Set timeout for web token.
const NOW = Math.floor(Date.now() / 1000);

// Set the initial state.
const initAuth = () => {
  const loggedIn = processLogin();
  return { loggedIn };
};

// Check to see if we've previously logged in.
const processLogin = () => {
  let loggedIn = false;
  const storedToken = localStorage.getItem("jwt");

  if (storedToken) {
    const decodedToken = jwt.decode(storedToken, "SOME_SECRET");

    // Has token expired?
    if (decodedToken && decodedToken.exp >= NOW) {
      loggedIn = true;
    } else {
      localStorage.removeItem("jwt");
    }
  }

  // Set authorization header if we are logged in.
  if (loggedIn) {
    axios.defaults.headers.common["Authorization"] = storedToken;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
  return loggedIn;
}

function authentication(state = initAuth(), action) {
  switch (action.type) {
    case "SET_LOGGED_IN":
      localStorage.setItem("jwt", action.payload.jwt);
      localStorage.setItem("initials", action.payload.subscriber.first_name[0] + action.payload.subscriber.last_name[0]);
      localStorage.setItem("isAdmin", action.payload.subscriber.is_admin);
      processLogin();
      return { ...state, ...{ loggedIn: action.payload } };
      
    case "LOG_OUT":
      localStorage.removeItem("jwt");
      return { ...state, ...{ loggedIn: false } };
    default:
      return state;
  }
}

export default authentication;

