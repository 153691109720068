import React from "react";
import MainNav from '../Admin/MainNav/MainNav';
import Footer from '../Admin/Footer/Footer';

const AdminLayout = (props) => {
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          {props.children}
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default AdminLayout;