import React, { useState } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import Gbp from '../../utils/Gbp';
import { apiAdminDeclineOffer } from '../../../store/actions/actions';
import NumberPlate from '../../NumberPlate';

const DeclineOfferModal = (props) => {
  const closeHandler = (e) => {
    props.onClose();
  }

  const declineHandler = (e) => {
    props.apiAdminDeclineOffer(props.offer.id, message)
      .then(({ response }) => {
        props.notify('Offer for ' + props.offer.registration + ' was successfully declined');
        props.onDecline();
      })
      .catch((error) => {
        props.notify('Error declining offer for ' + props.offer.registration);
      });
  }

  const [message, setMessage] = useState('');

  const updateMessageHandler = (e) => {
    setMessage(e.target.value);
  }

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Decline Offer</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to decline the offer of <strong><Gbp amount={props.offer.offer_price}/></strong> for the registration <NumberPlate mark={props.offer.registration}/>. Please confirm by clicking below.</p>
        <Form.Group controlId="formMessage" className="my-4">
          <Form.Label className="font-weight-bold">Add an additional custom message</Form.Label>
          <Form.Control
            name="message"
            as="textarea"
            rows={3}
            placeholder=""
            value={message}
            onChange={updateMessageHandler}
          />
        </Form.Group>
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={declineHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminDeclineOffer: (id, message) => dispatch(apiAdminDeclineOffer(id, message)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(DeclineOfferModal);
