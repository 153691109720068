import React from "react";

const PaginationDetail = (props) => {
  let previousDisabled = null;
  let nextDisabled = null;

  const previousPageHandler = (e) => {
    props.onPreviousClick();
  }

  const nextPageHandler = (e) => {
    props.onNextClick();
  }

  let pagination = null;
  if(props.meta !== null) {
    previousDisabled = (props.meta.page < 2);
    nextDisabled = (props.meta.page >= props.meta.total_pages);

    pagination = <div className="row d-flex align-items-center mb-4">
      <div className="col col-md-9">
        <strong>Displaying {props.meta.start_record}-{props.meta.end_record}</strong> of {props.meta.total_rows} in total
      </div>
      { props.display !== false && (
      <div className="col col-md-3 text-right">
        <nav className="float-right">
          <ul className="pagination pagination-sm mb-0">
            <li className={previousDisabled ? "page-item disabled" : "page-item"}>
              <button disabled={previousDisabled} className="page-link text-dark" onClick={previousPageHandler}>&laquo;</button>
            </li>
            <li className={nextDisabled ? "page-item disabled" : "page-item"}>
              <button disabled={nextDisabled} className="page-link text-dark" onClick={nextPageHandler}>&raquo;</button>
            </li>
          </ul>
        </nav>
      </div>
      )}
    </div>;
  }

  return (
    pagination
  )
}

export default PaginationDetail;