const initAuth = () => {
  return {
    filters: {
      name: '',
      companyName: '',
      statuses: [], 
      optedIn: [],
      isAdmin: [],
      page: 1
    }
  };
};

function userFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_USER_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    default:
      return state;
  }
}

export default userFilters;
