import React  from "react";
import { CircleFill } from 'react-bootstrap-icons';

const InvoiceType = (props) => {
  let variant = 'warning';
  let label = 'Purchase'
  switch(props.type){
    case 'PURCHASE':
      variant = 'success';
      break;
    case 'RESTOCKING_FEE':
      variant = 'danger';
      label = 'Restocking Fee'
      break;
    default:
      break;
  }
  
  return (
    <small>
      <CircleFill className={'text-' + variant}/><span className="text-muted pl-2">{label}</span>
    </small>
  )
}

export default InvoiceType;
