import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import ResponseBar from "../../components/ResponseBar";
import { apiLoadSubscriber, apiSaveSubscriber } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import { Form, Button, Card } from 'react-bootstrap';
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';
import validator from "validator";

const UpdateProfile = (props) => {
  const [user, setUser] = useState(null);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    opted_in: false,
    position: false
  })
  const refs = {
    optedIn: useRef(null)
  }

  useEffect(() => {
    props.apiLoadSubscriber(props.userId)
      .then(({ response }) => {
        setUser({
          id: response.data.subscriber.id,
          first_name: response.data.subscriber.first_name,
          last_name: response.data.subscriber.last_name,
          email: response.data.subscriber.email,
          opted_in: response.data.subscriber.opted_in,
          position: response.data.subscriber.position,
        });
      })
      .catch((error) => {
        console.log("Error retrieving user");
      });
  }, []);

  useEffect(() => {
    if(user !== null) {
      let tmpErrors = {
        first_name: false,
        last_name: false,
        email: false,
        opted_in: false,
        position: false
      }
      if(validator.isEmpty(user.first_name)){
        tmpErrors.first_name = true;
      }
      if(validator.isEmpty(user.last_name)){
        tmpErrors.last_name = true;
      }
      if(validator.isEmpty(user.email) || !validator.isEmail(user.email)){
        tmpErrors.email = true;
      }
      if(validator.isEmpty(user.position)){
        tmpErrors.position = true;
      }
      setErrors(tmpErrors);
    }
  }, [user, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    let tmpUser = {
      ...user
    }

    tmpUser[fieldName] = e.target.value;
    setUser(tmpUser);
  }

  const isOptedInHandler = (e) => {
    let tmpUser = {
      ...user
    }

    tmpUser.opted_in = refs.optedIn.current.checked;
    setUser(tmpUser);
  }

  const anyErrors = () => {
    for (const [, value] of Object.entries(errors)) {
      if (value) {
        return true;
      }
    }
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors()){
      props.apiSaveSubscriber(user)
        .then(({ response }) => {
          setSaved(true);
        })
        .catch((error) => {
          
        });
    }
  }

  const formSubmitHandler = (e) => {
    e.preventDefault();
  }

  const refreshForm = () => {
    setSaved(false);
    setSubmissionAttempted(false);
  }

  let form = null;
  if(user) {
    form = <Form onSubmit={submitFormHandler}>
      <Card>
        <Card.Header as="h5">Your details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">First Name</Form.Label>
            <Form.Control
              name="first_name"
              type="text"
              placeholder="First Name"
              className={errors.first_name ? 'is-invalid' : ''}
              value={user.first_name}
              onChange={(e) => fieldUpdateHandler(e, 'first_name')}
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Last Name</Form.Label>
            <Form.Control
              name="last_name"
              type="text"
              placeholder="Last Name"
              className={errors.last_name ? 'is-invalid' : ''}
              value={user.last_name}
              onChange={(e) => fieldUpdateHandler(e, 'last_name')}
            />
          </Form.Group>
          
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Email</Form.Label>
            <Form.Control
              name="email"
              type="text"
              placeholder="Email"
              className={errors.email ? 'is-invalid' : ''}
              value={user.email}
              onChange={(e) => fieldUpdateHandler(e, 'email')}
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Position</Form.Label>
            <Form.Control
              name="position"
              type="text"
              placeholder="Position"
              className={errors.position ? 'is-invalid' : ''}
              value={user.position}
              onChange={(e) => fieldUpdateHandler(e, 'position')}
            />
          </Form.Group>
          <Form.Group controlId="formOptedIn" className="mt-4 mb-1">
            <Form.Check
              name="opted_in"
              ref={refs.optedIn}
              type="checkbox"
              label="Opt in to receive communications"
              defaultChecked={user.opted_in}
              onChange={isOptedInHandler}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
    </Form>
  }

  let content = form;
  if(saved) {
    content = <div>
      <p>Your changes were successfully saved.</p>
      <button className="btn btn-dark" onClick={refreshForm}>
        Make more changes
      </button>
    </div>;
  }
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <form onSubmit={formSubmitHandler}>
          <ResponseBar />
          <div className="container py-4">
            <h1 className="pb-4 border-bottom mb-4">Update Profile</h1>
            {content}
          </div>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadSubscriber: () => dispatch(apiLoadSubscriber()),
    apiSaveSubscriber: (data) => dispatch(apiSaveSubscriber(data))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UpdateProfile));
