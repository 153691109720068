import React from 'react';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import CompanyForm from '../../../components/Admin/Users/UserForm';

const AddUser = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Edit User</h1>
          <CompanyForm userId={props.match.params.id}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(AddUser);