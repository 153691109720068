const initAuth = () => {
  return {
    filters: {
      mark: "",
      types: [],
      price_from: 0,
      price_to: 250000,
      statuses: [],
      vat: "Any",
      page: 1
    }
  };
};

function registrationFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_REGISTRATION_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    case "RESET_REGISTRATION_FILTERS":
      return { ...state, ...{ 
        filters: {
          mark: "",
          types: [],
          price_from: 0,
          price_to: 250000,
          statuses: [],
          vat: "Any",
          page: 1
        }
      }};
    default:
      return state;
  }
}

export default registrationFilters;
