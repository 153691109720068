import React, { useState } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiAdminUpdateInvoiceStatuses } from '../../../store/actions/actions';

const UpdateInvoiceStatusesModal = (props) => {

  const [formValues, setFormValues] = useState({
    status: props.invoice.status,
    paymentStatus: props.invoice.payment_status
  })

  const updateStatusHandler = (e) => {
    setFormValues({
      ...formValues,
      status: e.target.value
    })
  }

  const updatePaymentStatusHandler = (e) => {
    setFormValues({
      ...formValues,
      paymentStatus: e.target.value
    })
  }

  const [message, setMessage] = useState('');

  const updateMessageHandler = (e) => {
    setMessage(e.target.value);
  }


  const closeHandler = (e) => {
    props.onClose();
  }

  const convertFormValues = () => {
    let tmpFormValues = {
      status: 0,
      payment_status: 0,
      message: message
    }

    if(formValues.status === 'Processed') {
      tmpFormValues.status = 1;
    }

    if(formValues.paymentStatus === 'Received') {
      tmpFormValues.payment_status = 1;
    }

    return tmpFormValues;
  }

  const confirmHandler = (e) => {
    props.apiAdminUpdateInvoiceStatuses(props.invoice.id, convertFormValues(formValues))
      .then(({ response }) => {
        props.onConfirm();
        props.notify('Statuses for order #' + props.invoice.id.toString().padStart(6, '0') +  ' were successfully updated');
      })
      .catch((error) => {
        props.notify('Error when saving Statuses for order #' + props.invoice.id.toString().padStart(6, '0'));
      });
  }

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Update Statuses</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to update the statuses for invoice <strong>#{props.invoice.id.toString().padStart(6, '0')}</strong>. Please select your statuses and click to confirm.</p>
        <Form.Group controlId="formStatus" className="my-4">
          <Form.Label className="font-weight-bold">Status</Form.Label>
          <Form.Control as="select" defaultValue={formValues.status} onChange={updateStatusHandler}>
            <option value="New">New</option>
            <option value="Processed">Processed</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formPaymentStatus" className="my-4">
          <Form.Label className="font-weight-bold">Payment Status</Form.Label>
          <Form.Control as="select" defaultValue={formValues.paymentStatus} onChange={updatePaymentStatusHandler}>
            <option value="Pending">Pending</option>
            <option value="Received">Received</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formMessage" className="my-4">
          <Form.Label className="font-weight-bold">Add an additional custom message</Form.Label>
          <Form.Control
            name="message"
            as="textarea"
            rows={3}
            placeholder=""
            value={message}
            onChange={updateMessageHandler}
          />
        </Form.Group>
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={confirmHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminUpdateInvoiceStatuses: (id, d) => dispatch(apiAdminUpdateInvoiceStatuses(id, d)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(UpdateInvoiceStatusesModal);
