import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import RegistrationForm from '../../../components/Admin/Registrations/RegistrationForm';


const AddRegistration = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <div className="container">
            <h1 className="my-4">Add Registration</h1>
            <RegistrationForm registrationId={null}/>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}



export default withRouter(connect(null, null)(AddRegistration));
