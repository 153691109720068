import React, { useState } from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../../components/Layouts/AdminLayout';
import OfferList from "../../../components/Admin/Offers/OfferList";
import OfferFilters from "../../../components/Admin/Offers/OfferFilters";
import { apiAdminExportOffers } from '../../../store/actions/actions';
import { Button } from 'react-bootstrap';

const Offers = (props) => {
  const [exporting, setExporting] = useState(false);

  const exportHandler = (e) => {
    setExporting(true);
    props.apiAdminExportOffers(props.filters)
    .then(({ response }) => {
      setExporting(false);
    })
    .catch((error) => { 
      setExporting(false);
    })
  }
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="row d-flex align-items-center">
          <div className="col">
            <h1 className="my-4">Offers</h1>
          </div>
          <div className="col text-right">
            <Button variant="dark" className={exporting ? "btn-sm mr-2 disabled" : "btn-sm mr-2"} onClick={exportHandler}>Export</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4">
            <OfferFilters />
          </div>
          <div className="col-md-9">
            <OfferList />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
const mapStateToProps = state => {
  return {
    filters: state.offerFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminExportOffers: (f) => dispatch(apiAdminExportOffers(f)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
