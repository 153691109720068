import React  from "react";
import { CircleFill } from 'react-bootstrap-icons';

const InvoicePaymentStatusBadge = (props) => {
  let variant = 'warning';
  switch(props.paymentStatus){
    case 'Pending':
      variant = 'warning';
      break;
    case 'Received':
      variant = 'success';
      break;
    default:
      break;
  }
  
  return (
    <small className="text-nowrap">
      <CircleFill className={'text-' + variant}/><span className="text-muted pl-2">{props.paymentStatus}</span>
    </small>
  )
}

export default InvoicePaymentStatusBadge;
