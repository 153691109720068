import React, { useState, useEffect } from "react";
import  { Link } from 'react-router-dom';
import MoreInformationFormIntro from './MoreInformationFormIntro';
import { connect } from 'react-redux';
import { apiLoadRegistration } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import Gbp from '../utils/Gbp';
import NumberPlate from '../NumberPlate';

const MoreInformationForm = (props) => {
  
  // ***** Default state *****
  const [registration, setRegistration] = useState(null);
  const [apiError, setApiError] = useState(false);

  // ***** Mount handler *****
  
  // Trigger API call when registration mark changes
  useEffect(() => {
    props.apiLoadRegistration(props.mark)
      .then(({ response }) => {
        setApiError(false)
        setRegistration(response.data.registration)
      })
      .catch((error) => {
        setApiError(true)
        setRegistration(null);
      });
  }, [props.mark]);

  const displayPrice = (r) => {
    if(r.reduced_price > 0) {
      return r.reduced_price;
    }
    return r.price;
  }

  const vatPrice = () => {
    if(!registration || !registration.add_vat) return 0
    if(registration.add_vat) {
      return roundToTwo((displayPrice(registration)/100) * 20);
    }
  }

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  const total = () => {
    if(!registration) return 0
    return (displayPrice(registration) + vatPrice())
  }

  let buttons = null;
  if(props.buttons) {
    buttons = <div className="mb-5">
    <Link to="/registrations" className="btn btn-secondary">
      Back
    </Link>
  </div>;
  }


  let form =    <div></div>;
  if(apiError) {
    form = <div>Registration Not Found</div>;
  } else {
    if(registration) {
      let expiryDate = <div className="row mb-2">
        <div className="col-md-3"><strong>Expiry Date</strong></div>
        <div className="col-md-9">N/A</div>
      </div>;
      if(registration.expiry_date) {
        expiryDate = <div className="row mb-2">
        <div className="col-md-3"><strong>Expiry Date</strong></div>
        <div className="col-md-9">{registration.expiry_date}</div>
      </div>;
      }

      let notes = <div>
        <hr/>
        <div className="row mb-2">
          <div className="col-md-3"><strong>Notes</strong></div>
          <div className="col-md-9">N/A</div>
        </div>
      </div>;
      if(registration.notes) {
        let noteItems = <div><div>{registration.notes}</div></div>; 
        
        notes = <div>
        <hr/>
        <div className="row mb-2">
          <div className="col-md-3"><strong>Notes</strong></div>
          <div className="col-md-9">{noteItems}</div>
        </div>
      </div>;
      }

      let collapsableContent = null;
      if(!props.collapsed){
        collapsableContent = <div><div className="row mb-2">
        <div className="col-md-3"><strong>VAT</strong></div>
        <div className="col-md-9"><Gbp amount={vatPrice()}/></div>
      </div>
      <div className="row mb-2">
        <div className="col-md-3"><strong>Total to Pay</strong></div>
        <div className="col-md-9"><Gbp amount={total()}/> (Excludes transfer fees)</div>
      </div>{notes}</div>
      }
      form = <div>
      <div class="card mb-4">
        <div class="card-header">
          <strong>Registration Details</strong>
        </div>
        <div class="card-body">
          <div className="row mb-2">
            <div className="col-md-3"><strong>Registration</strong></div>
            <div className="col-md-9"><NumberPlate mark={registration.mark}/></div>
          </div>
          <hr/>
          <div className="row mb-2">
            <div className="col-md-3"><strong>Current Status</strong></div>
            <div className="col-md-9">{registration.status}</div>
          </div>
          {expiryDate}
          <hr/>
          <div className="row mb-2">
            <div className="col-md-3"><strong>Price</strong></div>
            <div className="col-md-9"><Gbp amount={displayPrice(registration)}/></div>
          </div>
          {collapsableContent}
          
        </div>
      </div>
      {buttons}
      
    </div>
    
    }
  }
  return (
    <div class="container">
      <MoreInformationFormIntro heading={props.heading} mark={props.mark} error={apiError}/>
      {form}
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadRegistration: (m) => dispatch(apiLoadRegistration(m)),
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(null, mapDispatchToProps)(MoreInformationForm));

