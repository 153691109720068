import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter, Redirect } from "react-router-dom";
import  { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Gbp from '../../utils/Gbp';
import NumberPlate from '../../NumberPlate';
import { apiAdminDealerRegistrationSearch } from "../../../store/actions/actions";
import PaginationDetail from '../../PaginationDetail';
import { displayPrice, displayCostPrice } from '../../../utils';
import Dropdown from 'react-bootstrap/Dropdown'

const DealerRegistrationList = (props) => {
  const [registrationResults, setRegistrationResults] = useState([]);
  const [meta, setMeta] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [redirectToReporting, setRedirectToReporting] = useState(false);
  const [deleteRegistrationModal, setDeleteRegistrationModal] = useState(null);

  useEffect(() => {
    props.apiAdminDealerRegistrationSearch(props.filters)
      .then(({ response }) => {
        setRegistrationResults(response.data.registrations);
        setMeta(response.data.meta);
      })
      .catch((error) => { })
  },[props.filters, refreshCounter, props.counter]);

  const previousPageHandler = (e) => {
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
    }
  }

  const nextPageHandler = (e) => {
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
    }
  }

  const reportHandler = (e, r) => {
    props.updateReportingState(
      {
        subscriber_email:'',
        registration: r.mark,
        event_type: '',
        page: 1
      }
    );
    setRedirectToReporting(true);
  }

  const deleteRegistrationCloseHandler = (e) => {
    setDeleteRegistrationModal(null);
  }

  const deleteRegistrationClickHandler = (e, index) => {
    setDeleteRegistrationModal(registrationResults[index]);
  }

  const deleteRegistrationConfirmedHandler = (e, id, mark) => {
    setDeleteRegistrationModal(null);
    setRefreshCounter(refreshCounter+1);
  }


  let registrationItems = null;
  if(registrationResults.length > 0){

    registrationItems = registrationResults.map((r, index) => {
      return (
        <tr key={index}>
          <th scope="row"><NumberPlate mark={r.mark}/></th>
          <td className=""><Gbp amount={displayPrice(r)}/></td>
          <td className="">{r.dealer_name}</td>
          <td className="d-none d-md-table-cell">{r.friendly_status} {r.friendly_sold_at}</td>
          <td className="d-none d-md-table-cell">{r.friendly_updated_at}</td>
        </tr>
      )
    });
  }

  if(registrationResults.length > 0) {


    let rtr = null;
    if(redirectToReporting) {
      rtr = <Redirect to="/admin/reporting"/>;
    }
    return (
      <React.Fragment>
        {rtr}
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Registration</th>
              <th scope="col" className="">Price</th>
              <th scope="col" className="">Dealer</th>
              <th scope="col" className="d-none d-md-table-cell">Status</th>
              <th scope="col" className="d-none d-md-table-cell">Updated At</th>
            </tr>
          </thead>
          <tbody>
            {registrationItems}
          </tbody>
        </table>
        <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} />
       
      </React.Fragment>
    )
  }
  return(
    <div></div>
  )
}

const mapStateToProps = state => {
  return {
    filters: state.registrationFilters.filters
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminDealerRegistrationSearch: (f) => dispatch(apiAdminDealerRegistrationSearch(f)),
    updateMainState: (f) => dispatch({ type: 'SET_REGISTRATION_FILTERS', payload: f }),
    updateReportingState: (f) => dispatch({ type: 'SET_REPORTING_FILTERS', payload: f }),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealerRegistrationList));