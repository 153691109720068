import React, { useState } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import Gbp from '../../utils/Gbp';
import { apiAdminCounterOffer } from '../../../store/actions/actions';
import NumberPlate from '../../NumberPlate';

const CounterOfferModal = (props) => {

  const [counterAmount, setCounterAmount] = useState(props.offer.offer_price);

  const updateCounterAmount = (e) =>  {
    setCounterAmount(e.target.value);
  }

  const closeHandler = (e) => {
    props.onClose();
  }

  const counterOfferHandler = (e) => {
    props.apiAdminCounterOffer(props.offer.id, counterAmount, message)
      .then(({ response }) => {
        props.notify('Counter offer for ' + props.offer.registration + ' was successfully created');
        props.onCounterOffer();
      })
      .catch((error) => {
        props.notify('Error countering offer for ' + props.offer.registration);
      });
  }

  const [message, setMessage] = useState('');

  const updateMessageHandler = (e) => {
    setMessage(e.target.value);
  }

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Counter Offer</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to counter the offer of <strong><Gbp amount={props.offer.offer_price}/></strong> for the registration <NumberPlate mark={props.offer.registration}/>. Please enter your counter offer below and click to confirm.</p>
        <Form.Group controlId="formCounterAmount" className="my-4">
          <Form.Label className="font-weight-bold">Counter offer</Form.Label>
          <Form.Control
            name="counter_amount"
            type="text"
            placeholder="100"
            className="text-uppercase"
            value={counterAmount}
            onChange={updateCounterAmount}
          />
        </Form.Group>
        <Form.Group controlId="formMessage" className="my-4">
          <Form.Label className="font-weight-bold">Add an additional custom message</Form.Label>
          <Form.Control
            name="message"
            as="textarea"
            rows={3}
            placeholder=""
            value={message}
            onChange={updateMessageHandler}
          />
        </Form.Group>
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={counterOfferHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminCounterOffer: (id, counterPrice, message) => dispatch(apiAdminCounterOffer(id, counterPrice, message)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(CounterOfferModal);
