import Home from "./../../features/Home/Home";
import Page from "./../../features/Page/Page";
import Login from "./../../features/Login/Login";
import Apply from "./../../features/Apply/Apply";
import Registrations from "./../../features/Registrations/Registrations";
import RegistrationsDownload from "./../../features/RegistrationsDownload/RegistrationsDownload";
import Registration from "./../../features/Registration/Registration";
import MoreInformation from "./../../features/MoreInformation/MoreInformation";
import MakeOffer from "./../../features/MakeOffer/MakeOffer";
import Enquire from "./../../features/Enquire/Enquire";
import Invoices from "./../../features/Invoices/Invoices";
import CompanySettings from "./../../features/CompanySettings/CompanySettings";
import UpdateProfile from "./../../features/UpdateProfile/UpdateProfile";
import ForgottenPassword from "./../../features/ForgottenPassword/ForgottenPassword";
import ResetPassword from "./../../features/ResetPassword/ResetPassword";
import UpdatePassword from "./../../features/ResetPassword/UpdatePassword";



function registerRoute(route) {
  const addRoute = Object.assign(
    {},
    {
      // Component to render the route
      component: null,

      // Name reference for the route
      name: null,

      // Path to match the route
      path: null,

      // Whether the user should be authenticated to view the route
      auth: true,

      // Whether the user should be an admin to view the route
      admin: true,

      // Whether the route should match the path exactly
      exact: true,
    },
    route
  );

  return addRoute;
}

const customerRoutes = [
  registerRoute({
    component: Home,
    name: 'Home',
    path: '/',
    headerTitle: 'T12ADE | The trade-only platform for number plates',
    auth: false,
    admin: false
  }),
  registerRoute({
    component: Registrations,
    name: 'Registrations',
    path: '/registrations',
    headerTitle: 'T12ADE | Browse our stock registrations',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: RegistrationsDownload,
    name: 'Registration',
    path: '/registrations/download',
    headerTitle: 'T12ADE | Download stock list',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: Registration,
    name: 'Registration',
    path: '/registrations/:mark',
    headerTitle: 'T12ADE | Order form',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: MoreInformation,
    name: 'MoreInformation',
    path: '/registrations/more-information/:mark',
    headerTitle: 'T12ADE | More information',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: MakeOffer,
    name: 'MakeOffer',
    path: '/registrations/make-offer/:mark',
    headerTitle: 'T12ADE | Make offer',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: Enquire,
    name: 'Enquire',
    path: '/registrations/enquire/:mark',
    headerTitle: 'T12ADE | Enquire',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: Invoices,
    name: 'Invoices',
    path: '/invoices',
    headerTitle: 'T12ADE | Invoices',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: UpdateProfile,
    name: 'UpdateProfile',
    path: '/update-profile',
    headerTitle: 'T12ADE | Update profile',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: CompanySettings,
    name: 'CompanySettings',
    path: '/company-settings',
    headerTitle: 'T12ADE | Company settings',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: ResetPassword,
    name: 'ResetPassword',
    path: '/reset-password',
    headerTitle: 'T12ADE | Reset password',
    admin: false,
    auth: false,
    redirect: '/registrations'
  }),
  registerRoute({
    component: UpdatePassword,
    name: 'UpdatePassword',
    path: '/update-password',
    headerTitle: 'T12ADE | Update password',
    admin: false,
    auth: true,
    redirect: '/login'
  }),
  registerRoute({
    component: ForgottenPassword,
    name: 'ForgottenPassword',
    path: '/forgotten-password',
    headerTitle: 'T12ADE | Forgotten password',
    admin: false,
    auth: false,
    redirect: '/registrations'
  }),
  registerRoute({
    component: Login,
    name: 'Login',
    path: '/login',
    headerTitle: 'T12ADE | Member login',
    admin: false,
    auth: false,
    redirect: '/registrations'
  }),
  registerRoute({
    component: Apply,
    name: 'Apply',
    path: '/apply',
    headerTitle: 'T12ADE | Apply',
    admin: false,
    auth: false,
    redirect: '/registrations'
  }),
  registerRoute({
    component: Page,
    name: 'Page',
    path: '/pages/:slug',
    headerTitle: 'T12ADE | Page',
    admin: false,
    auth: false
  }),
];

export default customerRoutes;