import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { apiAdminLoadInvoices, apiAdminExportInvoice } from '../../../store/actions/actions'
import Gbp from '../../utils/Gbp'
import InvoiceStatusBadge from './InvoiceStatusBadge';
import InvoicePaymentStatusBadge from './InvoicePaymentStatusBadge';
import UpdateInvoiceStatusesModal from './UpdateInvoiceStatusesModal';
import CancelInvoiceModal from './CancelInvoiceModal';
import PaginationDetail from '../../PaginationDetail';
import NumberPlate from '../../NumberPlate';
import InvoiceType from './InvoiceType';
import Dropdown from 'react-bootstrap/Dropdown'

const OrderList = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [processedModal, setProcessedModal] = useState(null);
  const [cancelInvoiceModal, setCancelInvoiceModal] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    props.apiAdminLoadInvoices(props.filters)
      .then(({ response }) => {
        setInvoices(response.data.invoices);
        setMeta(response.data.meta);
      })
      .catch((error) => {
        console.log("Error retrieving invoices");
      });
  }, [props.filters, props.page, refreshCounter]);

  
  

  const previousPageHandler = (e) => {
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
    }
  }

  const nextPageHandler = (e) => {
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
    }
  }

  const processedClickHandler = (e, index) => {
    setProcessedModal(invoices[index]);
  }

  const processedCloseHandler = (e) => {
    setProcessedModal(null);
  }

  const processedConfirmedHandler = (e) => {
    setProcessedModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const cancelClickHandler = (e, index) => {
    setCancelInvoiceModal(invoices[index]);
  }

  const cancelInvoiceCloseHandler = (e) => {
    setCancelInvoiceModal(null);
  }

  const cancelInvoiceConfirmedHandler = (e) => {
    setCancelInvoiceModal(null);
    setRefreshCounter(refreshCounter+1);
  }

  const downloadHandler = (e, index) => {
    props.apiAdminExportInvoice(invoices[index].id)
    .then(({ response }) => {
      
    })
    .catch((error) => {

    });
  }


  let invoiceItems = [];
  if( invoices && invoices.length > 0 ) {
    invoiceItems = invoices.map((i, index) => {

      return <tr>
        <td><strong>{i.id.toString().padStart(6, '0')}</strong></td>
        <td><InvoiceType type={i.invoice_type}/></td>
        <td><NumberPlate mark={i.mark}/></td>
        <td>{i.order_date}</td>
        <td className="d-none d-md-table-cell">{i.transfer_type}<br/><small className="text-muted">{i.nominee_name ? i.nominee_name : ''}</small></td>
        <td className="d-none d-md-table-cell"><Gbp amount={i.total}/></td>
        <td className="d-none d-md-table-cell">{i.company}</td>
        <td><InvoiceStatusBadge status={i.status}/></td>
        <td><InvoicePaymentStatusBadge paymentStatus={i.payment_status}/></td>
        <td className="min">
          <div class="btn-group">
            <button type="button" class="btn btn-link text-dark" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            &hellip;
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="drop2">
              <div>
                <button class="dropdown-item" onClick={(e) => processedClickHandler(e, index)}>Update statuses</button>
                <button class="dropdown-item" onClick={(e) => downloadHandler(e, index)}>Download Invoice</button>
                <Dropdown.Divider></Dropdown.Divider>
                <button class="dropdown-item text-danger" onClick={(e) => cancelClickHandler(e, index)}>Cancel invoice</button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    });
  }

  let updateInvoiceModalItem = null;
  if(processedModal !== null) {
    updateInvoiceModalItem = <UpdateInvoiceStatusesModal invoice={processedModal} onClose={processedCloseHandler} onConfirm={processedConfirmedHandler}/>;
  }

  let cancelInvoiceModalItem = null;
  if(cancelInvoiceModal !== null) {
    cancelInvoiceModalItem = <CancelInvoiceModal invoice={cancelInvoiceModal} onClose={cancelInvoiceCloseHandler} onConfirm={cancelInvoiceConfirmedHandler}/>;
  }

  return (
    <div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">invoice #</th>
            <th scope="col">Type</th>
            <th scope="col">Registration</th>
            <th scope="col">Date</th>
            <th scope="col" className="d-none d-md-table-cell">Transfer Type</th>
            <th scope="col" className="d-none d-md-table-cell">Total</th>
            <th scope="col" className="d-none d-md-table-cell">Company</th>
            <th scope="col">Status</th>
            <th scope="col">Payment</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {invoiceItems}
        </tbody>
      </table>
      <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} />
      {updateInvoiceModalItem}
      {cancelInvoiceModalItem}
    </div>
    )
}

const mapStateToProps = state => {
  return {
    filters: state.orderFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadInvoices: (f) => dispatch(apiAdminLoadInvoices(f)),
    apiAdminExportInvoice: (id) => dispatch(apiAdminExportInvoice(id)),
    updateMainState: (f) => dispatch({ type: 'SET_ORDER_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderList));