import React, { useState, useRef } from "react";
import { CaretDownFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Form } from 'react-bootstrap';
import CompanyNameDropdown from '../../CompanyNameDropdown';
import { DateRange } from 'react-date-range';
import format from 'date-fns/format'
import { Popover,  OverlayTrigger } from 'react-bootstrap';

const OrderFilters = (props) => {
  const shortDateFormat = 'dd MMM Y';
  const [formValues, setFormValues] = useState(props.filters);
  const refs = {
    new: useRef(null),
    processed: useRef(null),
    pending: useRef(null),
    paid: useRef(null)
  }

  const updateInvoiceNoHandler = (e) => {
    setFormValues({
      ...formValues,
      invoice_no: e.target.value
    })
  }

  const dateRangeChangeHandler = (range) => {
    setFormValues({
      ...formValues,
      startDate: range.selection.startDate,
      endDate: range.selection.endDate
    })
  }

  const updateRegistrationHandler = (e) => {
    setFormValues({
      ...formValues,
      registration: e.target.value
    })
  }

  const updateCompanyHandler = (n) => {
    setFormValues({
      ...formValues,
      company: n
    })
  }


  const updateStatusHandler = (e) => {
    let tmpValues = [];
    if (refs.new.current.checked) {
      tmpValues.push(0);
    }
    if (refs.processed.current.checked) {
      tmpValues.push(1);
    }
    setFormValues({
      ...formValues,
      statuses: tmpValues
    })
  }

  const updatePaymentStatusHandler = (e) => {
    let tmpValues = [];
    if (refs.pending.current.checked) {
      tmpValues.push(0);
    }
    if (refs.paid.current.checked) {
      tmpValues.push(1);
    }
    setFormValues({
      ...formValues,
      paymentStatuses: tmpValues
    })
  }

  const updateFiltersHandler = (e) => {
    e.preventDefault();
    props.updateMainState({
      ...formValues,
      page: 1
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#expandableFilters"
            >
              <span>
                <small><CaretDownFill /></small>
              </span>
            </button>
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
          <form onSubmit={updateFiltersHandler}>
            <h6 className="font-weight-bold mt-4 mb-2">Dates</h6>
            <OverlayTrigger
              trigger="click"
              key="bottom"
              placement="bottom"
              rootClose={true}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Content>
                    <DateRange
                      ranges={[
                        {
                          startDate: formValues.startDate,
                          endDate: formValues.endDate,
                          key: 'selection',
                        }
                      ]}
                      rangeColors={['#ffc107']}
                      maxDate={new Date()}
                      onChange={dateRangeChangeHandler}
                    />
                  </Popover.Content>
                </Popover>
              }
            >
            <div className="row mb-4">
              <div className="col">
                <div className="text-center border p-2 border-grey rounded">{format(formValues.startDate, shortDateFormat)} - {format(formValues.endDate, shortDateFormat)}</div>      
              </div>
            </div>
            
          </OverlayTrigger>
            <Form.Group controlId="formInvoiceNo" className="my-4">
              <Form.Label className="font-weight-bold">Invoice #</Form.Label>
              <Form.Control
                name="invoice_no"
                type="text"
                className="text-uppercase"
                value={formValues.invoice_no}
                onChange={updateInvoiceNoHandler}
              />
            </Form.Group>
            <Form.Group controlId="formRegistration" className="my-4">
              <Form.Label className="font-weight-bold">Registration</Form.Label>
              <Form.Control
                name="registration"
                type="text"
                className="text-uppercase"
                value={formValues.registration}
                onChange={updateRegistrationHandler}
              />
            </Form.Group>
            <CompanyNameDropdown label="Company name" companyName={formValues.company} onUpdate={updateCompanyHandler}/>
            <Form.Group controlId="formStatus0" className="mt-4 mb-1">
              <Form.Label className="font-weight-bold">Status</Form.Label>
              <Form.Check
                ref={refs.new}
                name="status"
                type="checkbox"
                label="New"
                defaultChecked={formValues.statuses.includes(0)}
                onChange={updateStatusHandler}
              />
            </Form.Group>
            <Form.Group controlId="formStatus1" className="mb-4">
              <Form.Check
                ref={refs.processed}
                name="status"
                type="checkbox"
                label="Processed"
                defaultChecked={formValues.statuses.includes(1)}
                onChange={updateStatusHandler}
              />
            </Form.Group>

            <Form.Group controlId="formPaymentStatus0" className="mt-4 mb-1">
              <Form.Label className="font-weight-bold">Payment Status</Form.Label>
              <Form.Check
                ref={refs.pending}
                name="payment_status"
                type="checkbox"
                label="Pending"
                defaultChecked={formValues.paymentStatuses.includes(0)}
                onChange={updatePaymentStatusHandler}
              />
            </Form.Group>
            <Form.Group controlId="formPaymentStatus1" className="mb-4">
              <Form.Check
                ref={refs.paid}
                name="payment_status"
                type="checkbox"
                label="Paid"
                defaultChecked={formValues.paymentStatuses.includes(1)}
                onChange={updatePaymentStatusHandler}
              />
            </Form.Group>
            

            <button type="submit" className="btn btn-dark btn-lg btn-block">
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    filters: state.orderFilters.filters
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) => dispatch({ type: 'SET_ORDER_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderFilters));

