import React from 'react';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import EmailForm from '../../../components/Admin/Emails/EmailForm';

const EditEmail = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Edit Email</h1>
          <EmailForm emailTemplateId={props.match.params.id}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(EditEmail);
