import React, { useState, useEffect } from "react";
import  { Link } from 'react-router-dom';
import OrderFormIntro from './OrderFormIntro';
import { connect } from 'react-redux';
import { apiLoadRegistration } from "../../store/actions/actions";
import { apiPurchaseRegistration } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import Gbp from '../utils/Gbp';
import NumberPlate from '../NumberPlate';

const OrderForm = (props) => {
  
  // ***** Default state *****
  const [registration, setRegistration] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [orderFormData, setOrderFormData] = useState({
    transfer_type: null,
    nominee: '',
    agree_to_terms: false
  });
  const [orderFormErrors, setOrderFormErrors] = useState({
    transfer_type: false,
    nominee: false,
    agree_to_terms: false
  });

  // ***** Mount handler *****
  
  // Trigger API call when registration mark changes
  useEffect(() => {
    props.apiLoadRegistration(props.mark)
      .then(({ response }) => {
        setApiError(false)
        setRegistration(response.data.registration)
      })
      .catch((error) => {
        setApiError(true)
        setRegistration(null);
      });
  }, [props.mark]);

  // ***** Validation handler *****
  const validate = () => {
    // Store errors
    let tmpErrors = {
      transfer_type: false,
      nominee: false,
      agree_to_terms: false
    }

    // Validate input
    const transferTypeItem = registration.transfer_types.find((tt) =>
      tt.name === orderFormData.transfer_type
    );

    if(!transferTypeItem){
      tmpErrors.transfer_type = true;
    }
    if(transferTypeItem && transferTypeItem.nominee && (!orderFormData.nominee)){
      tmpErrors.nominee = true;
    }
    if(!orderFormData.agree_to_terms) {
      tmpErrors.agree_to_terms = true;
    }
    
    setOrderFormErrors(tmpErrors);
  }

  const anyErrors = () => {
    return (
      orderFormErrors.transfer_type || 
      orderFormErrors.nominee || 
      orderFormErrors.agree_to_terms
    )
  }

  useEffect(() => {
    if(!registration) return
    validate();
  }, [registration, orderFormData]);


  const displayPrice = (r) => {
    if(r.reduced_price > 0) {
      return r.reduced_price;
    }
    return r.price;
  }

  const vatPrice = () => {
    if(!registration || !registration.add_vat) return 0
    if(registration.add_vat) {
      return roundToTwo((displayPrice(registration)/100) * 20);
    }
  }

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  const transferFee = () => {
    if(!registration) return 0
    const transferTypeItem = registration.transfer_types.find((tt) =>
      tt.name === orderFormData.transfer_type
    );

    if(transferTypeItem){
      return transferTypeItem.transfer_fee;
    }
    return 0;
  }

  const total = () => {
    if(!registration) return 0
    return (displayPrice(registration) + vatPrice() + transferFee())
  }


  // ***** Field change handlers *****
  const transferTypeChangeHandler = (e) => {
    setOrderFormData({
      ...orderFormData,
      transfer_type: e.target.value
    })
  }

  const nomineeChangeHandler = (e) => {
    setOrderFormData({
      ...orderFormData,
      nominee: e.target.value
    })
  }

  const termsChangeHandler = (e) => {
    setOrderFormData({
      ...orderFormData,
      agree_to_terms: e.target.checked
    });
  }

  // ***** Form submit handler *****
  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    validate(); 
    if(!anyErrors()) {
      props.apiPurchaseRegistration({
        mark: registration.mark, 
        price: displayPrice(registration),
        vat: vatPrice(),
        transfer_fee: transferFee(),
        total: total(),
        nominee_name: orderFormData.nominee,
        transfer_type: orderFormData.transfer_type,
      })
        .then(({ response }) => {
          console.log(response);
          props.onSubmit(response);
        })
        .catch((error) => {
          setApiError(true);
        });
    }
  }

  let form =    <div></div>;
  if(apiError) {
    form = <div>The specified registration is no longer available.</div>;
  } else {
    if(registration) {
      let expiryDate = <div className="row mb-2">
        <div className="col-md-3"><strong>Expiry Date</strong></div>
        <div className="col-md-9">N/A</div>
      </div>;;
      if(registration.expiry_date) {
        expiryDate = <div className="row mb-2">
        <div className="col-md-3"><strong>Expiry Date</strong></div>
        <div className="col-md-9">{registration.expiry_date}</div>
      </div>;
      }
      let notes = <div>
        <hr/>
        <div className="row mb-2">
          <div className="col-md-3"><strong>Notes</strong></div>
          <div className="col-md-9">N/A</div>
        </div>
      </div>;
      if(registration.notes) {
        let noteItems = <div><div>{registration.notes}</div></div>; 
        
        notes = <div>
        <hr/>
        <div className="row mb-2">
          <div className="col-md-3"><strong>Notes</strong></div>
          <div className="col-md-9">{noteItems}</div>
        </div>
      </div>;
      }
      // Create the transfer type options.
      const transferTypeItems = registration.transfer_types.map((tt) =>
        <option value={tt.name}>{tt.name}</option>
      );

      // Create the "things to note" section depending on the selection.
      let transferTypeNotes = <div></div>;
      let nomineeForm = <div></div>;
      
      if(orderFormData.transfer_type){
        const transferTypeItem = registration.transfer_types.find((tt) =>
          tt.name === orderFormData.transfer_type
        );

        let transferTypeNoteItems = transferTypeItem.notes.map((n) =>
          <li>{n}</li>
        );
        transferTypeNoteItems = [
          transferTypeNoteItems,
          <li>We've updated the total cost to reflect the transfer fee for this selection.</li>
        ]
        transferTypeNotes = <div>
                              <h6 className="my-4">Things to note about this transfer:</h6>
                              {transferTypeNoteItems}
                            </div>;

        // Toggle nominee field (if required).
        if(transferTypeItem.nominee){
          nomineeForm = <div class="card mb-4 error">
            <div class="card-header">
              <strong>Nominee Name</strong>
            </div>
            <div class="card-body">
              <div class="form-group">
                <input
                  type="text" 
                  class={orderFormErrors.nominee && submissionAttempted ? 'form-control is-invalid' : 'form-control'} 
                  id="nominee" 
                  name="nominee" 
                  placeholder="e.g. Neil Turner" 
                  value={orderFormData.nominee}
                  onChange={nomineeChangeHandler}
                />
              </div>
            </div>
          </div>;
        }
      }

      let errorMessage = <div></div>;

      if(submissionAttempted && anyErrors() === true) {
        errorMessage = <div class="text-danger mb-4"><small>Please review the highlighted errors and try again.</small></div>;
      }
      
      let termsAndConditionsOfSale = null;
      for(let x=0; x < props.pages.order_form.length; x++){
        if(props.pages.order_form[x].slug === 'terms-of-sale'){
          termsAndConditionsOfSale = <div dangerouslySetInnerHTML={{ __html: props.pages.order_form[x].content }} />;
        }
      }

      form = <form onSubmit={formSubmitHandler}>
      <div class="card mb-4">
        <div class="card-header">
          <strong>Registration Details</strong>
        </div>
        <div class="card-body">
          <div className="row mb-2">
            <div className="col-md-3"><strong>Registration</strong></div>
            <div className="col-md-9"><NumberPlate mark={registration.mark}/></div>
          </div>
          <hr/>
          <div className="row mb-2">
            <div className="col-md-3"><strong>Current Status</strong></div>
            <div className="col-md-9">{registration.status}</div>
          </div>
          {expiryDate}
          <hr/>
          <div className="row mb-2">
            <div className="col-md-3"><strong>Price</strong></div>
            <div className="col-md-9"><Gbp amount={displayPrice(registration)}/></div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3"><strong>VAT</strong></div>
            <div className="col-md-9"><Gbp amount={vatPrice()}/></div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3"><strong>Transfer Fee</strong></div>
            <div className="col-md-9"><Gbp amount={transferFee()}/></div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3"><strong>Total to Pay</strong></div>
            <div className="col-md-9"><Gbp amount={total()}/></div>
          </div>
          {notes}
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header">
        <strong>Transfer Type</strong>
        </div>
        <div class="card-body">
          <div class="form-group">
            <select name="transfer_type"
              class={orderFormErrors.transfer_type && submissionAttempted ? 'form-control is-invalid' : 'form-control'}
              id="transfer_type"
              onChange={transferTypeChangeHandler}
            >
              <option value="">Choose an option...</option>
              {transferTypeItems}
            </select>
          </div>
          {transferTypeNotes}
        </div>
      </div>
      {nomineeForm}
      <div class="card mb-4">
        <div class="card-header">
          <strong>Terms &amp; Conditions</strong>
        </div>
        <div class="card-body">
          <div className="overflow-auto terms-text border p-4">
            {termsAndConditionsOfSale}
          </div>
          <div class="form-check mt-3">
            <input
              class={submissionAttempted && orderFormErrors.agree_to_terms ? 'form-check-input is-invalid' : 'form-check-input'}
              type="checkbox" 
              value="1" 
              id="agree_to_terms"
              name="agree_to_terms"
              onChange={termsChangeHandler}
            />
            <label class="form-check-label" for="agree_to_terms">
              I have read and agree to the terms and conditions of sale.
            </label>
          </div>
        </div>
      </div>
      <div className="mb-5">
        {errorMessage}
        <button type="submit" class="btn btn-dark mr-2">Confirm purchase</button>
        <Link to="/registrations" className="btn btn-secondary">
          Cancel
        </Link>
      </div>
    </form>
    }
  }
  return (
    <div class="container">
      <OrderFormIntro mark={props.mark} error={apiError}/>
      {form}
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapStateToProps = state => {
  return {
    pages: state.pages.pageList
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiLoadRegistration: (m) => dispatch(apiLoadRegistration(m)),
    apiPurchaseRegistration: (m, f) => dispatch(apiPurchaseRegistration(m, f)),
  };
};

//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderForm));

