import React, { useState } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { apiAdminEnableUser } from '../../../store/actions/actions';

const EnableUserModal = (props) => {
  const closeHandler = (e) => {
    props.onClose();
  }

  const [message, setMessage] = useState('');

  const updateMessageHandler = (e) => {
    setMessage(e.target.value);
  }
  
  const acceptHandler = (e) => {
    props.apiAdminEnableUser(props.user.id, message)
      .then(({ response }) => {
        props.notify('The user ' + props.user.full_name + ' was successfully enabled');
        props.onConfirm();
      })
      .catch((error) => {
        props.notify('Error enabling the user ' + props.user.full_name);
      });
  }

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Enable User</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to enable the user <strong>{props.user.full_name}</strong>. Please confirm by clicking below.</p>
        <Form.Group controlId="formMessage" className="my-4">
          <Form.Label className="font-weight-bold">Add an additional custom message</Form.Label>
          <Form.Control
            name="message"
            as="textarea"
            rows={3}
            placeholder=""
            value={message}
            onChange={updateMessageHandler}
          />
        </Form.Group>
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={acceptHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminEnableUser: (id, message) => dispatch(apiAdminEnableUser(id, message)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(EnableUserModal);
