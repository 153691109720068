import React, { useState } from "react";
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import Gbp from '../../utils/Gbp';
import { apiAdminDeleteOffer } from '../../../store/actions/actions';
import NumberPlate from '../../NumberPlate';

const DeleteOfferModal = (props) => {
  const closeHandler = (e) => {
    props.onClose();
  }

  const deleteHandler = (e) => {
    props.apiAdminDeleteOffer(props.offer.id)
      .then(({ response }) => {
        props.notify('Offer for ' + props.offer.registration + ' was successfully deleted');
        props.onDelete();
      })
      .catch((error) => {
        props.notify('Error deleting offer for ' + props.offer.registration);
      });
  }

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Offer</Modal.Title>
      </Modal.Header>
    
      <Modal.Body>
        <p>You are about to delete the offer of <strong><Gbp amount={props.offer.offer_price}/></strong> for the registration <NumberPlate mark={props.offer.registration}/>. Please confirm by clicking below.</p>
       
      </Modal.Body>
    
      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>Cancel</Button>
        <Button variant="dark" onClick={deleteHandler}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminDeleteOffer: (id) => dispatch(apiAdminDeleteOffer(id)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default connect(null, mapDispatchToProps)(DeleteOfferModal);
