import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import ResponseBar from "../../components/ResponseBar";
import { withRouter } from "react-router-dom";
import MoreInformationForm from "../../components/MoreInformationForm/MoreInformationForm"
import EnquireForm from "../../components/EnquireForm/EnquireForm"
import ConditionalMessage from "../../components/ConditionalMessage/ConditionalMessage"
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';
import PlainNumberPlate from '../../components/PlainNumberPlate';

const Enquire = (props) => {
  const [submitState, setSubmitState] = useState({
    submitted: false,
    altContent: <div className="container">
      <h1 class="my-4">Enquiry Submitted</h1>
      <p>Thanks. We've received your enquiry about <strong><PlainNumberPlate mark={props.match.params.mark}/></strong>. We'll respond shortly.</p>
      <Link to="/registrations" className="btn btn-secondary">
        Back
      </Link>
    </div>
  });

  const submitHandler = (r) => {
    let tmpState = {
      ...submitState,
      submitted: true
    }
    setSubmitState(tmpState)
  }

  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <ConditionalMessage display={submitState.submitted} altContent={submitState.altContent}>
            <MoreInformationForm heading="Enquire" mark={props.match.params.mark} collapsed={false} />
            <EnquireForm mark={props.match.params.mark} onSubmit={submitHandler} />
          </ConditionalMessage>
        </div>
      </main>
      <Footer />
    </div>
  );
}


//export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default withRouter(connect(null, null)(Enquire));
