import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { apiRegistrationSearch } from "../../store/actions/actions";
import { displayPrice, reducedPrice } from '../../utils';
import NumberPlate from '../NumberPlate';
import PaginationDetail from '../PaginationDetail';
import Gbp from '../utils/Gbp';
import { EmojiFrown } from 'react-bootstrap-icons';

const RegistrationList = (props) => {
  const [registrationResults, setRegistrationResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    setLoading(true);
    props.apiRegistrationSearch(props.filters)
      .then(({ response }) => {
        setRegistrationResults(response.data.registrations);
        setMeta(response.data.meta);
        setLoading(false);
      })
      .catch((error) => { })
  },[props.filters, props.counter]);

  const scrollTop = () => {
    window.scrollTo(0,0)
  }

  const previousPageHandler = (e) => {
    if(props.filters.page > 1) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page-1)
      })
      scrollTop();
    }
  }

  const nextPageHandler = (e) => {
    if(meta && props.filters.page < meta.total_pages) {
      props.updateMainState({
        ...props.filters,
        page: (props.filters.page+1)
      })
      scrollTop();
    }
  }

  let registrationItems = null;
  if(registrationResults.length > 0){
    registrationItems = registrationResults.map((r, index) => {
      let priceItem = <Gbp amount={displayPrice(r)}/>;
      if(r.reduced_price !== 0) {
        priceItem = <span><strike><Gbp amount={displayPrice(r)}/></strike> <span className="text-danger"><Gbp amount={reducedPrice(r)}/></span></span>;
      }
      return (
        <tr key={index}>
          <th scope="row"><NumberPlate mark={r.mark}/></th>
          <td className="">{priceItem}</td>
          <td className="d-none d-md-table-cell">{r.status}</td>
          <td className="min">
            <div className="btn-group">
              <Link to={'/registrations/' + r.mark} className="btn btn-sm btn-dark">
                Buy
              </Link>
              <button type="button" className="btn btn-sm btn-dark dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={'/registrations/more-information/' + r.mark} className="dropdown-item">
                  More Information
                </Link>
                <Link to={'/registrations/make-offer/' + r.mark} className={`dropdown-item ${r.enable_offers ? "" : "disabled"}`}>
                  Make Offer
                </Link>
                <Link to={'/registrations/enquire/' + r.mark} className={'dropdown-item'}>
                  Enquire
                </Link>
              </div>
            </div>
          </td>
        </tr>
      )
    });
  }

  if(registrationResults.length > 0 && !loading) {
    return (
      <React.Fragment>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">Registration</th>
              <th scope="col" className="">Price</th>
              <th scope="col" className="d-none d-md-table-cell">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {registrationItems}
          </tbody>
        </table>
        <PaginationDetail meta={meta} onPreviousClick={previousPageHandler} onNextClick={nextPageHandler} display={false}/>
      </React.Fragment>
    )
  } else if (loading) {
    return(
      <div className="border rounded p-4 text-center text-muted">Loading...</div>
    )
  }
  return(
    <div className="border rounded p-4 text-center text-muted">No results found<EmojiFrown className="ml-2"/></div>
  )
}

const mapStateToProps = state => {
  return {
    filters: state.publicRegistrationFilters.filters
  };
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiRegistrationSearch: (f) => dispatch(apiRegistrationSearch(f)),
    updateMainState: (f) => dispatch({ type: 'SET_PUBLIC_REGISTRATION_FILTERS', payload: f })
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationList));