import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";

const ImportErrorsModal = (props) => {
  const closeHandler = (e) => {
    props.onClose();
  };

  const formatErrors = (errs) => {
    let items = errs.map((e) => {
      return <li>{e}</li>;
    });
    return <ul className="list-unstyled">{items}</ul>;
  };

  return (
    <Modal show={true} onHide={closeHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Import Status</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <strong>Successful imports:</strong> {props.status.success_count}
          <br />
          <strong>Failed imports:</strong> {props.status.failed_count}
          <br />
          <strong>Removed registrations:</strong> {props.status.removed_count}
          <br />
          <strong>Deleted registrations:</strong>{" "}
          {props.status.deleted.join(", ")}
        </p>
        {props.status.failed_count > 0 && <h5>Errors</h5>}
        {props.status.failed_count > 0 && formatErrors(props.status.errors)}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeHandler}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(null, mapDispatchToProps)(ImportErrorsModal);
