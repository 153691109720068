import React from 'react';
import { withRouter } from "react-router-dom";
import AdminLayout from '../../../components/Layouts/AdminLayout';
import CompanyForm from '../../../components/Admin/Companies/CompanyForm';

const AddCompany = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
        <div className="container">
          <h1 className="my-4">Add Company</h1>
          <CompanyForm companyId={null}/>
        </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(AddCompany);
