import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import RegistrationForm from '../../../components/Admin/Registrations/RegistrationForm';
import AdminLayout from '../../../components/Layouts/AdminLayout';

const EditRegistration = (props) => {
  return (
    <AdminLayout>
      <div className="container-full mx-3">
          <div className="container">
            <h1 className="my-4">Edit Registration</h1>
            <RegistrationForm registrationId={props.match.params.id}/>
          </div>
      </div>
    </AdminLayout>
  );
}

export default withRouter(connect(null, null)(EditRegistration));
