import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiGenerateSubscriberResetLink } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import { Form, Button, Card } from "react-bootstrap";
import MainNav from "../../components/User/MainNav/MainNav";
import Footer from "../../components/User/Footer/Footer";
import validator from "validator";

const ForgottenPassword = (props) => {
  const [user, setUser] = useState(null);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
  });

  useEffect(() => {
    setUser({
      email: "",
    });
  }, []);

  useEffect(() => {
    if (user !== null) {
      let tmpErrors = {
        email: false,
      };
      if (validator.isEmpty(user.email) || !validator.isEmail(user.email)) {
        tmpErrors.email = true;
      }
      setErrors(tmpErrors);
    }
  }, [user, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    let tmpUser = {
      ...user,
    };

    tmpUser[fieldName] = e.target.value;
    setUser(tmpUser);
  };

  const anyErrors = () => {
    for (const [, value] of Object.entries(errors)) {
      if (value) {
        return true;
      }
    }
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if (!anyErrors()) {
      props
        .apiGenerateSubscriberResetLink(user)
        .then(({ response }) => {
          setSaved(true);
        })
        .catch((error) => {
          setSaved(true);
        });
    }
  };

  let form = null;
  if (user) {
    form = (
      <Form onSubmit={submitFormHandler}>
        <p>
          To reset your password, please enter the email address linked to your
          T12ADE account.
        </p>
        <Card>
          <Card.Header>
            <strong>Your Details</strong>
          </Card.Header>
          <Card.Body>
            <Form.Group controlId="formPassword">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="text"
                placeholder="Email address"
                className={
                  submissionAttempted && errors.email ? "is-invalid" : ""
                }
                value={user.email}
                onChange={(e) => fieldUpdateHandler(e, "email")}
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Button type="submit" variant="dark" className="my-4 mr-2">
          Get reset link
        </Button>
      </Form>
    );
  }

  let content = form;
  if (saved) {
    content = (
      <div>
        <p>
          Thank you. If we recognised your email address, you will shortly
          receive an email containing a link to reset your password.
        </p>
      </div>
    );
  }
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <div className="container py-4">
            <h1 className="mb-4 pb-4 border-bottom">Forgotten Password</h1>

            {content}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiGenerateSubscriberResetLink: (data) =>
      dispatch(apiGenerateSubscriberResetLink(data)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ForgottenPassword));
