import React from "react";
import { connect } from "react-redux";
import ResponseBar from "../../components/ResponseBar";
import { apiRegistrationSearch } from "../../store/actions/actions";
import { withRouter } from "react-router-dom";
import MoreInformationForm from "../../components/MoreInformationForm/MoreInformationForm"
import MainNav from '../../components/User/MainNav/MainNav';
import Footer from '../../components/User/Footer/Footer';

const MoreInformation = (props) => {
  return (
    <div className="mainContainer">
      <MainNav />
      <main>
        <div>
          <ResponseBar />
          <MoreInformationForm heading="More Information" mark={props.match.params.mark} buttons={true}/>
        </div>
      </main>
      <Footer />
    </div>
  );
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiRegistrationSearch: (q, p) => dispatch(apiRegistrationSearch(q, p)),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(MoreInformation));
