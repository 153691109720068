import React, { useState, useEffect, useRef }  from "react";
import { Form, Button, Card } from 'react-bootstrap';
import  { Link } from 'react-router-dom';
import { apiAdminLoadSubscriber } from '../../../store/actions/actions';
import { apiAdminSaveSubscriber } from '../../../store/actions/actions';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import validator from "validator";
import CompanyIdDropdown from './../../CompanyIdDropdown';
import anyErrors from '../../../utils/anyErrors';

const UserForm = (props) => {
  const [user, setUser] = useState(null);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    opted_in: false,
    position: false,
    is_admin: false,
    company_id: false
  })
  const refs = {
    optedIn: useRef(null),
    isAdmin: useRef(null)
  }
  
  useEffect(() => {
    if(props.userId === null) {
      setUser({
        id: null,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        opted_in: true,
        company_id: '',
        position: '',
        is_admin: false
      });
    } else {
      props.apiAdminLoadSubscriber(props.userId)
        .then(({ response }) => {
          setUser({
            id: response.data.subscriber.id,
            first_name: response.data.subscriber.first_name,
            last_name: response.data.subscriber.last_name,
            email: response.data.subscriber.email,
            password: '',
            opted_in: response.data.subscriber.opted_in,
            company_id: response.data.subscriber.company_id.toString(),
            position: response.data.subscriber.position,
            is_admin: response.data.subscriber.is_admin,
          });
        })
        .catch((error) => {
          console.log("Error retrieving user");
        });
    }
  }, [props.userId]);

  useEffect(() => {
    if(user !== null && submissionAttempted) {
      let tmpErrors = {
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        opted_in: false,
        position: false,
        is_admin: false,
        company_id: false
      }
      if(validator.isEmpty(user.first_name)){
        tmpErrors.first_name = true;
      }
      if(validator.isEmpty(user.last_name)){
        tmpErrors.last_name = true;
      }
      if(validator.isEmpty(user.email) || !validator.isEmail(user.email)){
        tmpErrors.email = true;
      }
      if(user.id == null && validator.isEmpty(user.password)){
        tmpErrors.password = true;
      }
      if(validator.isEmpty(user.position)){
        tmpErrors.position = true;
      }
      if(!validator.isNumeric(user.company_id)){
        tmpErrors.company_id = true;
      }
      setErrors(tmpErrors);
    }
  }, [user, submissionAttempted]);

  const fieldUpdateHandler = (e, fieldName) => {
    console.log(e, fieldName);
    let tmpUser = {
      ...user
    }

    tmpUser[fieldName] = e.target.value;
    setUser(tmpUser);
  }

  const companyIdUpdateHandler = (v) => {
    let tmpUser = {
      ...user
    }

    tmpUser.company_id = v.toString();
    setUser(tmpUser);
  }

  const isAdminUpdateHandler = (e) => {
    let tmpUser = {
      ...user
    }

    tmpUser.is_admin = refs.isAdmin.current.checked;
    setUser(tmpUser);
  }

  const isOptedInHandler = (e) => {
    let tmpUser = {
      ...user
    }

    tmpUser.opted_in = refs.optedIn.current.checked;
    setUser(tmpUser);
  }

  const submitFormHandler = (e) => {
    e.preventDefault();
    setSubmissionAttempted(true);
    if(!anyErrors(errors)){
      props.apiAdminSaveSubscriber(user)
        .then(({ response }) => {
          props.notify('The user ' + user.first_name + ' ' + user.last_name +  ' was successfully saved');
          setSaved(true);
        })
        .catch((error) => {
          props.notify('Error saving the user ' + user.first_name + ' ' + user.last_name);
        });
    }
  }
  let redirect = null;
  if(saved) {
    redirect = <Redirect to="/admin/users"/>
  }
  let form = null;
  if(user) {
    form = <Form onSubmit={submitFormHandler}>
      {redirect}
      <Card>
        <Card.Header as="h5">User details</Card.Header>
        <Card.Body>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">First Name</Form.Label>
            <Form.Control
              name="first_name"
              type="text"
              placeholder="First Name"
              className={errors.first_name ? 'is-invalid' : ''}
              value={user.first_name}
              onChange={(e) => fieldUpdateHandler(e, 'first_name')}
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Last Name</Form.Label>
            <Form.Control
              name="last_name"
              type="text"
              placeholder="Last Name"
              className={errors.last_name ? 'is-invalid' : ''}
              value={user.last_name}
              onChange={(e) => fieldUpdateHandler(e, 'last_name')}
            />
          </Form.Group>
          
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Email</Form.Label>
            <Form.Control
              name="email"
              type="text"
              placeholder="Email"
              className={errors.email ? 'is-invalid' : ''}
              value={user.email}
              onChange={(e) => fieldUpdateHandler(e, 'email')}
            />
          </Form.Group>
          <Form.Group controlId="formOptedIn" className="mt-4 mb-1">
            <Form.Check
              name="opted_in"
              ref={refs.optedIn}
              type="checkbox"
              label="Opt in to receive communications"
              defaultChecked={user.opted_in}
              onChange={isOptedInHandler}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header as="h5">Company details</Card.Header>
        <Card.Body>
          <CompanyIdDropdown label="Company" companyId={user.company_id} error={errors.company_id} onUpdate={companyIdUpdateHandler}></CompanyIdDropdown>
          <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Position</Form.Label>
            <Form.Control
              name="position"
              type="text"
              placeholder="Position"
              className={errors.position ? 'is-invalid' : ''}
              value={user.position}
              onChange={(e) => fieldUpdateHandler(e, 'position')}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header as="h5">Security</Card.Header>
        <Card.Body>
        <Form.Group controlId="formName">
            <Form.Label className="font-weight-bold">Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder=""
              className={errors.password ? 'is-invalid' : ''}
              value={user.password}
              onChange={(e) => fieldUpdateHandler(e, 'password')}
            />
          </Form.Group>
          <Form.Group controlId="formIsAdmin" className="mt-4 mb-1">
            <Form.Check
              name="is_admin"
              ref={refs.isAdmin}
              type="checkbox"
              label="Make this user an administrator"
              defaultChecked={user.is_admin}
              onChange={isAdminUpdateHandler}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Button type="submit" variant="dark" className="my-4 mr-2">Save</Button>
      <Link to="/admin/users" className="btn btn-secondary">Cancel</Link>
    </Form>
  }
  return (
    form
  )
}

// Add ability to get state and manipulate it.
const mapDispatchToProps = (dispatch) => {
  return {
    apiAdminLoadSubscriber: (sid) => dispatch(apiAdminLoadSubscriber(sid)),
    apiAdminSaveSubscriber: (s) => dispatch(apiAdminSaveSubscriber(s)),
    notify: (msg) => dispatch({ type: 'NOTIFY', msg: msg })
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UserForm));
