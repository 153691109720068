const initAuth = () => {
  return {
    config: {
      columns: [
        'Registration',
        'Price',
        'VAT Amount',
        'Status',
        'Expiry Date',
        'Notes'
      ],
      vat: ''
    }
  };
};

function publicDownloadConfig(state = initAuth(), action) {
  switch (action.type) {
    case "SET_PUBLIC_DOWNLOAD_CONFIG":
      console.log(action.payload);
      return { ...state, ...{ config: action.payload } };
    case "RESET_PUBLIC_DOWNLOAD_CONFIG":
      return { ...state, ...{ 
        config: {
          columns: [
            'Registration',
            'Price',
            'VAT Amount',
            'Status',
            'Expiry Date',
            'Notes'
          ],
          vat: 'Any'
        }
      }};
    default:
      return state;
  }
}

export default publicDownloadConfig;
