import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import qs from 'qs';

const AuthGuard = (props) => {
  let redirectUrl = qs.parse(props.location.search, { ignoreQueryPrefix: true }).redirect_url
  let returnVal = props.children;

  if (props.requiresAuth && !props.loggedIn) {
    if (props.redirect) {
      returnVal = <Redirect to={props.redirect} />;
    } else {
      returnVal = <Redirect to='/' />;
    }
  }

  if (!props.requiresAuth && props.loggedIn && (props.redirect || redirectUrl)) {
    if (typeof redirectUrl != 'undefined') {
      returnVal = <Redirect to={redirectUrl} />;
    } else {
      returnVal = <Redirect to={props.redirect} />;
    }
  }

  return returnVal;
}

export default withRouter(connect(null, null)(AuthGuard));