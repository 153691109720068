export const numberAsString = (v) => {
  if(typeof v == 'number'){
    return v.toString();
  }
  if(v == null){
    return '';
  }
  return v;
}

export const anyErrors = (obj) => {
  for (const [, value] of Object.entries(obj)) {
    if (value) {
      return true;
    }
  }
}

export const displayPrice = (r) => {
  // if(r.reduced_price > 0) {
  //   return r.reduced_price;
  // }
  return r.price;
}

export const reducedPrice = (r) => {
  return r.reduced_price;
}

export const displayCostPrice = (r) => {
  return r.cost_price;
}