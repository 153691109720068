import React  from "react";
import CurrencyFormat from 'react-currency-format';

const Inflation = (props) => {
  let sign = '-£';
  if(props.value >= 0){
    sign = '+£';
  }
  
  return (
    <CurrencyFormat 
      value={props.value} 
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={'text'}
      thousandSeparator={true}
      prefix={sign} 
    />
  )
}

export default Inflation;
