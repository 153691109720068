const initAuth = () => {
  return {
    filters: {
      subscriber_email: "",
      registration: '',
      event_type: "",
      page: 1
    }
  };
};

function reportingFilters(state = initAuth(), action) {
  switch (action.type) {
    case "SET_REPORTING_FILTERS":
      return { ...state, ...{ filters: action.payload } };
    case "RESET_REPORTING_FILTERS":
      return { ...state, ...{ 
        filters: {
          subscriber_email: "",
          registration: '',
          event_type: "",
          page: 1
        }
      }};
    default:
      return state;
  }
}

export default reportingFilters;
