import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiLogout } from "../../../store/actions/actions";
import { ArrowRight } from 'react-bootstrap-icons';

class MainNav extends Component {
  logoutHandler = (e) => {
    e.preventDefault();
    this.props.apiLogout()
      .then(() => {})
      .catch(() => {});
  };
  
  render() {
    let initials = localStorage.getItem("initials");
    let isAdmin = localStorage.getItem("isAdmin");
    let nav = null;
    if(this.props.loggedIn){
      let adminLink = null;
      if(isAdmin === 'true'){
        adminLink =  <React.Fragment>
          <div class="dropdown-divider"></div>
          <Link to={'/admin/'} className="dropdown-item">
            Administrator <ArrowRight/>
          </Link>
      </React.Fragment>
      }
      nav = <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
      <Link to="/" className="navbar-brand">
        T12ADE
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
            <span className="nav-link mr-2 dropdown-toggle" id="regDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Registrations
            </span>
            <div className="dropdown-menu" aria-labelledby="regDropdown">
              <Link to="/registrations" className="dropdown-item">
                Search Registrations
              </Link>
              <Link to="/registrations/download" className="dropdown-item">
                Download Stock List
              </Link>
            </div>
          </li>
          <li className="nav-item mr-2 dropdown">
            <span className="nav-link dropdown-toggle" id="infoDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Important Information
            </span>
            <div className="dropdown-menu" aria-labelledby="infoDropdown">
            {this.props.pages.information.map((page) => (
              <Link to={'/pages/' + page.slug} className="dropdown-item">
              {page.title}
              </Link>
            ))}
            </div>
          </li>
          <li className="nav-item dropdown">
            <span className="nav-link mr-2 dropdown-toggle" id="dealerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Dealer Services
            </span>
            <div className="dropdown-menu" aria-labelledby="dealerDropdown">
            {this.props.pages.dealer_services.map((page) => (
              <Link to={'/pages/' + page.slug} className="dropdown-item">
              {page.title}
              </Link>
            ))}
            </div>
          </li>
          
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <span className=" text-black" id="dropdownMenuButton" data-toggle="dropdown">
              <button className="btn-circle btn-sm ">
                <span className="initials">{initials}</span>
              </button>
            </span>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
              <h6 className="dropdown-header">Billing</h6>
              <Link to="/invoices" className="dropdown-item">
                Your Invoices
              </Link>
              <div class="dropdown-divider"></div>
              <h6 className="dropdown-header">Account</h6>
              <Link to="/company-settings" className="dropdown-item">
                Company Settings
              </Link>
              <Link to="/update-profile" className="dropdown-item">
                Update Profile
              </Link>
              <Link to="/update-password" className="dropdown-item">
                Update Password
              </Link>
              {adminLink}
              <div class="dropdown-divider"></div>
              <span className="dropdown-item cursor-pointer" onClick={this.logoutHandler}>Logout</span>
            </div>
          </li>
          
        </ul>
      </div>
    </nav>;
    } else {
      nav = <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
      <Link to="/" className="navbar-brand">
        T12ADE
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">

          
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/login" className="nav-link">
              Member Login
            </Link>
          </li>
        </ul>
      </div>
    </nav>
    }
    return (
      nav
    );
  }
}


// Add ability to get state and manipulate it.
const mapStateToProps = state => {
  return {
    loggedIn: state.authentication.loggedIn,
    pages: state.pages.pageList,
    pagesLoaded: state.pages.loaded
  };
};

const mapDispatchToProps = dispatch => {
  return {
    apiLogout: () => dispatch(apiLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);
