const ConditionalMessage = (props) => {
  let content = props.children;
  if(props.display){
    content = props.altContent;
  }
  return (
    content
  )
}

export default ConditionalMessage;